import React, {ChangeEvent, Fragment, useContext, useEffect, useState} from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  InputAdornment,
  List, ListItem, ListItemText,
  TextField, Typography
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {makeStyles, Theme} from '@material-ui/core/styles';

import ItemEdit, {useItemEditStyles} from '../common/ItemEdit';
import MultiSelect from '../common/MultiSelect';
import SingleSelect from '../common/SingleSelect';
import {AuthContext} from '../../contexts';
import {BeerFirestoreService, BeerSightingFirestoreService} from '../../firebase';
import {FormErrors, InputEvent, SelectEvent} from '../../helpers/useFormValidation';
import useListFromFirestoreService
  from '../../helpers/useListFromFirestoreService';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';
import {Beer, BeerCore, BeerSighting, PlaceCore} from '../../models';


const useStyles = makeStyles((theme: Theme) => ({
  beerList: {
    maxHeight: 200,
    overflow: 'auto',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  beerListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
}));


function validateBeerSighting(values: BeerSighting) {
  let errors: FormErrors<BeerSighting> = {};

  // Beer Errors
  if (!values.beer) {
    errors.beer = "Beer required";
  }

  // Place Errors
  if (!values.place) {
    errors.beer = "Place required";
  }

  // Beer Errors
  if (!values.packaging) {
    errors.packaging = "Packaging required";
  }

  return errors;
}

const INITIAL_VALUE = new BeerSighting();

interface Props {
  beerSighting?: BeerSighting|null,
  place: PlaceCore,
  onComplete?: () => void,
  onError?: (msg: string) => void,
  hideButtons?: boolean,
  hideError?: boolean,
}

export const BeerSightingEdit = (props: Props) => {

  const {beerSighting, place, onComplete, onError, hideButtons=false, hideError=false} = props;
  console.log("BeerSightingEdit", beerSighting);

  const classes = useItemEditStyles();
  const beerSightingClasses = useStyles();

  const [_beerSighting, setBeerSighting] = useState<BeerSighting|null>(null);

  useEffect(() => {
    if (_beerSighting && place != null && _beerSighting.place == null) {
      _beerSighting.place = place;
    }
  }, [place, _beerSighting]);

  useEffect(() => {
    if (beerSighting == null && place != null) {
      setBeerSighting(() => {
        const newBeerSighting = INITIAL_VALUE;
        if (newBeerSighting != null) {
          newBeerSighting.place = place;
        }
        return newBeerSighting;
      });
    } else if (beerSighting != null && place != null) {
      setBeerSighting((oldBeerSighting) => {
        const newBeerSighting = oldBeerSighting?.clone();
        if (newBeerSighting != null) {
          newBeerSighting.place = place;
        }
        return newBeerSighting;
      });
    } else {
      setBeerSighting(beerSighting||null);
    }
  }, [beerSighting, place]);

  const [searchString, setSearchString] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const {firestoreItems} = useStreamFromFirebaseService<Beer, BeerFirestoreService>(
      BeerFirestoreService,
      {query: searchString}
      );

  const {itemLists} = useListFromFirestoreService(
    'beerLists',['packaging'], false, false);

  const {auth} = useContext(AuthContext);
  const currentUser = auth && auth.currentUser;

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setSearchString(event.target.value);
  }

  async function preSubmit(item: BeerSighting) {
    item.reportedById = currentUser?.id;
    item.reportedOn = new Date();
    item.action = 'Add';
  }

  const renderFields = (
    formItem: BeerSighting|null,
    formErrors: FormErrors<BeerSighting>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const handleListItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number,
    ) => {
      setSelectedIndex(index);
      const selectedBeer = firestoreItems[index];
      const beerCore = BeerCore.fromBeer(selectedBeer);
      updateProperty('beer', beerCore);
    };

    const pack = formItem?.packaging?.id
      && itemLists.packaging
        .find(p => p.id === formItem.packaging?.id);
    const packValue = pack !== "" ? pack : null;
    const volumes = packValue?.volumes;
    const options = packValue?.options;

    return (
      <Fragment>
        <TextField
          autoComplete="off"
          className={classes.textField}
          helperText="Filter Beers"
          value={searchString}
          onChange={handleSearchChange}
          type="text"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search/>
              </InputAdornment>
            )
          }}
        />

        <List dense className={beerSightingClasses.beerList}>
          {firestoreItems.map((beer, i) =>
            <ListItem
              button
              divider
              key={i}
              className={beerSightingClasses.beerListItem}
              selected={selectedIndex === i}
              onClick={event => handleListItemClick(event, i)}
            >
              <ListItemText
                primary={beer.name || ""}
                secondary={beer.brewery?.name || ""}
              />
            </ListItem>
          )}
        </List>
        <div className={classes.footer}>
          <SingleSelect
            label="Packaging"
            name="packaging"
            items={itemLists.packaging}
            value={formItem?.packaging}
            onChange={(val) => updateProperty("packaging", {
              id: val?.id,
              name: val?.name,
            })}
          />
          <MultiSelect
            label="Volumes"
            name="packagingVolumes"
            items={volumes}
            values={formItem?.packagingVolumes}
            onChange={(vals) => updateProperty("packagingVolumes", vals)}
          />
          <MultiSelect
            label="Options"
            name="packagingOptions"
            items={options}
            values={formItem?.packagingOptions}
            onChange={(vals) => updateProperty("packagingOptions", vals)}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <ItemEdit
      item={beerSighting}
      defaultValue={INITIAL_VALUE}
      service={BeerSightingFirestoreService}
      fieldRenderer={renderFields}
      validator={validateBeerSighting}
      preSubmit={preSubmit}
      formId="beerSightingEdit"
      onComplete={onComplete}
      onError={onError}
      hideButtons={hideButtons}
      hideError={hideError}
    />
  )
};

interface DialogProps {
  place: PlaceCore,
  onClose: (value: string|null) => void,
  open: boolean,
}

export const BeerSightingEditDialog = (props: DialogProps) => {

  const {place, onClose, open} = props;

  const [error, setError] = useState<string|null>(null);
  const handleError = (message: string) => {
    setError(message);
  };

  const handleClose = () => {
    onClose("");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="place-select-dialog-title"
      >
        <DialogTitle id="place-select-dialog-title">
          Edit Beer Sighting
        </DialogTitle>
        <DialogContent>
          <BeerSightingEdit
            place={place}
            onComplete={handleClose}
            onError={handleError}
            hideButtons
            hideError
          />
        </DialogContent>
        <DialogActions>
          {error &&
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          }
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            form="beerSightingEdit"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BeerSightingEditDialog;

import React, {Fragment, FunctionComponent, useContext} from 'react';
import { NavLink as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Divider
} from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import {Business, LocalBar, LocalDrink, List as ListIcon} from "@material-ui/icons";

import {AuthContext} from '../../contexts';

import SidebarNavItem from './SideBarNavItem';


export interface Page {
  title: string,
  href: string,
  icon: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {},
  list: {},
  listHeader: {},
  navList: {
    //width: theme.sidebar.width,
    fontSize: '1.1em',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  navListHeader: {
    textAlign: 'center',
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  button: {
    color: blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    //color: theme.palette.primary,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));


interface Props {
  isCollapsed?: boolean,
  onSelect?: () => void,
}

const CustomRouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <div style={{ flexGrow: 1 }} >
    <RouterLink innerRef={ref} {...props} />
  </div>
));

const SidebarNav: FunctionComponent<Props> = ({isCollapsed=false, ...props}) => {
  const {onSelect} = props;

  const classes = useStyles();

  const beerItems = [
    {
      name: 'Beers',
      link: '/beers/',
      Icon: LocalDrink,
    },
    {
      name: 'Breweries',
      link: '/breweries',
      Icon: Business,
    },
    {
      name: 'Ingredients',
      link: '/beer-ingredients',
      Icon: ListIcon,
    },
  ];

  const wineItems = [
    {
      name: 'Wines',
      link: '/wines/',
      Icon: LocalDrink,
    },
    {
      name: 'Wineries',
      link: '/Wineries',
      Icon: Business,
    },
    {
      name: 'Ingredients',
      link: '/wine-ingredients',
      Icon: ListIcon,
    },
  ];

  const spiritItems = [
    {
      name: 'Spirits',
      link: '/spirits/',
      Icon: LocalBar,
    },
    {
      name: 'Distilleries',
      link: '/distilleries',
      Icon: Business,
    },
    {
      name: 'Ingredients',
      link: '/spirit-ingredients',
      Icon: ListIcon,
    },
  ];

  const adminItems = [
  ];

  const {auth} = useContext(AuthContext);
  const currentUserRoles = auth && auth.currentUserRoles;
  const canAdminister = currentUserRoles != null && currentUserRoles.canAdminister;
  const canApprove = currentUserRoles != null && currentUserRoles.canApprove;

  if (canApprove) {
    adminItems.push({
      name: 'Transactions',
      link: '/transactions',
      Icon: ListIcon,
    });
  }

  return (
    <div>
      <SidebarNavItem
        isCollapsed={false}
        name="Places"
        iconPadding={0}
        link="/places/"
        onClick={onSelect}
      />

      <Divider className={classes.divider} />

      <List className={classes.navList} disablePadding>
        <SidebarNavItem
            isCollapsed={isCollapsed}
            name="Beer"
            iconPadding={0}
            items={beerItems}
            onClick={onSelect}
        />
      </List>

      <Divider className={classes.divider} />

      <List className={classes.navList} disablePadding>
        <SidebarNavItem
            isCollapsed={false}
            name="Wine"
            iconPadding={0}
            items={wineItems}
            onClick={onSelect}
        />
      </List>

      <Divider className={classes.divider} />

      <List className={classes.navList} disablePadding>
        <SidebarNavItem
            isCollapsed={false}
            name="Spirits"
            iconPadding={0}
            items={spiritItems}
            onClick={onSelect}
        />
      </List>

      {adminItems.length > 0 &&
        <Fragment>
          <Divider className={classes.divider} />

          <List className={classes.navList} disablePadding>
            <SidebarNavItem
                isCollapsed={false}
                name="Admin"
                iconPadding={0}
                items={adminItems}
                onClick={onSelect}
            />
          </List>
        </Fragment>
      }
    </div>
  );
};

export default SidebarNav;

import React from 'react';
import {
  Card, CardActionArea, CardMedia, CardContent,
  Grid,
  Typography
} from '@material-ui/core';

import {BeerFirestoreService} from '../../firebase';
import {Beer, Brewery} from '../../models';

import {makeStyles} from '@material-ui/core/styles';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';


const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 10,
  },
  gridList: {
  },
  gridListTile: {
  },
  card: {
    width: 200,
  },
  cardMedia: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  image: {
    height: 140,
    maxWidth: "100%",
    objectFit: "contain",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
}));


interface Props {
  brewery?: Partial<Brewery>|null,
  onSelect?: (item: Beer|null) => void,
}

const BeerCoresGrid = (props: Props) => {
  console.log("Refreshing BeersCardGrid");

  const {brewery, onSelect} = props;

  const classes = useStyles();

  const {firestoreItems} = useStreamFromFirebaseService<Beer,BeerFirestoreService>(
      BeerFirestoreService,
      {kwArgs: {brewery: brewery}}
  );

  const handleClick = (beer: Beer) => {
    onSelect && onSelect(beer);
  };

  return (
    <div className={classes.root}>
       <Grid container className={classes.gridList} spacing={1}>
        {firestoreItems.map(item => {
          const logoURL = item.logoURLs && item.logoURLs["400pxURL"];
          return (
            <Grid item
                  key={item.id}
                  className={classes.gridListTile}
            >
              <Card className={classes.card}>
                <CardActionArea
                  onClick={() => handleClick(item)}
                >
                  {logoURL != null
                    ? <CardMedia
                        className={classes.cardMedia}
                        //image={logoURL}
                        title={item.name}
                      >
                      <img className={classes.image} alt="logo" src={logoURL}/>
                      </CardMedia>
                    : <div className={classes.image}/>
                  }
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary"
                                component="p">
                      {item.type?.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
};

export default BeerCoresGrid;

import React from 'react';

import {BeersCardGrid} from '../components/Beer';


export default function BeersView() {

  return (
    <div>
      <BeersCardGrid />
    </div>
  );
}

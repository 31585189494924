import React, {ReactNode, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';

import {FirestoreService} from '../../firebase';
import useErrorMessage from '../../helpers/useErrorMessage';
import useFormValidation, {
  FormErrors, InputEvent, SelectEvent
} from '../../helpers/useFormValidation';
import useItemFromFirestoreService from '../../helpers/useItemFromFirestoreService';
import {FirestoreBase} from '../../models';


export const useItemEditStyles = makeStyles((theme: Theme) => ({
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  footer: {
  },
  errorText: {
    color: theme.palette.error.main
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  },
}));


interface Props<S,T> {
  item?: S | null;
  defaultValue: S;
  id?: string | null;
  service: new () => T;
  fieldRenderer: (
    item: S|null,
    errors: FormErrors<S>,
    handleBlur: () => void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => ReactNode;
  validator: (item: S) => FormErrors<S>;
  formId?: string,
  preSubmit?: (item: S) => void,
  onComplete?: () => void;
  onError?: (msg: string) => void;
  hideButtons?: boolean;
  hideError?: boolean;
}

function ItemEdit<S extends FirestoreBase,T extends FirestoreService<S>>(props: Props<S,T>) {

  const {
    item,
    defaultValue,
    id,
    service,
    fieldRenderer,
    validator,
    formId,
    preSubmit,
    onComplete,
    onError,
    hideButtons = false,
    hideError = false,
  } = props;

  //console.log("ItemEdit", item);

  const classes = useItemEditStyles();

  const [_item, setItem] = useState<S|null>(null);

  const [_id, setId] = useState<string|null>(null);
  const {id: paramId} = useParams();
  useEffect(() => {
    if (id != null) {
      setId(id);
    } else if (paramId != null) {
      setId(paramId);
    } else {
      setId(null);
    }
  }, [id, paramId]);

  const {
    firestoreItem,
    addItemToFirestore,
    updateItemInFirestore,
    firestoreItemError
  } = useItemFromFirestoreService<S,T>(_id, service);

  useEffect(() => {
    if (firestoreItem != null) {
      setItem(firestoreItem);
    } else if (item != null) {
      setItem(item);
    } else {
      setItem(defaultValue);
    }
  }, [item, firestoreItem, defaultValue]);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    updateProperty,
    formItem,
    formErrors,
    isValid,
    isSubmitting
  } = useFormValidation<S>(_item, validator);

  const [errorMessage, setErrorMessage] = useErrorMessage(
    [formErrors, firestoreItemError]);

  useEffect(() => {
    if (errorMessage && errorMessage.length > 0) {
      onError && onError(errorMessage);
    }
  }, [errorMessage, onError]);

  async function onSubmit() {
    if (formItem == null) {
      setErrorMessage("Form has not been initialized");
      return;
    }
    preSubmit && await preSubmit(formItem);
    if (formItem && formItem.id) {
      console.log("Updating item", _item);
      try {
        await updateItemInFirestore(formItem, _item);
      } catch (e) {
        console.log("onSubmit Update  Error", e);
        return;
      }
    } else {
      console.log("Adding item", item);
      await addItemToFirestore(formItem);
    }
    onComplete && onComplete();
  }

  if (isSubmitting) {
    return <CircularProgress/>
  }

  return (
    <form
      className={classes.form}
      id={formId}
      onSubmit={(e) => handleSubmit(e, onSubmit)}
    >
      {fieldRenderer(formItem, formErrors, handleBlur, handleChange, updateProperty)}
      {!hideButtons &&
        <Button
          className={classes.submitButton}
          color="primary"
          disabled={!isValid}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {formItem?.id == null ? "Add" : "Update"}
        </Button>
      }
      {!hideError &&
        <Typography
          className={classes.errorText}
          color="error"
          variant="body1"
        >
          {errorMessage || ""}
        </Typography>
      }
    </form>
  );
}

export default ItemEdit;

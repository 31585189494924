import cloneDeep from '../helpers/cloneDeep';
import {difference} from '../helpers/objectDifference';

export class BaseModel {
  [key: string]: any;

  static fromMap<T extends typeof BaseModel>(this: T, data: Partial<InstanceType<T>>): InstanceType<T> {
    const base = new this() as InstanceType<T>;
    base.setFromMap(data);
    return base;
  }

  // Create a map containing this instances properties
  toMap(): {[key:string]: any} {
    const map: {[key:string]: any} = {};
    Object.entries(this).forEach(([key, value]) => {
      if (value != null) {
        if (value instanceof BaseModel) {
          map[key] = value.toMap();
        } else {
          map[key] = value;
        }
      }
    });
    return map;
  }

  // Extract the values for this instance from the provided document data
  setFromMap(map?: Partial<this>) {
    if (map == null) {
      return;
    }
    Object.entries(map).forEach(([key, value]) => {
      if (this[key] instanceof BaseModel) {
        this[key].setFromMap(value);
      } else {
        this[key] = value;
      }
    });
  }

  clone() {
    //const copy = new (this.constructor as { new (): T })();
    //Object.assign(copy, cloneDeep<T>(this));
    //const clone = Object.create(Object.getPrototypeOf(this));
    //const descriptors = Object.getOwnPropertyDescriptors(this);
    //Object.defineProperties(clone, descriptors);
    return cloneDeep(this);
  }

  changesFrom(other: this) {
    console.log("this", this);
    console.log("other", other);
    const changes = difference(other, this);
    console.log("changes", changes);
    return changes;
  }

  validate() {
    return true;
  }
}
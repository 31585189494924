import React, {Fragment, FunctionComponent} from 'react';
import {
  Typography
} from '@material-ui/core';

import LabeledText from '../common/LabeledText';
import {AdjunctFirestoreService} from '../../firebase';
import {Adjunct} from '../../models';
import ItemView, {useItemViewStyles} from '../common/ItemView';


interface Props {
  adjunct?: Adjunct | null,
  id?: string|null,
}

const AdjunctView: FunctionComponent<Props> = props => {

  const {adjunct, id} = props;
  console.log("AdjunctView", adjunct);

  const classes = useItemViewStyles();

  const renderView = (item: Adjunct|null) => {
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {item?.name}
        </Typography>
        <Typography
          className={classes.body}
          variant="body1"
        >
          {item?.description}
        </Typography>
        <LabeledText
          label="Picklist Name"
          text={item?.picklistName}
        />
      </Fragment>
    );
  };

  return (
    <ItemView
      item={adjunct}
      id={id}
      service={AdjunctFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default AdjunctView;

import React, {Fragment, FunctionComponent, useContext, useState} from 'react';
import {useHistory} from 'react-router';
import {Button, Grid, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';
import AddressView from '../common/AddressView';
import ItemView, {useItemViewStyles} from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledURL from '../common/LabeledURL';
import {AuthContext} from '../../contexts';
import {BreweryFirestoreService} from '../../firebase';
import {Beer, Brewery} from '../../models';

import {BeerEditDialog} from '../Beer';
import BreweryEditDialog from './BreweryEditDialog';
import BeerCoresGrid from '../Beer/BeerCoresGrid';


const useStyles = makeStyles((theme: Theme) => ({
  beerList: {
    //maxHeight: 200,
    //overflow: 'auto',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  beerListTitle: {
    paddingTop: 5,
    paddingBottom: 0,
    marginTop: 5,
    marginBottom: 0,
  },
}));


interface Props {
  brewery?: Brewery | null,
  id?: string|null,
}

const BreweryView: FunctionComponent<Props> = props => {

  const {brewery, id} = props;

  //console.log("BreweryView", brewery);
  const history = useHistory();

  const viewClasses = useItemViewStyles();
  const classes = useStyles();

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openBeerDialog, setOpenBeerDialog] = useState<boolean>(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickOpenBeerDialog = () => {
    setOpenBeerDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseBeerDialog = () => {
    setOpenBeerDialog(false);
  };

  const handleBeerClick = (beer: Beer|null) => {
    if (beer != null) {
      history.push(`/beer/${beer.id}`);
    }
  };

  const renderView = (item: Brewery|null) => {
    return (
      <div className={viewClasses.root}>
        {canContribute &&
        <div>
            <Button size="small" color="primary"
                    onClick={handleClickOpenDialog}>
                Edit
            </Button>
            <BreweryEditDialog
                brewery={item}
                open={openDialog}
                onClose={handleCloseDialog}
            />
        </div>
        }
        <Grid container spacing={6}>
          <Grid item className={viewClasses.leftCentered}>
            {item?.logoURLs &&
              <img className={viewClasses.image}
                src={item?.logoURLs['400pxURL']}
                alt="Logo"
              />
            }
          </Grid>
          <Grid item className={clsx(viewClasses.info, viewClasses.leftCentered)}>
            <Typography className={viewClasses.title} variant="h5">
              {item?.name}
            </Typography>

            <Typography className={viewClasses.body} variant="body2" color="textSecondary">
              {item?.description}
            </Typography>

            {item?.addresses && item?.addresses.map((address, i) =>
              <AddressView address={address} className={viewClasses} key={i} labeled/>
            )}
            <LabeledURL
              label="Website"
              url={item?.website}
            />
            <LabeledLinks
              label="Links"
              links={item?.links}
            />
          </Grid>
        </Grid>

        <div className={classes.beerList}>
          <Typography className={classes.beerListTitle} variant="h4">
            Beers
          </Typography>
          <BeerCoresGrid
            brewery={item}
            onSelect={beer => handleBeerClick(beer)}
          />
        </div>

        {canContribute &&
        <Fragment>
            <Button size="small" color="primary"
                    onClick={handleClickOpenBeerDialog}>
                Add Beer
            </Button>
            <BeerEditDialog
                brewery={item}
                open={openBeerDialog}
                onClose={handleCloseBeerDialog}
            />
        </Fragment>
        }
        </div>
    );
  };

  return (
    <ItemView
      item={brewery}
      id={id}
      service={BreweryFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default BreweryView;

import React, {Fragment, useEffect, useState} from 'react';
import {TextField, Typography} from '@material-ui/core';

import AddressEdit from '../common/AddressEdit';
import ImagePicker from '../common/ImagePicker';
import LinkListEdit from '../common/LinkListEdit';
import MultiSelect from '../common/MultiSelect';
import {PlaceFirestoreService} from '../../firebase';
import {uploadLogo} from '../../helpers/imageUpload';
import {FormErrors, InputEvent, SelectEvent} from '../../helpers/useFormValidation';
import useListFromFirestoreService
  from '../../helpers/useListFromFirestoreService';
import {Address, Place} from '../../models';
import ItemEdit, {useItemEditStyles} from '../common/ItemEdit';


function validatePlace(values: Place) {
  let errors: FormErrors<Place> = {};

  // Name Errors
  if (!values.name) {
    errors.name = "Name required";
  } else if (values.name.length < 2) {
    errors.name = "Name must be at least 2 characters";
  }

  // Coordinate
  // TODO(): Figure out how to assign coordinateString a value
  //if (values.coordinatesString && values.coordinatesString.length > 0) {
    //if (values.geoLocation?.geopoint == null) {
    //  errors.coordsString = "Invalid coordinates. Must be two numbers separated by a comma";
    //}
  //}

  return errors;
}

const INITIAL_VALUE = new Place();

interface Props {
  place?: Place|null,
  formId?: string,
  onCancel?: () => void,
  onComplete?: () => void,
  onError?: (msg: string) => void,
  hideButtons?: boolean,
  hideError?: boolean,
}

const PlaceEdit = (props: Props) => {

  const {formId, place, onComplete, onError, hideButtons=false, hideError=false} = props;

  //console.log("PlaceEdit place", place);

  const classes = useItemEditStyles();

  const [_coords, setCoords] = useState<string>("");
  const [_logoFile, setLogoFile] = useState<File|null>(null);

  useEffect(() => {
    if (place != null) {
      setCoords(place.coordinatesString);
    }
  }, [place]);

  const {itemLists} = useListFromFirestoreService(
    'placeLists', ['services'], false);

  async function preSubmit(item: Place) {
    if (_logoFile != null) {
      item.logoURLs = await uploadLogo(_logoFile, 'logos/places');
    }
    item.coordinatesString = _coords;
  }

  const renderFields = (
    formItem: Place|null,
    formErrors: FormErrors<Place>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {
    const logoURL = formItem?.logoURLs && formItem.logoURLs['originalURL'];

    function handleAddressChange(address: Address) {
      updateProperty && updateProperty("address", address);
    }

    function handleCoordsChange(e: InputEvent) {
      console.log("coords", _coords);
      setCoords(e.target.value || "");
    }

    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {place?.name || "New Place"}
        </Typography>
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.name != null}
          fullWidth
          helperText={formErrors.name}
          label="Name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.name || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.description != null}
          fullWidth
          helperText={formErrors.description}
          label="Description"
          multiline
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.description || ''}
          variant="outlined"
        />
        <AddressEdit
          address={formItem?.address}
          classes={classes}
          onChange={handleAddressChange}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.phone != null}
          fullWidth
          helperText={formErrors.phone}
          label="Phone"
          name="phone"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.phone || ''}
          variant="outlined"
        />
        <MultiSelect
          label="Services"
          name="services"
          items={itemLists.services}
          values={formItem?.services}
          onChange={(vals) => updateProperty("services", vals)}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.coordsString != null}
          fullWidth
          helperText={formErrors.coordinatesString}
          label="Latitude, Longitude"
          name="coordinatesString"
          onChange={handleCoordsChange}
          //onBlur={handleBlur}
          type="text"
          value={_coords}
          variant="outlined"
        />
        <ImagePicker
          label="Logo"
          imageURL={logoURL}
          folder="logos/places"
          onChange={setLogoFile}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.website != null}
          fullWidth
          helperText={formErrors.website}
          label="Website"
          name="website"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.website || ''}
          variant="outlined"
        />
        <LinkListEdit
          value={formItem?.links}
          onChange={(val) => updateProperty("links", val)}
        />
      </Fragment>
    );
  };

  return (
    <ItemEdit
      item={place}
      defaultValue={INITIAL_VALUE}
      service={PlaceFirestoreService}
      fieldRenderer={renderFields}
      validator={validatePlace}
      preSubmit={preSubmit}
      formId={formId}
      onComplete={onComplete}
      onError={onError}
      hideButtons={hideButtons}
      hideError={hideError}
    />
  )
};

export default PlaceEdit;

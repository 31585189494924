import React, {FunctionComponent} from 'react';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(3)
  },
  body: {
    marginTop: theme.spacing(2)
  },
  h2: {
    marginTop: theme.spacing(5)
  },
  h3: {
    marginTop: theme.spacing(2)
  },
  h4: {
    marginTop: theme.spacing(2)
  },
}));

interface Props {
  className?: string,
}

const HomeView: FunctionComponent<Props> = props => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h1">
        Welcome to BevDb!
      </Typography>
      <p> </p>

      <Typography className={classes.h2} variant="h2">
        What is BevDb?
      </Typography>
      <p>BevDb is a data repository for publicly available information related to the beverage industry.</p>
      <p>Data in BevDb is collected and maintained through crowdsourcing.</p>
      <p>You are free to browse the content on bevdb.org without registering, but an account is required to contribute.</p>

      <Typography className={classes.h2} variant="h2">
        Why BevDb
      </Typography>
      <p>The goal of BevDb is to be a single source of beverage related data that can be used without restrictions imposed by BevDb.</p>
      <p>Data entered into BevDb by the community is made available to community without restrictions above those imposed by the content owners.</p>
      <p>Data downloaded from BevDb can be used to power applications, websites, or even other databases.</p>
  </div>
  );
};

export default HomeView;
import React, {} from 'react';
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';

import {Beer, BreweryCore, Brewery} from '../../models';
import {BeerFirestoreService} from '../../firebase';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';

const useStyles = makeStyles((theme: Theme) => ({
  beerList: {
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  beerListItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  beerListText: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

interface Props {
  brewery?: BreweryCore | Brewery | null,
  onSelect?: (item: Beer|null) => void,
}

const BeersList = (props: Props) => {
  console.log("Refreshing BeersList");

  const {brewery, onSelect} = props;
  const classes = useStyles();

  const {firestoreItems} = useStreamFromFirebaseService<Beer,BeerFirestoreService>(
      BeerFirestoreService, {kwArgs: {brewery: brewery}});

  const handleClick = (beer: Beer) => {
    onSelect && onSelect(beer);
  };

  return (
    <List dense className={classes.beerList}>
    {firestoreItems && firestoreItems.map((beer, i) =>
      <ListItem button divider key={i}
                className={classes.beerListItem}
                onClick={() => handleClick(beer)}>
        <ListItemText className={classes.beerListText}
            primary={beer.name || ""} secondary={beer.type?.name || ""} />
      </ListItem>
    )}
    </List>
  )
};

export default BeersList;

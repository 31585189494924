import React, {useState} from 'react';

import CardGrid from '../common/CardGrid';
import {BeerFirestoreService} from '../../firebase';
import {Beer} from '../../models';

import BeerCard from './BeerCard';
import BeerEditDialog from './BeerEditDialog';


interface Props {
  onEdit?: (item: Beer|null) => void,
  onSelect?: (item: Beer|null) => void,
}

const BeersCardGrid = (props: Props) => {
  console.log("Refreshing BeersCardGrid");

  const {onEdit, onSelect} = props;

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const handleCloseDialog = (value: string) => {
    setOpenAddDialog(false);
  };

  return (
    <CardGrid
        card={<BeerCard/>}
        editDialog={<BeerEditDialog open={openAddDialog} onClose={handleCloseDialog}/>}
        firestoreService={BeerFirestoreService}
        onEdit={onEdit}
        onSelect={onSelect}
        openDialog={() => setOpenAddDialog(true)}
    />
  );
};

export default BeersCardGrid;

import React, {Fragment} from 'react';
import {
  TextField,
  Typography
} from '@material-ui/core';

import {BeerTypeFirestoreService} from '../../firebase';
import {FormErrors, InputEvent, SelectEvent} from '../../helpers/useFormValidation';
import {BeerType} from '../../models';
import ItemEdit, {useItemEditStyles} from '../common/ItemEdit';
import LinkListEdit from '../common/LinkListEdit';


function validateBeerType(values: BeerType) {
  let errors: FormErrors<BeerType> = {};

  // Name Errors
  if (!values.name) {
    errors.name = "Name required";
  } else if (values.name.length < 2) {
    errors.name = "Name must be at least 2 characters";
  }
  // Picklist Name Errors
  if (!values.picklistName) {
    errors.picklistName = "Picklist Name required";
  } else if (values.picklistName.length < 2) {
    errors.picklistName = "Picklist Name must be at least 2 characters";
  }
  /*
  // Brewers Association Errors
  if (!values.brewersAssociation) {
    errors.brewersAssociation = "Brewers Association type required";
  } else if (values.brewersAssociation.length < 2) {
    errors.brewersAssociation = "Brewers Association type must be at least 2 characters";
  }
  */

  return errors;
}

const INITIAL_VALUE = new BeerType();

interface Props {
  beerType?: BeerType | null
  id?: string | null;
  onComplete?: () => void
}

const BeerTypeEdit = (props: Props) => {

  const {beerType, id, onComplete} = props;
  console.log("BeerTypeEdit", beerType);

  const classes = useItemEditStyles();

  const renderFields = (
    formItem: BeerType|null,
    formErrors: FormErrors<BeerType>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {formItem?.name || "New Beer Type"}
        </Typography>
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.name != null}
          fullWidth
          helperText={formErrors.name}
          label="Name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.name || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.picklistName != null}
          fullWidth
          helperText={formErrors.picklistName}
          label="Picklist Name"
          name="picklistName"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.picklistName || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.brewersAssociation != null}
          fullWidth
          helperText={formErrors.brewersAssociation}
          label="Brewers Association"
          name="brewersAssociation"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.brewersAssociation || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.description != null}
          fullWidth
          helperText={formErrors.description}
          label="Description"
          multiline
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.description || ''}
          variant="outlined"
        />
        <LinkListEdit
          value={formItem?.links}
          onChange={(val) => updateProperty("links", val)}
        />
      </Fragment>
    )
  };

  return (
    <ItemEdit
      item={beerType}
      defaultValue={INITIAL_VALUE}
      id={id}
      service={BeerTypeFirestoreService}
      fieldRenderer={renderFields}
      validator={validateBeerType}
      onComplete={onComplete}
    />
  );
};

export default BeerTypeEdit;

import React, {FunctionComponent} from 'react';
import {Theme, makeStyles} from '@material-ui/core/styles';

import TopBar from './TopBar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 64,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  content: {
    height: '100%'
  }
}));

interface Props {
  className?: string,
}

const Minimal: FunctionComponent<Props> = props => {
  const {children} = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
};

export default Minimal;
import {FirestoreBase} from './FirestoreBase';


export class Adjunct extends FirestoreBase {
  name?: string;
  picklistName?: string;
  description?: string;

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    if (this.picklistName == null || this.picklistName === "") {
      return false;
    }
    return true;
  }
}

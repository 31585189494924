import {FirestoreService} from './FirestoreService';
import {Brewery} from '../models';


export class BreweryFirestoreService extends FirestoreService<Brewery> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'breweries',
      objectCreator: Brewery,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log("BreweryService construct");
  }
}

import React, {useEffect, useState} from 'react';
import {Card, Paper, Tab, Tabs} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {AdjunctsList} from '../components/Adjunct';
import {BeerTypesList} from '../components/BeerType';
import {HopsList} from '../components/Hop';
import {MaltsList} from '../components/Malt';
import {YeastsList} from '../components/Yeast';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    margin: 10,
  },
  panel: {
    flexGrow: 1,
    margin: 10,
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export default function BeerIngredientsView() {
  const classes = useStyles();

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    console.log("BeerIngredientsView useEffect");
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function HopsPanel() {
    return (
      <div className={classes.panel}>
        <Card className={classes.card}>
          <HopsList />
        </Card>
      </div>
    );
  }

  function MaltsPanel() {
    return (
      <div className={classes.panel}>
        <Card className={classes.card}>
          <MaltsList />
        </Card>
      </div>
    );
  }

  function YeastsPanel() {
    return (
      <div className={classes.panel}>
        <Card className={classes.card}>
          <YeastsList />
        </Card>
      </div>
    );
  }

  function AdjunctsPanel() {
    return (
      <div className={classes.panel}>
        <Card className={classes.card}>
          <AdjunctsList />
        </Card>
      </div>
    );
  }

  function BeerTypesPanel() {
    return (
      <div className={classes.panel}>
        <Card className={classes.card}>
          <BeerTypesList />
        </Card>
      </div>
    );
  }


  return (
    <div>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Hops" />
          <Tab label="Malts" />
          <Tab label="Yeasts" />
          <Tab label="Adjuncts" />
          <Tab label="Beer Types" />
        </Tabs>
      </Paper>
      {value === 0 && HopsPanel()}
      {value === 1 && MaltsPanel()}
      {value === 2 && YeastsPanel()}
      {value === 3 && AdjunctsPanel()}
      {value === 4 && BeerTypesPanel()}
    </div>
  );
}

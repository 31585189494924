import React, {ChangeEvent, useEffect, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  label: {
  },
  input: {
    marginTop: theme.spacing(3)
  },
  image: {
    height: 300,
    width: "100%",
    objectFit: "contain",
    maxWidth: 400,
    backgroundSize: "contain",
  },
}));

interface Props {
  folder?: string,
  imageURL?: string,
  label?: string|null;
  onChange?: (file: File) => void;
}


const ImagePicker = ({folder='', ...props}: Props) => {

  const {imageURL, label, onChange} = props;
  const [url, setURL] = useState<string>("");

  useEffect(() => {
    if (imageURL != null) {
      setURL(imageURL);
    }
  }, [imageURL]);

  const classes = useStyles();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    console.log('files', files);
    if (files == null) {
      return;
    }
    if (files[0]) {
      const file = files[0];
      setURL(URL.createObjectURL(file));
      onChange && onChange(file);
    }
  };

  const createImagePicker = () => (
    <div>
      <input
        accept="image/*"
        className={classes.input}
        id="icon-button-photo"
        onChange={handleChange}
        type="file"
      />
      {url &&
      <img
        className={classes.image}
          src={url}
          alt="Uploaded Images"
        />
      }
    </div>

  );

  if (label != null) {
    return (
      <div className={classes.root}>
        <Typography
            className={classes.label}
            variant="h6"
            component="h6"
            display="inline"
        >
          {label}
        </Typography>
        {createImagePicker()}
      </div>
    )
  }
  return createImagePicker();
};

export default ImagePicker;

import {PlaceBeer} from './PlaceBeer';

const actions = <const> ['Add', 'Confirm', 'Remove'];
type BeerSightingActions = typeof actions[number];

interface BeerSightingArgs {
  placeBeerId?: string;
  reportedOn?: Date;
  reportedById?: string;
  action?: BeerSightingActions;
}


export class BeerSighting extends PlaceBeer {

  placeBeerId?: string;
  reportedOn?: Date;
  reportedById?: string;
  action?: BeerSightingActions;

  constructor(kwArgs?: BeerSightingArgs) {
    super();
    if (kwArgs != null) {
      this.placeBeerId = kwArgs.placeBeerId;
      this.reportedById = kwArgs.reportedById;
      this.reportedOn = kwArgs.reportedOn;
      this.action = kwArgs.action;
    }
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    return true;
  }
}

import React, {FunctionComponent, ReactElement, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
  Card,
  CardActionArea, CardActions,
  CardContent,
  CardMedia, Collapse, IconButton,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import clsx from 'clsx';


const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: 345,
    height: "100%"
  },
  media: {
    height: 200,
    width: "100%",
    objectFit: "contain",
    maxWidth: 300,
    backgroundSize: "contain",
  },
  cardContent: {
    paddingBottom: 0,
  },
  actions: {
    paddingBottom: 5,
    paddingTop: 5,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandedContent: {
    paddingTop: 0,
  },
}));


interface Props {
  actionContent?: ReactElement;
  cardContent?: ReactElement;
  editable?: boolean,
  expandedContent?: ReactElement;
  logoURL?: string,
  onClick?: () => void;
  title?: string,
}


const GridCard: FunctionComponent<Props> =
    ({editable=false,
       ...props}) => {

  const {actionContent, cardContent, expandedContent, logoURL, onClick, title} = props;

  console.log("GridCard");

  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          className={classes.media}
          image={logoURL || "/images/logo512.png"}
          title={title}
        />
        <CardContent className={classes.cardContent}>
          {cardContent}
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className={classes.actions}>
        {actionContent}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMore />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.expandedContent}>
          {expandedContent}
        </CardContent>
      </Collapse>
    </Card>
  )
};

export default GridCard;

import {FirestoreBase} from './FirestoreBase';


export class UserRoles extends FirestoreBase {

  canAdminister: boolean = false;
  canApprove: boolean = false;
  canContribute: boolean = true;

  validate() {
    return true;
  }
}

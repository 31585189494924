import {Address} from './Address';
import {FirestoreBase} from './FirestoreBase';
import {Item} from './Item';


export class BreweryCore extends Item {

  static fromBrewery(brewery: Brewery): BreweryCore {
    const base = new BreweryCore();
    base.id = brewery.id;
    base.name = brewery.name;
    return base;
  }
}


export class Brewery extends FirestoreBase {

  name?: string;
  description?: string;
  addresses?: Address[] = [new Address()];
  website?: string;
  logoURLs?: {[key: string]: string};
  links?: {[key: string]: string};
  keywords?: string[];

  get cityState() {
    if (this.addresses != null && this.addresses.length > 0) {
      return this.addresses[0].cityState;
    }
    return "";
  }

  // Extract the values for this instance from the provided document data
  setFromMap(map?: Partial<this>) {
    if (map && "addresses" in map) {
      this.addresses = map.addresses?.map(address => {
        const addressObject = new Address();
        addressObject.setFromMap(address);
        return addressObject;
      });
      delete map.addresses;
    }
    super.setFromMap(map);
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    return true;
  }
}

import React, {FunctionComponent, useState} from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SideBar from './SideBar';
import TopBar from './TopBar';
import Footer from './Footer';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 64,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

interface Props {
}

const Main: FunctionComponent<Props> = props => {
  const {children} = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSideBar, setOpenSideBar] = useState(false);

  const handleSideBarOpen = () => {
    setOpenSideBar(true);
  };

  const handleSideBarClose = () => {
    setOpenSideBar(false);
  };

  const shouldOpenSideBar = isDesktop ? true : openSideBar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <TopBar
        onSideBarOpen={handleSideBarOpen}
      />
      <SideBar
        onClose={handleSideBarClose}
        open={shouldOpenSideBar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

export default Main;
import React, {Fragment} from 'react';
import {
  TextField,
  Typography
} from "@material-ui/core";

import LinkListEdit from '../common/LinkListEdit';
import {HopFirestoreService} from '../../firebase';
import {FormErrors, InputEvent, SelectEvent} from '../../helpers/useFormValidation';
import {Hop} from '../../models';
import ItemEdit, {useItemEditStyles} from '../common/ItemEdit';


function validateHop(values: Hop) {
  let errors: FormErrors<Hop> = {};

  // Name Errors
  if (!values.name) {
    errors.name = "Name required";
  } else if (values.name.length < 2) {
    errors.name = "Name must be at least 2 characters";
  }

  // Picklist Name Errors
  if (!values.picklistName) {
    errors.picklistName = "Picklist Name required";
  } else if (values.picklistName.length < 2) {
    errors.picklistName = "Picklist Name must be at least 2 characters";
  }

  return errors;
}

const INITIAL_VALUE = new Hop();

interface Props {
  hop?: Hop | null
  id?: string | null;
  onComplete?: () => void
}

const HopEdit = (props: Props) => {

  const {hop, id, onComplete} = props;
  console.log("HopEdit", hop);

  const classes = useItemEditStyles();

  const renderFields = (
    formItem: Hop|null,
    formErrors: FormErrors<Hop>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {formItem?.name || "New Hop"}
        </Typography>
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.name != null}
          fullWidth
          helperText={formErrors.name}
          label="Name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.name || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.picklistName != null}
          fullWidth
          helperText={formErrors.picklistName}
          label="Picklist Name"
          name="picklistName"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.picklistName || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.description != null}
          fullWidth
          helperText={formErrors.description}
          label="Description"
          multiline
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.description || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.alphaMin != null}
          helperText={formErrors.alphaMin}
          label="Alpha Acid (Min %)"
          name="alphaMin"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.alphaMin || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.alphaMax != null}
          helperText={formErrors.alphaMax}
          label="Alpha Acid (Max %)"
          name="alphaMax"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.alphaMax || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.creator != null}
          fullWidth
          helperText={formErrors.creator}
          label="Created By"
          name="creator"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.creator || ''}
          variant="outlined"
        />
        <LinkListEdit
          value={formItem?.links}
          onChange={(val) => updateProperty("links", val)}
        />
      </Fragment>
    );
  };

  return (
    <ItemEdit
      item={hop}
      defaultValue={INITIAL_VALUE}
      id={id}
      service={HopFirestoreService}
      fieldRenderer={renderFields}
      validator={validateHop}
      onComplete={onComplete}
    />
  );
};

export default HopEdit;

import React, { FunctionComponent } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {SignIn, SignUp, VerifySignIn} from '../components/SignIn';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  quote: {
    backgroundColor: theme.palette.text.primary,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
}));


const SignInView: FunctionComponent = () => {

  const location = useLocation();
  const history = useHistory();

  console.log("location", location);

  const classes = useStyles();

  const handleBack = () => {
    history.goBack();
  };

  const goBack = () => {
    if (history.length <= 1) {
      history.replace('/home');
    } else {
      history.goBack();
    }
  };

  const createSignInComponent = () => {
    switch (location.pathname) {
      case "/sign-up":
        return <SignUp onComplete={goBack}/>;
      case "/verify-sign-in":
        return <VerifySignIn onComplete={goBack}/>;
      default:
        return <SignIn onComplete={goBack}/>;
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={1}
        >
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={11}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              {createSignInComponent()}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignInView;

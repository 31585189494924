import {FirestoreBase} from './FirestoreBase';


export class Hop extends FirestoreBase {

  name?: string;
  picklistName?: string;
  description?: string;
  aliases?: string[];
  alphaMin?: number;
  alphaMax?: number;
  creator?: string;
  links?: {[key: string]: string};

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    if (this.picklistName == null || this.picklistName === "") {
      return false;
    }
    return true;
  }
}

import React, {Fragment} from 'react';
import {
  TextField,
  Typography
} from '@material-ui/core';

import ItemEdit, {useItemEditStyles} from '../common/ItemEdit';
import {AdjunctFirestoreService} from '../../firebase';
import {FormErrors, InputEvent, SelectEvent} from '../../helpers/useFormValidation';
import {Adjunct} from '../../models';


function validateAdjunct(item: Adjunct) {
  let errors: FormErrors<Adjunct> = {};

  // Name Errors
  if (!item.name) {
    errors.name = 'Name required';
  } else if (item.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  }
  // Picklist Name Errors
  if (!item.picklistName) {
    errors.picklistName = 'Picklist Name required';
  } else if (item.picklistName.length < 2) {
    errors.picklistName = 'Picklist Name must be at least 2 characters';
  }

  return errors;
}

const INITIAL_VALUE = new Adjunct();

interface Props {
  adjunct?: Adjunct | null;
  id?: string | null;
  onComplete?: () => void;
}

const AdjunctEdit = (props: Props) => {

  const {adjunct, id, onComplete} = props;
  console.log("AdjunctEdit", adjunct);

  const classes = useItemEditStyles();

  const renderFields = (
    formItem: Adjunct|null,
    formErrors: FormErrors<Adjunct>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void
  ) => {
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {formItem?.name || "New Adjunct"}
        </Typography>
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.name != null}
          fullWidth
          helperText={formErrors.name}
          label="Name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.name || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.picklistName != null}
          fullWidth
          helperText={formErrors.picklistName}
          label="Picklist Name"
          name="picklistName"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.picklistName || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.description != null}
          fullWidth
          helperText={formErrors.description}
          label="Description"
          multiline
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.description || ''}
          variant="outlined"
        />
      </Fragment>
    );
  };

  return (
    <ItemEdit
      item={adjunct}
      defaultValue={INITIAL_VALUE}
      id={id}
      service={AdjunctFirestoreService}
      fieldRenderer={renderFields}
      validator={validateAdjunct}
      onComplete={onComplete}
    />
  );
};

export default AdjunctEdit;

import {FirestoreService} from './FirestoreService';
import {Yeast} from '../models';


export class YeastFirestoreService extends FirestoreService<Yeast> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'yeasts',
      objectCreator: Yeast,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log('YeastService construct');
  }
}

import {default as fb} from 'firebase';
const DocumentReference = fb.firestore.DocumentReference;

export default function cloneDeep(obj: any): any {

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
     const clone = new Date();
     clone.setTime(obj.getTime());
     return clone;
  }

  // Handle Array
  if (obj instanceof Array) {
    const clone = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      clone[i] = cloneDeep(obj[i]);
    }
    return clone;
  }

  // Do not try to clone DocumentReference objects as an infinite loop will occur
  if (obj instanceof DocumentReference) {
    return obj;
  }

  // Handle Object
  if (obj instanceof Object) {
    const clone = Object.create(obj);
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        clone[attr] = cloneDeep(obj[attr]);
      }
    }
    return clone;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

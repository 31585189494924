import React, {Fragment, FunctionComponent, useContext, useState} from 'react';
import {useHistory} from 'react-router';
import {Button, Typography} from '@material-ui/core';

import GridCard from '../common/GridCard';
import {Beer} from '../../models';

import BeerEditDialog from './BeerEditDialog';
import {AuthContext} from '../../contexts';
import LabeledLinks from '../common/LabeledLinks';
import LabeledText from '../common/LabeledText';
import LabeledURL from '../common/LabeledURL';


interface Props {
  item?: Beer | null,
}


const BeerCard: FunctionComponent<Props> = props => {

  const {item} = props;
  const history = useHistory();

  console.log("BeerCard brewery", item);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  if (item == null) {
    return null;
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = () => {
    history.push(`/beer/${item.id}`);
  };

  const logoURL = item?.logoURLs && item.logoURLs['400pxURL'];

  const CardContent = (
    <Fragment>
      <Typography gutterBottom variant="h5" component="h2">
        {item.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.type?.name || ""}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.brewery?.name || ""}
      </Typography>
    </Fragment>
  );

  const createActionContent = () => {
    if (canContribute) {
      return (
        <Fragment>
          <Button size="small" color="primary"
                  onClick={handleClickOpenDialog}>
            Edit
          </Button>
          <BeerEditDialog
              beer={item}
              open={openDialog}
              onClose={handleCloseDialog}
          />
        </Fragment>
      );
    }
  };

  const ExpandedContent = (
    <Fragment>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.description}
      </Typography>

      <LabeledText
          label="ABV"
          text={item.abv != null ? item.abv.toLocaleString() : ""}
      />
      <LabeledText
          label="IBU"
          text={item.ibu != null ? item.ibu.toLocaleString() : ""}
      />
      <LabeledText
          label="Availability"
          text={item.availability?.name}
      />
      <LabeledText
          label="Hops"
          text={item.hops && item.hops.map(hop => hop.name).join(", ")}
      />
      <LabeledText
          label="Malts"
          text={item.malts && item.malts.map(malt => malt.name).join(", ")}
      />
      <LabeledText
          label="Yeasts"
          text={item.yeasts && item.yeasts.map(yeast => yeast.name).join(", ")}
      />
      <LabeledText
          label="Adjuncts"
          text={item.adjuncts && item.adjuncts.map(adjunct => adjunct.name).join(", ")}
      />

      <LabeledURL
        label="Website"
        url={item.website}
      />
      <LabeledLinks
        label="Links"
        links={item.links}
      />
    </Fragment>
  );

  return (
    <GridCard
      title={item.name}
      logoURL={logoURL}
      cardContent={CardContent}
      actionContent={createActionContent()}
      expandedContent={ExpandedContent}
      onClick={handleClick}
    >
    </GridCard>
  );
};

export default BeerCard;

import firebase from './firebase';

export default firebase;
export {User} from '../models';
export {AuthService, EmailNotVerifiedError} from './AuthService';
export {AdjunctFirestoreService} from './AdjunctFirestoreService';
export {BeerFirestoreService} from './BeerFirestoreService';
export {BeerSightingFirestoreService} from './BeerSightingFirestoreService';
export {BeerTypeFirestoreService} from './BeerTypeFirestoreService';
export {BreweryFirestoreService} from './BreweryFirestoreService';
export {FirestoreService} from './FirestoreService';
export {HopFirestoreService} from './HopFirestoreService';
export {ListFirestoreService} from './ListFirestoreService';
export {MaltFirestoreService} from './MaltFirestoreService';
export {PlaceFirestoreService} from './PlaceFirestoreService';
export {PlaceBeerFirestoreService} from './PlaceBeerFirestoreService';
export {TransactionFirestoreService} from './TransactionFirestoreService';
export {UserFirestoreService} from './UserFirestoreService';
export {YeastFirestoreService} from './YeastFirestoreService';

export const functions = firebase.functions();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

import {storage} from '../firebase';
import {createUniqueId} from './createUniqueId';


export async function uploadFile(file: File, filename: string, dir: string) {

  let filePath = `/uploads/${dir}/${filename}`;
  console.log('File to upload', filePath);

  let url = "";
  // Upload image to firebase storage
  const firebaseStorageRef = storage.ref().child(filePath);
  const snapshot = await firebaseStorageRef.put(file);
  console.log("upload snapshot complete");
  url = await snapshot.ref.getDownloadURL();
  console.log("Got url", url);
  return filePath; //url;
}


export async function uploadLogo(logoFile: File, logoDir: string) {

  const extension = logoFile.name.split('.').pop();
  console.log('Logo name', logoFile.name, extension);
  let newFilename = createUniqueId();
  if (extension !== logoFile.name) {
    newFilename = `${newFilename}.${extension}`;
  }

  let uploadPath: string|null = null;
  try {
    uploadPath = await uploadFile(logoFile, newFilename, logoDir);
    console.log("Got image path", uploadPath);
  } catch (e) {
    console.log("Error uploading logo", e);
    throw Error(e.message);
  }
  return {'originalURL': uploadPath};
}

import {FirestoreBase} from './FirestoreBase';
import {BreweryCore} from './Brewery';
import {Item} from './Item';
import {PicklistItem} from './PicklistItem';

export class BeerCore extends Item {

  static fromBeer(beer: Beer): BeerCore {
    const base = new BeerCore();
    base.id = beer.id;
    base.name = beer.name;
    base.type = beer.type;
    base.brewery = beer.brewery;
    base.logoURL = beer.logoURLs && beer.logoURLs['100pxURL'];
    return base;
  }

  type?: PicklistItem;
  brewery?: BreweryCore;
  logoURL?: string;
}


export class Beer extends FirestoreBase {

  name?: string;
  type?: PicklistItem;
  description?: string;
  brewery?: BreweryCore;
  adjuncts?: PicklistItem[];
  hops?: PicklistItem[];
  malts?: PicklistItem[];
  yeasts?: PicklistItem[];
  abv?: number;
  ibu?: number;
  availability?: Item;
  website?: string;
  logoURLs?: {[key: string]: string};
  links?: {[key: string]: string};
  keywords?: string[];

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    return true;
  }
}

import {useState, useEffect} from 'react';

import {FirestoreBase} from '../models';
import {FirestoreService} from '../firebase';
import useFirestoreService from "./useFirestoreService";


export default function useItemFromFirestoreService<S extends FirestoreBase, T extends FirestoreService<S>>(
    id: string|null|undefined,
    service: new () => T
) {
  const [firestoreItem, setFirestoreItem] = useState<S|null>(null);
  const [isRetrieving, setIsRetrieving] = useState<boolean>(false);
  const [firestoreItemError, setFirestoreItemError] = useState<string|null>(null);

  const {
    db,
    addItemToFirestore,
    updateItemInFirestore,
    deleteItemInFirestore,
    firestoreError} = useFirestoreService<S,T>(service);

  useEffect(() => {
    if (firestoreError != null) {
      setFirestoreItemError(firestoreError);
    }
  }, [firestoreError]);

  useEffect(() => {
    if (id == null) {
      //const msg = "Item id must be provided";
      //console.error(msg);
      //setError(msg);
      return;
    }

    if (db == null) {
      return;
    }

    async function getItem(id: string) {
      setIsRetrieving(true);
      try {
        const item = await db!.getById(id);
        console.log('item:', item);
        if (item != null) {
          setFirestoreItem(item);
        }
      } catch (e) {
        setFirestoreItemError(e.message);
      }
      setIsRetrieving(false);
    }
    getItem(id);
  }, [db, id]);

  return {
    firestoreItem,
    addItemToFirestore,
    updateItemInFirestore,
    deleteItemInFirestore,
    isRetrieving,
    db,
    firestoreItemError
  };
}

import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import RouteWithLayout from './RouteWithLayout';
import {AdjunctView} from '../components/Adjunct';
import {BeerView} from '../components/Beer';
import {PlaceBeerView} from '../components/Place';
import {BeerTypeView} from '../components/BeerType';
import {BreweryView} from '../components/Brewery';
import ComingSoon from '../components/common/ComingSoon';
import HopEdit from '../components/Hop/HopEdit';
import {HopView} from '../components/Hop';
import {MaltView} from '../components/Malt';
import {Main as MainLayout, Minimal as MinimalLayout} from '../layouts';
import {
  BeerIngredientsView,
  BeersView,
  BreweriesView,
  HomeView,
  PlacesView,
  SignInView
} from '../views';
import {PlaceView} from '../components/Place';
import PrivacyPolicy from '../components/common/PrivacyPolicy';
import ResendVerificationEmail
    from '../components/SignIn/ResendVerificationEmail';
import TermsAndConditions from '../components/common/TermsAndConditions';
import TransactionsList from '../components/Transaction/TransactionsList';


const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <RouteWithLayout
        component={AdjunctView}
        exact
        layout={MainLayout}
        path="/adjunct/:id"
      />
      <RouteWithLayout
        component={BeerView}
        exact
        layout={MainLayout}
        path="/beer/:id"
      />
      <RouteWithLayout
        component={BeerIngredientsView}
        exact
        layout={MainLayout}
        path="/beer-ingredients"
      />
      <RouteWithLayout
        component={BeersView}
        exact
        layout={MainLayout}
        path="/beers"
      />
      <RouteWithLayout
        component={BeerTypeView}
        exact
        layout={MainLayout}
        path="/beer-type/:id"
      />
      <RouteWithLayout
        component={BreweriesView}
        exact
        layout={MainLayout}
        path="/breweries"
      />
      <RouteWithLayout
        component={BreweryView}
        exact
        layout={MainLayout}
        path="/brewery/:id"
      />
      <RouteWithLayout
        component={ComingSoon}
        exact
        layout={MainLayout}
        path="/distilleries"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={HopView}
        exact
        layout={MainLayout}
        path="/hop/:id"
      />
      <RouteWithLayout
        component={HopEdit}
        exact
        layout={MainLayout}
        path="/hops/new"
      />
      <RouteWithLayout
        component={MaltView}
        exact
        layout={MainLayout}
        path="/malt/:id"
      />
      <RouteWithLayout
        component={PlacesView}
        exact
        layout={MainLayout}
        path="/places"
      />
      <RouteWithLayout
        component={PlaceView}
        exact
        layout={MainLayout}
        path="/place/:id"
      />
      <RouteWithLayout
        component={PlaceBeerView}
        exact
        layout={MainLayout}
        path="/place-beer/:id"
      />
      <RouteWithLayout
        component={PrivacyPolicy}
        exact
        layout={MainLayout}
        path="/privacy"
      />
      <RouteWithLayout
        component={ResendVerificationEmail}
        exact
        layout={MinimalLayout}
        path="/resend-verification-email"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={ComingSoon}
        exact
        layout={MainLayout}
        path="/spirit-ingredients"
      />
      <RouteWithLayout
        component={ComingSoon}
        exact
        layout={MainLayout}
        path="/spirits"
      />
      <RouteWithLayout
        component={TermsAndConditions}
        exact
        layout={MainLayout}
        path="/terms"
      />
      <RouteWithLayout
        component={TransactionsList}
        exact
        layout={MainLayout}
        path="/transactions"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/verify-sign-in"
      />
      <RouteWithLayout
        component={ComingSoon}
        exact
        layout={MainLayout}
        path="/wineries"
      />
      <RouteWithLayout
        component={ComingSoon}
        exact
        layout={MainLayout}
        path="/wine-ingredients"
      />
      <RouteWithLayout
        component={ComingSoon}
        exact
        layout={MainLayout}
        path="/wines"
      />
    </Switch>
  );
};

export default Routes;
import {default as fb} from 'firebase';

import {User, UserRoles} from '../models';
import {UserFirestoreService} from './UserFirestoreService';
import {UserRolesFirestoreService} from './UserRolesFirestoreService';

type FirebaseAuth = fb.auth.Auth;
type Unsubscribe = fb.Unsubscribe;
type FirebaseUser = fb.User;

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `https://${process.env.REACT_APP_DOMAIN}/verify-sign-in?mode=<action>&oobCode=<code>`,
  // This must be true.
  handleCodeInApp: true,
  //iOS: {
  //  bundleId: 'org.bevdb.ios'
  //},
  //android: {
  //  packageName: 'org.bevdb.android',
  //  installApp: true,
  //  minimumVersion: '12'
  //},
  //dynamicLinkDomain: 'example.page.link'
};

export class EmailNotVerifiedError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = EmailNotVerifiedError.name; // stack traces display correctly now
  }
}

export class AuthService {

  _firebaseAuth: FirebaseAuth = fb.auth();
  userDb: UserFirestoreService;
  userRolesDb: UserRolesFirestoreService;
  fbUser: FirebaseUser|null = null;
  _currentUser: User | null = null;
  _currentUserRoles: UserRoles | null = null;
  _currentUserSetter?: (user: User|null) => void;
  _currentUserRolesSetter?: (userRoles: UserRoles|null) => void;

  constructor(currentUserSetter?: (user: User|null) => void,
              currentUserRolesSetter?: (userRoles: UserRoles|null) => void) {
    this._currentUserSetter = currentUserSetter;
    this._currentUserRolesSetter = currentUserRolesSetter;
    this.userDb = new UserFirestoreService();
    this.userRolesDb = new UserRolesFirestoreService();
    this.getUserFromLocalStorage();
  }

  async _userFromFirestore(fbUser: FirebaseUser|null) {
    console.log(`fbUser: ${fbUser && fbUser.uid}`);
    if (fbUser == null) {
      localStorage.removeItem('user');
      return null;
    }
    if (!fbUser.emailVerified) {
      console.log("Email not verified");
      this.fbUser = fbUser;
      throw new EmailNotVerifiedError();
    }
    localStorage.setItem('user', JSON.stringify(fbUser));

    let user = await this.userDb.getById(fbUser.uid);
    if (user == null) {
      user = await this.userDb.createUser(fbUser.uid,{
        'name': fbUser.displayName,
        'email': fbUser.email,
      });
    }
    this._currentUser = user;
    console.log(`fsUser user: ${user && user.name}`);
    this._currentUserSetter && this._currentUserSetter(user);

    let userRoles = await this.userRolesDb.getById(fbUser.uid);
    if (userRoles == null) {
      userRoles = await this.userRolesDb.createUserRoles(fbUser.uid);
    }
    this._currentUserRoles = userRoles;
    console.log(`fsUser userRoles: ${userRoles}`);
    this._currentUserRolesSetter && this._currentUserRolesSetter(userRoles);

    return user;
  }

  get userStream(): Unsubscribe {
    return this._firebaseAuth.onAuthStateChanged(this._userFromFirestore);
  }

  get onAuthStateChanged(): Unsubscribe {
    return this._firebaseAuth.onAuthStateChanged(this._userFromFirestore);
  }

  get currentUser(): User | null {
    return this._currentUser;
  }

  get currentUserRoles(): UserRoles | null {
    return this._currentUserRoles;
  }

  async getUserFromLocalStorage() {
    const user = localStorage.getItem('user');
    if (user != null) {
      try {
        await this._userFromFirestore(JSON.parse(user));
      } catch (e) {
        localStorage.removeItem('user');
      }
    }
  }

  async signUpWithEmail(name: string, email: string, password: string) {
    try {
      const result = await this._firebaseAuth.createUserWithEmailAndPassword(email, password);
      if (result.user) {
        const user = result.user;
        await user.updateProfile({displayName: name});
        await this._firebaseAuth.updateCurrentUser(user);
        await user.sendEmailVerification(actionCodeSettings);
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
      }
    } catch (e) {
      console.log("signUpEmail Error", e);
      throw e;
    }
  }

  async sendVerificationEmail(fbUser: FirebaseUser) {
    await this._firebaseAuth.updateCurrentUser(fbUser);
  }

  async confirmSignUpEmailLink(email: string, password: string) {
    // Confirm the link is a sign-in with email link.
    console.log("Verify URL:", window.location.href);
    //const params = new URL(window.location.href).searchParams;
    // @ts-ignore
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    console.log('params', params);
    const oobCode = params.get('oobCode');
    console.log('oobCode', oobCode);
    if (oobCode != null) {
      const result = await this._firebaseAuth.applyActionCode(oobCode);
      console.log("action code result", result);
      await this.signInWithEmailAndPassword(email, password)
    }
    /*
    if (this._firebaseAuth.isSignInWithEmailLink(window.location.href)) {
      console.log("is Valid:", this._firebaseAuth.isSignInWithEmailLink(window.location.href));
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      try {
        // The client SDK will parse the code from the link for you.
        const result = await this._firebaseAuth.signInWithEmailLink(email, window.location.href);
        console.log("signInWithEmailLink result", result);
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        console.log("signInWithEmailLink user", result.user);
        this._userFromFirestore(result.user);
      } catch (e) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log("confirmSignUpEmailLink Error", e);
        throw e;
      }
    }
    */
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this._firebaseAuth.signInWithEmailAndPassword(email, password)
        .then((user) => resolve(this._userFromFirestore(user.user)))
        .catch((err) => {console.log('reject');reject(err);console.log('post');});
    });
  }

  async signInWithGoogle() {
    try {
      let provider = new fb.auth.GoogleAuthProvider();
      const result = await this._firebaseAuth.signInWithPopup(provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const token = result.credential!.providerId;
      // The signed-in user info.
      const user = result.user;
      return this._userFromFirestore(user);
    } catch (e) {
      console.log("signInWithGoogle Error", e);
      throw e;
    }
  }

  async signInWithFacebook() {
    try {
      let provider = new fb.auth.FacebookAuthProvider();
      const result = await this._firebaseAuth.signInWithPopup(provider);
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      // const token = result.credential!.providerId;
      // The signed-in user info.
      const user = result.user;
      return this._userFromFirestore(user);
    } catch (e) {
      console.log("signInWithFacebook Error", e);
      throw e;
    }
  }

  async sendPasswordResetEmail(email: string) {
    await this._firebaseAuth.sendPasswordResetEmail(email);
  }

  async signOut() {
    localStorage.removeItem('user');
    this._currentUser = null;
    this._currentUserRoles = null;
    return this._firebaseAuth.signOut();
  }
}

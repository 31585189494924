import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useState
} from 'react';
import {useHistory} from 'react-router';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';

import AddressView from '../common/AddressView';
import GridCard from '../common/GridCard';
import LabeledLinks from '../common/LabeledLinks';
import LabeledText from '../common/LabeledText';
import LabeledURL from '../common/LabeledURL';
import {AuthContext} from '../../contexts';
import {Place} from '../../models';

//import {BeerSightingEditDialog} from './BeerSightingEdit';
import PlaceEditDialog from './PlaceEditDialog';
//import PlaceBeersList from './PlaceBeersList';


const useStyles = makeStyles((theme: Theme) => ({
  body: {
    //marginTop: theme.spacing(2),
    //height: 400,
  },
  beerList: {
    maxHeight: 150,
    overflow: 'auto',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  beerListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  beerListTitle: {
    paddingTop: 5,
    paddingBottom: 0,
    marginTop: 5,
    marginBottom: 0,
  },
}));


interface Props {
  item?: Place | null,
}


const PlaceCard: FunctionComponent<Props> = props => {

  const {item} = props;
  const history = useHistory();

  console.log("PlaceCard brewery", item);

  const classes = useStyles();

  // const [openBeerSightingDialog, setOpenBeerSightingDialog] = useState<boolean>(false);
  const [openPlaceDialog, setOpenPlaceDialog] = useState<boolean>(false);

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  if (item == null) {
    return null;
  }

  /*
  const handleClickOpenBeerSightingDialog = () => {
    setOpenBeerSightingDialog(true);
  };
  const handleCloseBeerSightingDialog = () => {
    setOpenBeerSightingDialog(false);
  };
  */

  const handleClickOpenPlaceDialog = () => {
    setOpenPlaceDialog(true);
  };

  const handleClosePlaceDialog = () => {
    setOpenPlaceDialog(false);
  };

  const handleClick = () => {
    history.push(`/place/${item.id}`);
  };

  const logoURL = item?.logoURLs && item.logoURLs['400pxURL'];

  const CardContent = (
    <Fragment>
      <Typography gutterBottom variant="h5" component="h2">
        {item.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.cityState}
      </Typography>
    </Fragment>
  );

  const createActionContent = () => {
    if (canContribute) {
      return (
        <Fragment>
          <Button size="small" color="primary"
                  onClick={handleClickOpenPlaceDialog}>
            Edit
          </Button>
          <PlaceEditDialog
              place={item}
              open={openPlaceDialog}
              onClose={handleClosePlaceDialog}
          />
        </Fragment>
      );
    }
  };

  const ExpandedContent = (
    <Fragment>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.description}
      </Typography>

      <LabeledText
          label="Services"
          text={item.services && item.services.map(service => service.name).join(", ")}
      />

      <AddressView address={item.address} className={classes} labeled/>
      <LabeledText
          label="Phone"
          text={item.phone}
      />
      <LabeledURL
        label="Website"
        url={item.website}
      />
      <LabeledLinks
        label="Links"
        links={item.links}
      />

      {/** These lists load even if the card isn't expanded and they can take
           too much space
      {<PlaceBeersList place={item} status="Confirmed"/>}
      {<PlaceBeersList place={item} status="Reported"/>}
      {canContribute &&
        <Fragment>
          <Button size="small" color="primary"
                  onClick={handleClickOpenBeerSightingDialog}>
              Add Beer
          </Button>
          <BeerSightingEditDialog
            place={item}
            open={openBeerSightingDialog}
            onClose={handleCloseBeerSightingDialog}
          />
        </Fragment>
      }
      **/}
    </Fragment>
  );

  return (
    <GridCard
      title={item.name}
      logoURL={logoURL}
      cardContent={CardContent}
      actionContent={createActionContent()}
      expandedContent={ExpandedContent}
      onClick={handleClick}
    >
    </GridCard>
  );

};

export default PlaceCard;

import React, {FunctionComponent, useContext, useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';


import {AuthContext} from '../../contexts';
import useFormValidation, {FormErrors} from '../../helpers/useFormValidation';
import {FacebookIcon, GoogleIcon} from '../../icons';
import {BaseModel} from '../../models';
import {EmailNotVerifiedError} from '../../firebase';
import useStyles from './signInStyles';


class SignInValues extends BaseModel {
  email: string = "";
  password: string = "";
}

function validateSignIn(values: SignInValues) {
  let errors: FormErrors<SignInValues> = {};

  // Email Errors
  if (!values.email) {
    errors.email = "Email required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  // Password Errors
  if (!values.password) {
    errors.password = "Password required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be at least 6 characters";
  }

  return errors;
}

type SubmitEvent = React.FormEvent<HTMLFormElement>;
type ButtonEvent = React.MouseEvent<HTMLButtonElement>;

const INITITIAL_VALUE = new SignInValues();

interface Props {
  onComplete?: () => void
}

const SignIn: FunctionComponent<Props> = props => {

  const {onComplete} = props;
  const history = useHistory();
  const {auth} = useContext(AuthContext);
  const [firebaseError, setFirebaseError] = useState<string|null>(null);

  async function authenticateUser(authFunc: () => Promise<any>) {
    try {
      console.log("authUser");
      await authFunc();
      onComplete && onComplete();
    } catch (e) {
      if (e instanceof EmailNotVerifiedError) {
        history.replace('/resend-verification-email');
      } else {
        console.error("Authentication Error:", e);
        setFirebaseError(e.message);
      }
    }
  }

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    formItem,
    formErrors,
    isValid,
    isSubmitting
  } = useFormValidation<SignInValues>(INITITIAL_VALUE, validateSignIn);

  const classes = useStyles();

  const handleEmailSignIn = (event: SubmitEvent) => {
    if (formItem == null) {
      return;
    }
    const {email, password} = formItem;
    if (auth == null) {
      console.error("AuthContext auth is null");
      throw new Error("Not connected to authentication service");
    }
    handleSubmit(
      event,
      () => authenticateUser(
        () => auth.signInWithEmailAndPassword(email, password)
      )
    );
  };

  const handleGoogleSignIn = async (event: ButtonEvent) => {
    if (auth == null) {
      console.error("AuthContext auth is null");
      throw new Error("Not connected to authentication service");
    }
    try {
      console.log("authUser");
      await auth.signInWithGoogle();
      onComplete && onComplete();
    } catch (e) {
      console.error("Google Authentication Error:", e);
      setFirebaseError(e.message);
    }
  };

  const handleFacebookSignIn = async (event: ButtonEvent) => {
    if (auth == null) {
      console.error("AuthContext auth is null");
      throw new Error("Not connected to authentication service");
    }
    try {
      console.log("authUser");
      await auth.signInWithFacebook();
      onComplete && onComplete();
    } catch (e) {
      console.error("Google Authentication Error:", e);
      setFirebaseError(e.message);
    }
  };

  if (isSubmitting) {
    return <CircularProgress/>
  }

  return (
    <div>

      <form
        className={classes.form}
        onSubmit={handleEmailSignIn}
      >
        <Typography
          className={classes.title}
          variant="h2"
        >
          Sign in
        </Typography>
        <Typography
          align="center"
          className={classes.suggestion}
          color="textSecondary"
          variant="body1"
        >
          Sign in with social media
        </Typography>
        <Grid
          className={classes.socialButtons}
          container
          spacing={2}
        >
          <Grid item>
            <Button
              color="primary"
              onClick={handleFacebookSignIn}
              size="large"
              variant="contained"
            >
              <FacebookIcon className={classes.socialIcon} />
              Login with Facebook
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleGoogleSignIn}
              size="large"
              variant="contained"
            >
              <GoogleIcon className={classes.socialIcon} />
              Login with Google
            </Button>
          </Grid>
        </Grid>
        <Typography
          align="center"
          className={classes.suggestion}
          color="textSecondary"
          variant="body1"
        >
          or login with email address
        </Typography>
        <TextField
          className={classes.textField}
          error={formErrors.email != null}
          fullWidth
          helperText={formErrors.email}
          label="Email address"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.email || ''}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={formErrors.password != null}
          fullWidth
          helperText={formErrors.password}
          label="Password"
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          value={formItem?.password || ''}
          variant="outlined"
        />
        <Button
          className={classes.signInButton}
          color="primary"
          disabled={!isValid}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Sign in now
        </Button>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          Don't have an account?{' '}
          <Link
            component={RouterLink}
            to="/sign-up"
            replace={true}
            variant="h6"
          >
            Sign up
          </Link>
        </Typography>
        <Typography
          color="error"
          variant="body1"
        >
          {firebaseError || ""}
        </Typography>
      </form>
    </div>
  );
};

export default SignIn;

import React, {useState} from 'react';

import CardGrid from '../common/CardGrid';
import {PlaceFirestoreService} from '../../firebase';
import {Place} from '../../models';

import PlaceCard from './PlaceCard';
import PlaceEditDialog from './PlaceEditDialog';


interface Props {
  onEdit?: (item: Place|null) => void,
  onSelect?: (item: Place|null) => void,
}

const PlacesCardGrid = (props: Props) => {
  console.log("Refreshing PlacesCardGrid");

  const {onEdit, onSelect} = props;

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const handleCloseDialog = (value: string) => {
    setOpenAddDialog(false);
  };

  return (
    <CardGrid
        card={<PlaceCard/>}
        editDialog={<PlaceEditDialog open={openAddDialog} onClose={handleCloseDialog}/>}
        firestoreService={PlaceFirestoreService}
        onEdit={onEdit}
        onSelect={onSelect}
        openDialog={() => setOpenAddDialog(true)}
    />
  );
};

export default PlacesCardGrid;

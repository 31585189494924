import {BaseModel} from './Base';


export class Item extends BaseModel {

  constructor(id?: string, name?: string) {
    super();
    this.id = id;
    this.name = name;
  }

  id?: string;
  name?: string;
}

import React, {Fragment, FunctionComponent} from 'react';
import {Typography} from '@material-ui/core';

import LabeledLinks from '../common/LabeledLinks';
import LabeledText from '../common/LabeledText';
import {MaltFirestoreService} from '../../firebase';
import {Malt} from '../../models';
import ItemView, {useItemViewStyles} from '../common/ItemView';


interface Props {
  malt?: Malt | null,
  id?: string|null,
}

const MaltView: FunctionComponent<Props> = props => {

  const {id, malt} = props;

  const classes = useItemViewStyles();

  const renderView = (item: Malt|null) => {
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {item?.name}
        </Typography>
        <Typography
          className={classes.body}
          variant="body1"
        >
          {item?.description}
        </Typography>
        <LabeledText
          label="Picklist Name"
          text={item?.picklistName}
        />
        <LabeledText
          label="Aliases"
          text={item?.aliases && item?.aliases.join(", ")}
        />
        <LabeledText
          label="Created by:"
          text={item?.creator}
        />
        <LabeledLinks label="Links" links={item?.links}/>
      </Fragment>
    );
  };

  return (
    <ItemView
      item={malt}
      id={id}
      service={MaltFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default MaltView;

import {FirestoreService} from './FirestoreService';
import {PlaceBeer, PicklistItem} from '../models';


export class PlaceBeerFirestoreService extends FirestoreService<PlaceBeer> {

  constructor(kwArgs: {
    beer?: PicklistItem|null,
    place?: PicklistItem|null,
    status?: string,
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'placeBeers',
      objectCreator: PlaceBeer,
      equals: {
        ...(kwArgs.beer?.id != null && {'beer.id': kwArgs.beer?.id}),
        ...(kwArgs.place?.id != null && {'place.id': kwArgs.place?.id}),
        //...(kwArgs.status != null && {'status': kwArgs.status}),
      },
      startsWith: {
        ...(kwArgs.status != null && {'status': kwArgs.status}),
      },
      orderBy: kwArgs.orderBy
    });
    console.log("PlaceBeerService construct");
  }
}

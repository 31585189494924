import React, {useEffect, useState} from 'react';
import {IconButton, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Add} from '@material-ui/icons';

import {Address} from '../../models';
import AddressEdit from './AddressEdit';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      marginTop: theme.spacing(2)
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  }),
);

interface Props {
  addresses?: Address[];
  classes?: {[key: string]: string}
  onChange?: (addresses: Address[]) => void;
}

const AddressesEdit = (props: Props) => {
  const {addresses, classes: parentClasses, onChange} = props;

  const componentClasses = useStyles();
  const classes = {...componentClasses, ...parentClasses};

  const [_addresses, setAddresses] = useState<Address[]>([]);
  useEffect(() => {
    if (addresses != null) {
      setAddresses(addresses);
    }
  }, [addresses]);

  const handleChange = (value: Address, index: number) => {
    _addresses[index] = value;
    onChange && onChange(_addresses);
  };

  const handleAddClick = () => {
    _addresses.push(new Address());
    onChange && onChange(_addresses);
  };

  return (
    <div>
      <Typography
        className={classes.body}
        variant="body1"
      >
        Addresses
      </Typography>
      {_addresses.map((address, i) =>
        <span key={i}>
          <AddressEdit
              address={address}
              classes={classes}
              onChange={val => handleChange(val, i)}
          />
        </span>
      )}
      <IconButton
          color="secondary"
          className={classes.button}
          aria-label="add"
          onClick={handleAddClick}
      >
        <Add/>
        <Typography
          className={classes.body}
          variant="body1"
        >
          Address
        </Typography>
      </IconButton>
     </div>
  );
};

export default AddressesEdit;

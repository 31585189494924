import {default as fb} from 'firebase';
import {BaseModel} from './Base';

type DocumentData = fb.firestore.DocumentData;
type DocumentReference = fb.firestore.DocumentReference;
type DocumentSnapshot = fb.firestore.DocumentSnapshot;


export class FirestoreBase extends BaseModel{
  id?: string;
  docRef?: DocumentReference;

  static fromData<T extends typeof FirestoreBase>(this: T, data: DocumentData): InstanceType<T> {
    const base = new this() as InstanceType<T>;
    base.setFromMap(data as Partial<InstanceType<T>>);
    return base;
  }

  static fromDocument<T extends typeof FirestoreBase>(this: T, doc: DocumentSnapshot): InstanceType<T> {
    const base = new this() as InstanceType<T>;
    base.setFromDocument(doc);
    return base;
  }

  // Extract the values for this instance from the provided document
  async setFromReference(docRef: DocumentReference) {
    if (docRef == null) {
      return;
    }
    const doc: DocumentSnapshot = await docRef.get();
    this.setFromDocument(doc);
  }

  // Extract the values for this instance from the provided document
  setFromDocument(doc: DocumentSnapshot) {
    if (doc == null || doc.data == null) {
      return;
    }
    const data = doc.data();
    this.setFromMap(data as Partial<this>);
    this.id = doc.id;
    this.docRef = doc.ref;
  }
}
import React, {FunctionComponent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {LocalDrink} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    background: theme.palette.white,
  }
}));

interface Props {
  className?: string,
}

const TopBar: FunctionComponent<Props> = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <span>
          <LocalDrink/>
          <Typography>
            BevDb
          </Typography>
          </span>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
import React, {ChangeEvent, Fragment, useEffect, useState} from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

import {Brewery, BreweryCore} from '../../models';
import {BreweryFirestoreService} from '../../firebase';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';
import {InputAdornment, TextField} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex'
  },
  text: {

  },
  button: {
    alignSelf: 'center'
  }
}));


interface SelectProps {
  brewery?: BreweryCore|null,
  onSelect?: (item: Brewery|null) => void,
}

const BrewerySelectList = (props: SelectProps) => {
  console.log("Refreshing BrewerySelect");

  const {brewery, onSelect} = props;

  const [searchString, setSearchString] = useState('');

  const {firestoreItems} = useStreamFromFirebaseService<Brewery, BreweryFirestoreService>(
      BreweryFirestoreService,
      {query: searchString}
      );

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setSearchString(event.target.value);
  }

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  useEffect(() => {
    if (brewery != null && firestoreItems != null) {
      const selectedBrewery = firestoreItems.find((item) => item.id === brewery.id);
      const index = selectedBrewery && firestoreItems.indexOf(selectedBrewery);
      if (index != null && index > 0) {
        setSelectedIndex(index);
      }
    }
  }, [brewery, firestoreItems]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    onSelect && onSelect(firestoreItems[index]);
  };

  return (
    <Fragment>
      <TextField
        helperText="Filter Breweries"
        value={searchString}
        onChange={handleSearchChange}
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
                <Search />
            </InputAdornment>
          )
        }}
      />
      <List  component="nav">
        {firestoreItems.map((brewery, i) =>
          <ListItem
            button
            key={i}
            selected={selectedIndex === i}
            onClick={event => handleListItemClick(event, i)}
          >
            <ListItemText
              primary={brewery.name}
              secondary={brewery.cityState}
            />
          </ListItem>
        )}
      </List>
    </Fragment>
  );
};

interface DialogProps {
  brewery: BreweryCore|null,
  onClose: (value: BreweryCore|null) => void,
  open: boolean,
}

const BrewerySelectDialog = (props: DialogProps) => {

  const {brewery, onClose, open} = props;

  const [_brewery, setBrewery] = useState<BreweryCore|null>(brewery);

  const handleClose = () => {
    onClose(_brewery);
  };

  const handleSelect = (brewery: BreweryCore|null) => {
    setBrewery(brewery);
    onClose(brewery);
  };

  return (
    <div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="brewery-select-dialog-title"
      >
        <DialogTitle id="brewery-select-dialog-title">Select Brewery</DialogTitle>
        <DialogContent>
          <BrewerySelectList
              brewery={_brewery}
              onSelect={handleSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
              onClick={handleClose}
              color="primary"
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


interface Props {
  brewery?: BreweryCore|null,
  onChange?: (item: BreweryCore|null) => void,
}

const BrewerySelect = (props: Props) => {
  console.log("Refreshing BrewerySelect");

  const {brewery, onChange} = props;

  const classes = useStyles();

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const handleCloseDialog = (value: BreweryCore|null) => {
    setOpenAddDialog(false);
    onChange && onChange(value);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        {brewery?.name || "No Brewery Selected"}
      </Typography>
      <Button
          className={classes.button}
          size="small"
          color="primary"
          onClick={() => setOpenAddDialog(true)}
      >
        Select
      </Button>
      <BrewerySelectDialog
          brewery={brewery || null}
          onClose={handleCloseDialog}
          open={openAddDialog}
      />
    </div>
  );
};

export default BrewerySelect;

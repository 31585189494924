import React, {FunctionComponent, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Typography
} from '@material-ui/core';

import {AuthContext} from '../../contexts';
import useStyles from './signInStyles';


const ResendVerificationEmail: FunctionComponent = () => {

  const history = useHistory();
  const {auth} = useContext(AuthContext);

  const fbUser = auth?.fbUser;

  const classes = useStyles();

  const handleSendEmail = async () => {
    if (auth == null) {
      console.error("AuthContext auth is null");
      throw new Error("Not connected to authentication service");
    }
    fbUser && await auth.sendVerificationEmail(fbUser);
    history.replace('/home');
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSendEmail}
    >
      <Typography
        className={classes.title}
        variant="h2"
      >
        Resend Verification Email
      </Typography>
      <Typography
        align="center"
        className={classes.suggestion}
        color="textSecondary"
        variant="body1"
      >
        Press the button to have a new email sent. Check spam or junk folders if not received shortly.
      </Typography>
      <Button
        className={classes.signInButton}
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Resend Email
      </Button>
    </form>
  );

};

export default ResendVerificationEmail;

import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography,
} from '@material-ui/core';
import {createUniqueId} from '../../helpers/createUniqueId';
import {Brewery} from '../../models';
import BreweryEdit from './BreweryEdit';
import PlaceEdit from '../Place/PlaceEdit';


interface Props {
  brewery?: Brewery | null,
  onClose?: (value: string) => void,
  open?: boolean,
}


const BreweryEditDialog = ({open=false, ...props}: Props) => {

  const {brewery, onClose} = props;

  const [formId, setFormId] = useState<string>("breweryForm");
  useEffect(() => {
    setFormId(createUniqueId());
  }, []);

  const [error, setError] = useState<string|null>(null);
  const handleError = (message: string) => {
    setError(message);
  };

  const handleClose = () => {
    onClose && onClose('Closed');
  };

  return (
    <div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="brewery-edit-dialog-title"
      >
        <DialogTitle id="brewery-edit-dialog-title">
          Edit Brewery
        </DialogTitle>
        <DialogContent>
          <BreweryEdit
            brewery={brewery}
            formId={formId}
            onComplete={handleClose}
            onError={handleError}
            hideButtons
            hideError
          />
        </DialogContent>
        <DialogActions>
          {error &&
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          }
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
              color="primary"
              type="submit"
              form={formId}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BreweryEditDialog;

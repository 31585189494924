import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  ReactNode,
  cloneElement,
  useContext,
  useState,
} from 'react';
import {
  Button,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {FirestoreBase} from '../../models';
import {FirestoreService} from '../../firebase';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';
import {AuthContext} from '../../contexts';

// TODO: Implement responsive GridList
//  https://github.com/mui-org/material-ui/issues/17000


const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 10,
  },
  gridList: {
  },
  gridListTile: {
  }
}));

interface CardProps<S extends FirestoreBase> {
  item?: S | null,
  editable?: boolean,
}

interface EditDialogProps<S extends FirestoreBase> {
  item?: S | null,
  open: boolean,
  onClose: (value: string) => void,
}


interface Props<S extends FirestoreBase, T extends FirestoreService<S>> {
  card: ReactNode,
  editDialog: ReactNode,
  firestoreService: new () => T;
  onEdit?: (item: S|null) => void,
  onSelect?: (item: S|null) => void,
  openDialog?: () => void,
  useSearch?: boolean,
}

function CardGrid<S extends FirestoreBase,T extends FirestoreService<S>>(
    {useSearch=true,
      ...props}:Props<S,T>
) {
  console.log("Refreshing CardGrid");

  const {card, editDialog, openDialog, firestoreService} = props;

  const classes = useStyles();

  const [searchString, setSearchString] = useState('');

  const {firestoreItems} = useStreamFromFirebaseService<S,T>(
      firestoreService,
      {query: searchString, limit: 25}
  );

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  const GridCard =  (props: {item: S}) => cloneElement(
      card as ReactElement<any>,
      {item: props.item, editable: canContribute});

  /*
  // cloneElement seems to disable the form submit execution so this code
  // moved to each component referencing this component
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const EditDialog =  () => cloneElement(
      editDialog as ReactElement<any>,
      {open: openAddDialog, onClose: handleCloseDialog});

  const handleCloseDialog = (value: string) => {
    setOpenAddDialog(false);
  };
  */

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setSearchString(event.target.value);
  }

  return (
    <div className={classes.root}>
      <TextField
        helperText="Search"
        value={searchString}
        onChange={handleSearchChange}
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
                <Search />
            </InputAdornment>
          )
        }}
      />
      {canContribute &&
        <Fragment>
          <Button
            size="small"
            color="primary"
            onClick={openDialog} //{() => setOpenAddDialog(true)}
          >
            Add
          </Button>
          {editDialog}
        </Fragment>
      }
       <Grid container className={classes.gridList} spacing={1}>
        {firestoreItems.map(item => (
          <Grid item xs={6} sm={4} md={3} lg={3} xl={2}
              key={item.id}
              className={classes.gridListTile}
          >
            <GridCard item={item}/>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default CardGrid;

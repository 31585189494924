import {FirestoreService} from './FirestoreService';
import {FirestoreListItem} from '../models';

export class ListFirestoreService extends FirestoreService<FirestoreListItem> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'lists',
      objectCreator: FirestoreListItem,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log("ListService construct");
  }
}

import {FirestoreService} from './FirestoreService';
import {BeerType} from '../models';


export class BeerTypeFirestoreService extends FirestoreService<BeerType> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'beerTypes',
      objectCreator: BeerType,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log('BeerTypeService construct');
  }
}

import React, {ChangeEvent, Fragment, useState} from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {Place} from '../../models';
import {PlaceFirestoreService} from '../../firebase';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';
import {InputAdornment, TextField} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex'
  },
  text: {

  },
  button: {
    alignSelf: 'center'
  }
}));


type MinPlace = Pick<Place, 'id' | 'name'> & Partial<Place>;

interface SelectProps {
  place?: MinPlace|null,
  onSelect?: (item: Place|null) => void,
}

const PlaceSelectList = (props: SelectProps) => {
  console.log("Refreshing PlaceSelect");

  const {place, onSelect} = props;

  const classes = useStyles();

  const [searchString, setSearchString] = useState('');

  const {firestoreItems} = useStreamFromFirebaseService<Place, PlaceFirestoreService>(
      PlaceFirestoreService,
      {query: searchString}
      );

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setSearchString(event.target.value);
  }

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    onSelect && onSelect(firestoreItems[index]);
  };

  return (
    <Fragment>
      <TextField
        helperText="Filter Places"
        value={searchString}
        onChange={handleSearchChange}
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
                <Search />
            </InputAdornment>
          )
        }}
      />
      <List  component="nav">
        {firestoreItems.map((place, i) =>
          <ListItem
            button
            key={i}
            selected={selectedIndex === i}
            onClick={event => handleListItemClick(event, i)}
          >
            <ListItemText
              primary={place.name}
              secondary={place.cityState}
            />
          </ListItem>
        )}
      </List>
    </Fragment>
  );
};

interface DialogProps {
  place: MinPlace|null,
  onClose: (value: MinPlace|null) => void,
  open: boolean,
}

const PlaceSelectDialog = (props: DialogProps) => {

  const {place, onClose, open} = props;

  const [_place, setPlace] = useState<MinPlace|null>(place);

  const handleClose = () => {
    onClose(_place);
  };

  return (
    <div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="place-select-dialog-title"
      >
        <DialogTitle id="place-select-dialog-title">Select Place</DialogTitle>
        <DialogContent>
          <PlaceSelectList
              place={_place}
              onSelect={setPlace}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
              onClick={handleClose}
              color="primary"
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


interface Props {
  place?: MinPlace|null,
  onChange?: (item: MinPlace|null) => void,
}

const PlaceSelect = (props: Props) => {
  console.log("Refreshing PlaceSelect");

  const {place, onChange} = props;

  const classes = useStyles();

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const handleCloseDialog = (value: MinPlace|null) => {
    setOpenAddDialog(false);
    onChange && onChange(value);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        {place?.name || "No Place Selected"}
      </Typography>
      <Button
          className={classes.button}
          size="small"
          color="primary"
          onClick={() => setOpenAddDialog(true)}
      >
        Select
      </Button>
      <PlaceSelectDialog
          place={place || null}
          onClose={handleCloseDialog}
          open={openAddDialog}
      />
    </div>
  );
};

export default PlaceSelect;

import {useState, useEffect, useContext} from "react";

import {AuthContext} from '../contexts';
import {FirestoreBase} from '../models';
import {FirestoreService} from '../firebase';
import {difference} from './objectDifference';


export default function useFirestoreService<
    S extends FirestoreBase, T extends FirestoreService<S>>(
    service: {new (kwArgs: {[key: string]: any}): T},
    serviceArgs?: {[key: string]: any}
) {

  const [db, setDb] = useState<T|null>(null);
  const [firestoreError, setFirestoreError] = useState<string|null>(null);

  const {currentUser} = useContext(AuthContext);
  const typeName = (db && db.path) || "";

  useEffect(() => {
    setDb(new service(serviceArgs != null ? serviceArgs : {}));
  }, []);

  function checkDb() {
    if (db == null) {
      setFirestoreError("Not connected to the database");
      console.error("Not connected to the database");
      return false;
    }
    return true
  }

  const addItemToFirestore = async (values: {[key: string]: any}) => {
    if (!checkDb()) {
      return;
    }
    try {
      console.log(`Saving new ${typeName} item`);
      await db!.createObject(values, currentUser)
    } catch (e) {
      console.error(`Error saving new ${typeName}:`, e);
      setFirestoreError(`Error saving new ${typeName}: ${e.message}`);
    }
  };

  const updateItemInFirestore = async (
      newItem: S,
      oldItem?: S|null
      ) => {
    console.log(`Updating ${typeName}`);
    if (!checkDb()) {
      return;
    }

    console.log("oldValues", oldItem);
    console.log("newValues", newItem);
    let values;
    if (oldItem == null) {
      values = newItem.toMap();
    } else {
      values = difference(newItem.toMap(), oldItem.toMap());
      values.id = newItem.id;
      values.docRef = newItem.docRef;
    }
    console.log("Update values", values);

    try {
      console.log(`Saving updated ${typeName} item`);
      await db!.updateObject(values, currentUser)
    } catch (e) {
      console.error(`Error updating ${typeName}:`, e);
      setFirestoreError(`Error updating ${typeName}: ${e.message}`);
    }
  };

  const deleteItemInFirestore = async (values: {[key:string]: any}) => {
    if (!checkDb()) {
      return;
    }
    try {
      console.log(`Deleting ${typeName} item`);
      await db!.deleteObject(values, currentUser)
    } catch (e) {
      console.error(`Error deleting ${typeName}:`, e);
      setFirestoreError(`Error deleting ${typeName}: ${e.message}`);
    }
  };

  return {
    db,
    addItemToFirestore,
    updateItemInFirestore,
    deleteItemInFirestore,
    firestoreError
  };
}

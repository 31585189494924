import React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import cslx from 'clsx';

import {Address} from '../../models';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  label: {
  },
  text: {
    marginLeft: 20,
  }
}));

interface Props {
  address?: Address,
  className?: {body: string},
  label?: string;
  labeled?: boolean,
}

const AddressView = (
    {labeled=false,
     label="Address",
      ...props
    }: Props
) => {

  const {address, className} = props;

  const classes = useStyles();

  if (address == null) {
    return null;
  }

  const textStyle = cslx(className?.body, classes.text);

  const createStreet = () => {
    if (address.street) {
      return (
        <Typography
            className={textStyle}
            variant="body1"
        >
          {address.street}
        </Typography>
      );
    }
  };

  const createStreet2 = () => {
    if (address.street2) {
      return (
        <Typography
            className={textStyle}
            variant="body1"
        >
          {address.street2}
        </Typography>
      );
    }
  };

  const createCityStateZip = () => {
    if (address.cityStateZip) {
      return (
        <Typography
            className={textStyle}
            variant="body1"
        >
          {address.cityStateZip}
        </Typography>
      );
    }
  };

  const createCountry = () => {
    if (address.street2) {
      return (
        <Typography
            className={textStyle}
            variant="body1"
        >
          {address.country}
        </Typography>
      );
    }
  };

  const createAddressBlock = (className?: string) => (
    <div className={className}>
      {createStreet()}
      {createStreet2()}
      {createCityStateZip()}
      {createCountry()}
    </div>
  );

  if (labeled) {
    return (
      <div className={classes.root}>
        <Typography
            className={classes.label}
            variant="h6"
            component="h6"
            display="inline"
        >
          {label}
        </Typography>
        {createAddressBlock()}
      </div>
    );
  } else {
    return (
      <div>
        {createAddressBlock(classes.text)}
      </div>
    );
  }
};

export default AddressView
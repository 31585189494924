import React, {FunctionComponent, useContext, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Toolbar,
  Badge,
  Button,
  Hidden,
  IconButton,
  Typography
} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {LocalDrink, Menu, Notifications} from '@material-ui/icons';

import {AuthContext} from '../../contexts';
import Profile from './Profile';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    background: theme.palette.white,
  },
  flexGrow: {
    flexGrow: 1
  },
  signInButton: {
    marginLeft: theme.spacing(1)
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

interface Props extends RouteComponentProps {
  className?: String,
  onSideBarOpen?: Function,
}

const TopBar: FunctionComponent<Props> = props => {
  const {className, history, onSideBarOpen} = props;

  const classes = useStyles();

  const {auth} = useContext(AuthContext);

  const [notifications] = useState([]);

  function renderAuthenticationButton() {
    if (auth && auth.currentUser) {
      return (
        <Profile />
      )
    } else {
      return (
        <Button
          className={classes.signInButton}
          //color="inherit"
          onClick={() => history.push("/sign-in")}
          variant="outlined"
        >
          Sign In
        </Button>
      )
    }
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
    >
      <Toolbar>

        <Hidden lgUp>
          <IconButton
            onClick={() => onSideBarOpen && onSideBarOpen()}
          >
            <Menu />
          </IconButton>
        </Hidden>

        <RouterLink to="/">
          <span>
            <LocalDrink/>
            <Typography>
              BevDb
            </Typography>
          </span>
        </RouterLink>

        <div className={classes.flexGrow} />

        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <Notifications />
            </Badge>
          </IconButton>
          {renderAuthenticationButton()}
          </Hidden>

      </Toolbar>
    </AppBar>
  );
};

export default withRouter(TopBar);
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  label: {
  },
  text: {
    marginLeft: 20,
  }
}));


interface Props {
  label?: string,
  url?: string,
}


const LabeledURL = (props: Props) => {

  const {label, url} = props;

  const classes = useStyles();

  const [_url, setURL] = useState<string>(url || "");

  useEffect(() => {
    if (url && !url.startsWith("http")) {
      setURL("//"+url);
    }
  }, [url]);

  return (
    <div>
      <Typography
          className={classes.label}
          variant="h6"
          display="inline"
      >
        {label}
      </Typography>
      <Typography
          className={classes.text}
          variant="body1"
          display="inline"
      >
        <Link
            to={_url}
            target="newTab"
        >
          {url}
        </Link>
      </Typography>
    </div>
  )
};

export default LabeledURL;

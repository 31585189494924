import React, {FunctionComponent} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(3)
  },
  body: {
    marginTop: theme.spacing(2)
  },
  error: {
    color: theme.palette.error.main
  }
}));


interface Props {
}

const ComingSoon: FunctionComponent<Props> = props => {

  const classes = useStyles();

  return (
    <div>
      <Typography  className={classes.title} variant="h2">
        Coming Soon
      </Typography>
      <Typography  className={classes.body} variant="body1">
        This feature is currently under development.
      </Typography>
    </div>
  );
};

export default ComingSoon;

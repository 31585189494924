import {useState, useEffect} from 'react';
import {FirestoreBase} from '../models';
import {FirestoreService} from '../firebase';
import useFirestoreService from './useFirestoreService';


interface Options {
  query?: string,
  limit?: number,
  kwArgs?: {[key: string]: any}
}

export default function useStreamFromFirestoreService<S extends FirestoreBase, T extends FirestoreService<S>>(
    service: new () => T,
    options: Options = {}
) {
  const [firestoreItems, setFirestoreItems] = useState<S[]>([]);
  const [streamError, setStreamError] = useState<string|null>(null);

  const {
    db,
    addItemToFirestore,
    updateItemInFirestore,
    deleteItemInFirestore,
    firestoreError
  } = useFirestoreService<S,T>(service, options.kwArgs);

  if (firestoreError != null) {
    setStreamError(firestoreError);
  }

  useEffect(() => {
    if (db == null) {
      return;
    }
    try {
      const unsubscribe = db!.updateStream({
        filter: options.query, limit: options.limit, setter: setFirestoreItems});
      return unsubscribe ? () => unsubscribe() : () => {};
    } catch (e) {
      console.error(`Error retrieving steam ${typeof firestoreItems}:`, e);
      setStreamError(`Error retrieving stream ${typeof firestoreItems}: ${e.message}`);
    }
  }, [db, options.query]);

  return {
    db,
    firestoreItems,
    addItemToFirestore,
    updateItemInFirestore,
    deleteItemInFirestore,
    streamError
  };
}

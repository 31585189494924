import {FirestoreService} from './FirestoreService';
import {Beer, BreweryCore, Brewery} from '../models';

export class BeerFirestoreService extends FirestoreService<Beer> {

  constructor(kwArgs: {
    brewery?: BreweryCore|Brewery|null,
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'beers',
      objectCreator: Beer,
      equals: kwArgs.brewery != null ? {'brewery.id': kwArgs.brewery?.id} : kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log("BeerService construct");
  }
}

import React, {Fragment, FunctionComponent} from 'react';
import {Typography} from '@material-ui/core';

import LabeledText from '../common/LabeledText';
import {BeerTypeFirestoreService} from '../../firebase';
import {BeerType} from '../../models';
import LabeledLinks from '../common/LabeledLinks';
import ItemView, {useItemViewStyles} from '../common/ItemView';


interface Props {
  beerType?: BeerType | null,
  id?: string|null,
}

const BeerTypeView: FunctionComponent<Props> = props => {

  const {beerType, id} = props;

  const classes = useItemViewStyles();

  const renderView = (item: BeerType|null) => {
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {item?.name}
        </Typography>
        <Typography
          className={classes.body}
          variant="body1"
        >
          {item?.description}
        </Typography>
        <LabeledText
          label="Picklist Name"
          text={item?.picklistName}
        />
        <LabeledText
          label="Brewers Association"
          text={item?.brewersAssociation}
        />
        <LabeledLinks
          label="Links"
          links={item?.links}
        />
      </Fragment>
    );
  };

  return (
    <ItemView
      item={beerType}
      id={id}
      service={BeerTypeFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default BeerTypeView;

import {FirestoreService} from './FirestoreService';
import {Malt} from '../models';


export class MaltFirestoreService extends FirestoreService<Malt> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'malts',
      objectCreator: Malt,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log('MaltService construct');
  }
}

import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {
  CircularProgress,
  Typography
} from '@material-ui/core';

import {FirestoreService} from '../../firebase';
import useItemFromFirestoreService from '../../helpers/useItemFromFirestoreService';
import {FirestoreBase} from '../../models';


export const useItemViewStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 10,
  },
  title: {
    marginTop: theme.spacing(3),
  },
  body: {
    marginTop: theme.spacing(2),
  },
  leftCentered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //alignItems: "center",
    //position: "absolute",
    //top: "50%",
    //transform: "translateY(-50%)",
  },
  image: {
    height: 400,
    maxWidth: 400,
    objectFit: "contain",
    display: "block",
  },
  info: {
    maxWidth: "50%",
  },
  error: {
    color: theme.palette.error.main
  }
}));


interface Props<S,T> {
  item?: S | null,
  id?: string|null,
  service: new () => T;
  viewRenderer: (item: S|null) => ReactNode;
}

function ItemView<S extends FirestoreBase,T extends FirestoreService<S>>(props: Props<S,T>) {

  const {item, id, service, viewRenderer} = props;
  console.log("ItemView");

  const classes = useItemViewStyles();

  const {id: paramId} = useParams();


  const [resolvedItem, setItem] = useState<S|null>(null);

  const [resolvedId, setId] = useState<string|null>(null);
  useEffect(() => {
    if (id != null) {
      setId(id);
    } else if (paramId != null) {
      setId(paramId);
    } else {
      setId(null);
    }
  }, [id, paramId]);

  const {firestoreItem, isRetrieving, firestoreItemError} =
      useItemFromFirestoreService<S,T>(resolvedId, service);

  useEffect(() => {
    if (firestoreItem != null) {
      setItem(firestoreItem);
    } else if (item != null) {
      setItem(item);
    }
  }, [item, firestoreItem]);

  if (resolvedItem == null) {
    if (id != null) {
      return (
        <Typography
          className={classes.title}
          variant="h2"
        >
          Not found
        </Typography>
      );
    } else {
      return null;
    }
  }

  if (isRetrieving) {
    return <CircularProgress/>
  }

  if (firestoreItemError) {
    return (
        <Typography
          className={classes.error}
          color="error"
          variant="body1"
        >
          {firestoreItemError}
        </Typography>
    );
  }

  return (
    <Fragment>
      {viewRenderer(resolvedItem)}
    </Fragment>
  );
}

export default ItemView;

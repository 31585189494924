import MuiCard from './MuiCard';
/*
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
*/

/*
  To create MUI Component overrides, check the src for the component in the
  material-ui git repo to determine which properties can be overridden
  https://github.com/mui-org/material-ui/tree/master/packages/material-ui/src
 */

export default {
  MuiCard,
  //MuiIconButton,
  //MuiPaper,
  //MuiTableCell,
  //MuiTableHead,
  //MuiTypography
};

import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {Address} from '../../models';


const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginTop: theme.spacing(2)
  },
  errorText: {
    color: theme.palette.error.main
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


type Errors = {
  [K in keyof Address]?: string;
};

interface Props {
  address?: Address,
  classes?: {[key: string]: string}
  onChange?: (v: Address) => void
}

const AddressEdit = (props: Props) => {

  const {address, onChange, classes: parentClasses} = props;

  const componentClasses = useStyles();
  const classes = {...componentClasses, ...parentClasses};

  const errors = {} as Errors;

  const [_address, setAddress] = useState<Address|null>(null);

  useEffect(() => {
    if (address != null) {
      if (address.country == null) {
        setAddress({...address, country: "United States"} as Address);
      } else {
        setAddress(address);
      }
    } else {
      setAddress(Address.fromMap({country: "United States"}));
    }
  }, [address]);

  const updateProperty = (name: string, value: any) => {
    const newAddress = _address == null ? new Address() :_address.clone();
    newAddress[name] = value;
    setAddress(newAddress);
    onChange && onChange(newAddress);
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target != null) {
      updateProperty(event.target.name, event.target.value);
    }
  };

  const handleSelectChange = (event: ChangeEvent<{name?: string|undefined, value: unknown }>) => {
    if (event != null && event.target.name) {
      updateProperty(event.target.name, event.target.value);
    }
  };

  return (
    <div>
      <TextField
        autoComplete="off"
        className={classes.textField}
        //error={errors.street != null}
        fullWidth
        //helperText={errors.street}
        label="Address"
        name="street"
        onChange={handleTextFieldChange}
        //onBlur={handleBlur}
        type="text"
        value={_address?.street || ""}
        variant="outlined"
      />
      <TextField
        autoComplete="off"
        className={classes.textField}
        //error={errors.street2 != null}
        fullWidth
        //helperText={errors.street2}
        label="Address 2"
        name="street2"
        onChange={handleTextFieldChange}
        //onBlur={handleBlur}
        type="text"
        value={_address?.street2 || ''}
        variant="outlined"
      />
      <TextField
        autoComplete="off"
        className={classes.textField}
        //error={errors.city != null}
        fullWidth
        //helperText={errors.city}
        label="City"
        name="city"
        onChange={handleTextFieldChange}
        //onBlur={handleBlur}
        type="text"
        value={_address?.city || ''}
        variant="outlined"
      />
      {_address?.country !== "United States"
        ? <TextField
            autoComplete="off"
            className={classes.textField}
            //error={errors.state != null}
            fullWidth
            //helperText={errors.state}
            label="State"
            name="state"
            onChange={handleTextFieldChange}
            //onBlur={handleBlur}
            type="text"
            value={_address?.state || ''}
            variant="outlined"
          />
        : <FormControl className={classes.formControl}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              name="state"
              onChange={handleSelectChange}
              value={_address?.state || ""}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stateAbbrList.map((sa, i) =>
                <MenuItem value={sa} key={i}>{sa}</MenuItem>)}
            </Select>
            <FormHelperText>{errors?.state}</FormHelperText>
          </FormControl>
      }
      <TextField
        autoComplete="off"
        className={classes.textField}
        //error={errors.zip != null}
        fullWidth
        //helperText={errors.zip}
        label="Zipcode"
        name="zip"
        onChange={handleTextFieldChange}
        //onBlur={handleBlur}
        type="text"
        value={_address?.zip || ''}
        variant="outlined"
      />
      <FormControl className={classes.formControl}>
        <InputLabel id="country-label">Country</InputLabel>
        <Select
          labelId="country-label"
          id="country"
          name="country"
          onChange={handleSelectChange}
          value={_address?.country || "United States"}
        >
          {countryList.map((c, i) => <MenuItem value={c} key={i}>{c}</MenuItem>)}
        </Select>
        <FormHelperText>{errors?.country}</FormHelperText>
      </FormControl>

    </div>
  );

};

export default AddressEdit;

const stateAbbrList = [
  '', 'AK','AL','AR','AZ','CA','CO','CT','DC','DE','FL','GA','GU','HI','IA',
  'ID', 'IL','IN','KS','KY','LA','MA','MD','ME','MH','MI','MN','MO','MS','MT',
  'NC','ND','NE','NH','NJ','NM','NV','NY', 'OH','OK','OR','PA','PR','PW','RI',
  'SC','SD','TN','TX','UT','VA','VI','VT','WA','WI','WV','WY'
];

const countryList = [
  'Afghanistan','Albania','Algeria','Andorra','Angola','Anguilla',
  'Antigua & Barbuda','Argentina','Armenia','Aruba','Australia','Austria',
  'Azerbaijan','Bahamas','Bahrain','Bangladesh','Barbados','Belarus','Belgium',
  'Belize','Benin','Bermuda','Bhutan','Bolivia','Bosnia &amp; Herzegovina',
  'Botswana','Brazil','British Virgin Islands','Brunei','Bulgaria',
  'Burkina Faso','Burundi','Cambodia','Cameroon','Canada','Cape Verde',
  'Cayman Islands',
  'Chad','Chile','China','Colombia','Congo','Cook Islands','Costa Rica',
  'Cote D Ivoire','Croatia','Cruise Ship','Cuba','Cyprus','Czech Republic',
  'Denmark','Djibouti','Dominica','Dominican Republic','Ecuador','Egypt',
  'El Salvador','Equatorial Guinea','Estonia','Ethiopia','Falkland Islands',
  'Faroe Islands','Fiji','Finland','France','French Polynesia',
  'French West Indies','Gabon','Gambia','Georgia','Germany','Ghana','Gibraltar',
  'Greece','Greenland','Grenada','Guam','Guatemala','Guernsey','Guinea',
  'Guinea Bissau','Guyana','Haiti','Honduras','Hong Kong','Hungary','Iceland',
  'India','Indonesia','Iran','Iraq','Ireland','Isle of Man','Israel','Italy',
  'Jamaica','Japan','Jersey','Jordan','Kazakhstan','Kenya','Kuwait',
  'Kyrgyz Republic','Laos','Latvia','Lebanon','Lesotho','Liberia','Libya',
  'Liechtenstein','Lithuania','Luxembourg','Macau','Macedonia','Madagascar',
  'Malawi','Malaysia','Maldives','Mali','Malta','Mauritania','Mauritius',
  'Mexico','Moldova','Monaco','Mongolia','Montenegro','Montserrat','Morocco',
  'Mozambique','Namibia','Nepal','Netherlands','Netherlands Antilles',
  'New Caledonia','New Zealand','Nicaragua','Niger','Nigeria','Norway','Oman',
  'Pakistan','Palestine','Panama','Papua New Guinea','Paraguay','Peru',
  'Philippines','Poland','Portugal','Puerto Rico','Qatar','Reunion','Romania',
  'Russia','Rwanda','Saint Pierre &amp; Miquelon','Samoa','San Marino',
  'Satellite','Saudi Arabia','Senegal','Serbia','Seychelles','Sierra Leone',
  'Singapore','Slovakia','Slovenia','South Africa','South Korea','Spain',
  'Sri Lanka','St Kitts &amp; Nevis','St Lucia','St Vincent','St. Lucia',
  'Sudan','Suriname','Swaziland','Sweden','Switzerland','Syria','Taiwan',
  'Tajikistan','Tanzania','Thailand','Timor L\'Este','Togo','Tonga',
  'Trinidad & Tobago','Tunisia','Turkey','Turkmenistan','Turks & Caicos',
  'Uganda','Ukraine','United Arab Emirates','United Kingdom','United States',
  'Uruguay','Uzbekistan','Venezuela','Vietnam','Virgin Islands (US)','Yemen',
  'Zambia','Zimbabwe'
];

import React, {Fragment, FunctionComponent, useContext, useState} from 'react';
import {useHistory} from 'react-router';
import {Button, Typography} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';

import {BeerEditDialog, BeersList} from '../Beer';
import AddressView from '../common/AddressView';
import GridCard from '../common/GridCard';
import LabeledURL from '../common/LabeledURL';
import LabeledLinks from '../common/LabeledLinks';
import {AuthContext} from '../../contexts';
import {Beer, Brewery} from '../../models';

import BreweryEditDialog from './BreweryEditDialog';

const useStyles = makeStyles((theme: Theme) => ({
  beerList: {
    maxHeight: 150,
    overflow: 'auto',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  beerListTitle: {
    paddingTop: 5,
    paddingBottom: 0,
    marginTop: 5,
    marginBottom: 0,
  },
}));


interface Props {
  item?: Brewery | null,
}


const BreweryCard: FunctionComponent<Props> = props => {

  const {item} = props;
  const history = useHistory();
  const classes = useStyles();
  console.log("BreweryCard brewery", item);

  const [openBeerDialog, setOpenBeerDialog] = useState<boolean>(false);
  const [openBreweryDialog, setOpenBreweryDialog] = useState<boolean>(false);

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  if (item == null) {
    return null;
  }

  const handleClickOpenBeerDialog = () => {
    setOpenBeerDialog(true);
  };

  const handleClickOpenBreweryDialog = () => {
    setOpenBreweryDialog(true);
  };

  const handleCloseBeerDialog = () => {
    setOpenBeerDialog(false);
  };

  const handleCloseBreweryDialog = () => {
    setOpenBreweryDialog(false);
  };

  const handleClick = () => {
    history.push(`/brewery/${item.id}`);
  };

  const handleBeerClick = (beer: Beer|null) => {
    if (beer != null) {
      history.push(`/beer/${beer.id}`);
    }
  };

  const logoURL = item?.logoURLs && item.logoURLs['400pxURL'];

  const CardContent = (
    <Fragment>
      <Typography gutterBottom variant="h5" component="h2">
        {item.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.cityState}
      </Typography>
    </Fragment>
  );

  const createActionContent = () => {
    if (canContribute) {
      return (
        <Fragment>
          <Button size="small" color="primary"
                  onClick={handleClickOpenBreweryDialog}>
            Edit
          </Button>
          <BreweryEditDialog
              brewery={item}
              open={openBreweryDialog}
              onClose={handleCloseBreweryDialog}
          />
        </Fragment>
      );
    }
  };

  const ExpandedContent = (
    <Fragment>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.description}
      </Typography>

      {item.addresses && item.addresses.map((address, i) =>
         <AddressView address={address} key={i} labeled/>
      )}
      <LabeledURL
        label="Website"
        url={item.website}
      />
      <LabeledLinks
        label="Links"
        links={item.links}
      />

      <div className={classes.beerList}>
        <Typography gutterBottom variant="h5" component="h2" className={classes.beerListTitle}>
          Beers
        </Typography>
        <BeersList brewery={item} onSelect={beer => handleBeerClick(beer)}/>
      </div>

      {canContribute &&
        <Fragment>
          <Button size="small" color="primary"
                  onClick={handleClickOpenBeerDialog}>
              Add Beer
          </Button>
          <BeerEditDialog
            brewery={item}
            open={openBeerDialog}
            onClose={handleCloseBeerDialog}
          />
        </Fragment>
      }

    </Fragment>
  );

  return (
    <GridCard
      title={item.name}
      logoURL={logoURL}
      cardContent={CardContent}
      actionContent={createActionContent()}
      expandedContent={ExpandedContent}
      onClick={handleClick}
    >
    </GridCard>
  );
};

export default BreweryCard;

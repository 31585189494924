import {default as fb} from 'firebase';
import {FirestoreBase} from './FirestoreBase';
import {Address} from './Address';
import {encodeGeoPoint} from '../helpers/encodeGeoPoint';
import {Item} from './Item';
import {BaseModel} from './Base';

type GeoPoint = fb.firestore.GeoPoint;


export class GeoFirePoint extends BaseModel {

  geohash: string|null = null;
  geopoint?: GeoPoint|null;

  constructor(latitude: number=0.0, longitude: number=0.0) {
    super();
    this.setCoordinates(latitude, longitude);
  }

  get latitude(): number|null|undefined {
    return this.geopoint?.latitude;
  }
  set latitude(value: number|null|undefined) {
    if (value == null) {
      this.geopoint = null;
      this.geohash = null;
      return;
    }
    if (this.geopoint == null) {
      return;
    }
    this.setCoordinates(value, this.geopoint.longitude);
  }

  get longitude(): number|null|undefined {
    return this.geopoint?.longitude;
  }
  set longitude(value: number|null|undefined) {
    if (value == null) {
      this.geopoint = null;
      this.geohash = null;
      return;
    }
    if (this.geopoint == null) {
      return;
    }
    this.setCoordinates(this.geopoint.latitude, value);
  }

  setCoordinates(latitude: number, longitude: number) {
    this.geopoint = new fb.firestore.GeoPoint(latitude, longitude);
    this.geohash = encodeGeoPoint(this.geopoint.latitude, this.geopoint.longitude);
  }
}

interface PlaceServices {
  id: string;
  name: string;
}

export class PlaceCore extends Item {

  static fromPlace(place?: Place|null): PlaceCore {
    return new PlaceCore(place?.id, place?.name);
  }
}


export class Place extends FirestoreBase {

  name?: string;
  description?: string;
  address?: Address;
  phone?: string;
  website?: string;
  services?: PlaceServices[];
  logoURLs?: {[key: string]: string};
  links?: {[key: string]: string};
  geoLocation: GeoFirePoint;
  distance?: number; // not read or stored in database
  keywords?: string[];

  constructor() {
    super();
    this.geoLocation = new GeoFirePoint();
  }

  get cityState() {
    if (this.address != null) {
      return this.address.cityState;
    }
    return "";
  }

  get coordinates(): [number, number]|null|undefined {
    if (this.geoLocation?.geopoint == null) {
      return null;
    } else {
      return [
        this.geoLocation.geopoint.latitude,
        this.geoLocation.geopoint.longitude
      ];
    }
  }
  set coordinates(values) {
    if (values == null) {
      this.geoLocation.geopoint = null;
      return;
    }
    const [latitude, longitude] = values;
    if (latitude == null || longitude == null) {
      return;
    } else {
      this.geoLocation.setCoordinates(latitude, longitude);
    }
  }

  get coordinatesString(): string {
    if (this.geoLocation?.geopoint == null) {
      return "";
    } else {
      return [
        this.geoLocation.geopoint.latitude,
        this.geoLocation.geopoint.longitude
      ].join(", ");
    }
  }
  set coordinatesString(value: string) {
    if (value == "") {
      this.geoLocation.geopoint = null;
      return;
    }
    const [latitudeString, longitudeString] = value.split(",");
    if (latitudeString == null || longitudeString == null) {
      return;
    }
    try {
      this.geoLocation.setCoordinates(
        Number(latitudeString),
        Number(longitudeString)
      );
    } catch (e) {
      this.geoLocation.geopoint = null;
    }
  }

  // Create a map containing this instances properties
  toMap(): {[key:string]: any} {
    const map = super.toMap();
    map['geoLocation'] = this.geoLocation;
    console.log("----geolocation", map['geoLocation']);
    return map;
  }
  // Extract the values for this instance from the provided document data
  setFromMap(map?: Partial<this>) {
    if (map != null) {
      if ("address" in map) {
        this.address = new Address();
      }
    }
    super.setFromMap(map);
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    return true;
  }
}

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {PlaceBeer} from '../../models';
import PlaceBeerView from './PlaceBeerView';
import LabeledText from '../common/LabeledText';


interface Props {
  placeBeer?: PlaceBeer | null,
  onClose?: (value: string) => void,
  open?: boolean,
}


const PlaceBeerViewDialog = ({open=false, ...props}: Props) => {

  const {placeBeer, onClose} = props;

  const handleClose = () => {
    onClose && onClose('Closed');
  };

  return (
    <div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="placebeer-view-dialog-title"
      >
        <DialogTitle id="placebeer-view-dialog-title">Available Beer</DialogTitle>
        <DialogContent>
          <LabeledText label="Place" text={placeBeer?.place?.name}/>
          <LabeledText label="Beer" text={placeBeer?.beer?.name}/>
          <LabeledText label="Brewery" text={placeBeer?.beer?.brewery?.name}/>
          <LabeledText label="Packaging" text={placeBeer?.packaging?.name}/>
          <LabeledText
            label="Packaging Volumes"
            text={placeBeer?.packagingVolumes
              .map(vol => vol.name)
              .join(", ")}
          />
          <LabeledText
            label="Packaging Options"
            text={placeBeer?.packagingOptions
              .map(vol => vol.name)
              .join(", ")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PlaceBeerViewDialog;

import React, {Fragment, useContext, useState} from 'react';
import {
  Button,
  Grid,
  IconButton,
  List, ListItem, ListItemSecondaryAction, ListItemText,
  Typography
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Edit} from '@material-ui/icons';

import {AuthContext} from '../../contexts';
import {PicklistItem as ListItemModel} from '../../models';

import {YeastEdit, YeastView} from '../Yeast';
import useListFromFirestoreService
  from '../../helpers/useListFromFirestoreService';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginTop: theme.spacing(3)
  },
}));



const YeastsList = () => {
  console.log("Refreshing YeastsList");

  const classes = useStyles();

  const [_yeastId, setYeastId] = useState<string|null>(null);
  const [_editing, setEditing] = useState<boolean>(false);
  const [_selectedIndex, setSelectedIndex] = useState<number|null>(null);

  const {itemLists} = useListFromFirestoreService(
      'beerLists', ['yeasts'], false);

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles?.canContribute;

  const handleAdd = () => {
    setYeastId(null);
    setSelectedIndex(null);
    setEditing(true);
  };

  const handleDoneEditing = () => {
    setEditing(false);
    setSelectedIndex(null);
  };

  const handleEdit = (yeast: ListItemModel) => {
    if (yeast.id != null) {
      setYeastId(yeast.id);
      setEditing(true);
    }
  };

  const handleSelect = (yeast: ListItemModel, index: number) => {
    setSelectedIndex(index);
    setEditing(false);
    yeast.id && setYeastId(yeast.id);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography className={classes.title} variant="h2">
          Yeasts
        </Typography>
        {canContribute &&
          <Button
            size="small"
            color="primary"
            onClick={handleAdd}
          >
            Add
          </Button>
        }
        <List className={classes.root} dense>
          {itemLists.yeasts && itemLists.yeasts.map((yeast, i) => (
          <Fragment key={i}>
            <ListItem
              divider
              selected={_selectedIndex === i}
              onClick={() => handleSelect(yeast, i)}
            >
              <ListItemText
                primary={yeast.name}
              />
              {canContribute &&
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(yeast)}
                  >
                    <Edit/>
                  </IconButton>
                </ListItemSecondaryAction>
              }
            </ListItem>
          </Fragment>
          ))}
        </List>
      </Grid>
      <Grid item xs={6}>
        {_editing
            ? <YeastEdit id={_yeastId} onComplete={handleDoneEditing}/>
            : <YeastView id={_yeastId}/>
        }
      </Grid>
    </Grid>
  );
};

export default YeastsList;

import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  breakpoints: {
    values: {
      xs: 0, // default: 0
      sm: 600, // default: 600
      md: 960, // default: 960
      lg: 1280, // default: 1280
      xl: 1500, // default: 1920
    }
  },
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },

});

export default theme;

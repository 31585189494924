import {FirestoreService} from './FirestoreService';
import {UserRoles} from '../models';


export class UserRolesFirestoreService extends FirestoreService<UserRoles> {

  constructor(kwArgs: {
    equals?: {[key: string]: any} | null,
    orderBy?: string | null
  } = {}) {
    super({
      path: 'userRoles',
      objectCreator: UserRoles,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    this.useTransactions = false;
    console.log('UserRoleService construct');
  }

  async createUserRoles(id: string) {
    console.log('Creating userRoles');
    const docRef = this.collection.doc(id);
    const userRoles = new UserRoles();
    await docRef.set(userRoles.toMap());
    return await this.getByReference(docRef);
  }
}

import {FirestoreBase} from './FirestoreBase';
import {BeerCore} from './Beer';
import {Item} from './Item';
import {PlaceCore} from './Place';

export class PlaceBeer extends FirestoreBase {

  beer?: BeerCore;
  place?: PlaceCore;
  addedOn?: Date;
  addedById?: string;
  packaging?: Item;
  packagingVolumes: Item[] = [];
  packagingOptions: Item[] = [];
  status?: string;

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.beer == null) {
      return false;
    }
    if (this.place == null) {
      return false;
    }
    return true;
  }
}

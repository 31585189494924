import React from 'react';

import {BreweriesCardGrid} from '../components/Brewery';


export default function BreweriesView() {

  return (
    <div>
      <BreweriesCardGrid />
    </div>
  );
}

import _ from 'lodash';

import {default as fb} from 'firebase';
const DocumentReference = fb.firestore.DocumentReference;
const GeoPoint = fb.firestore.GeoPoint;

/**
 * Remove properties with undefined values deeply, using lodash
 * @param  {Object} obj Object to remove undefined properties
 * @return {Object}        Return a new object without undefined values
 */
export function removeUndefined(obj: {[key:string]: any}): {[key:string]: any} {
	//console.log("removeUndefined value", obj);
  if (obj instanceof Date
    || obj instanceof DocumentReference
    || obj instanceof GeoPoint) {
    return obj;
  }
  let cb = (v: {[key:string]: any}) => removeUndefined(v);
  return _.isObject(obj)
    ? _.isArray(obj)
      ? _.map(obj, cb)
      : _(obj).omitBy(_.isUndefined).mapValues(cb).value()
    : obj;
}

import {FirestoreService} from './FirestoreService';
import {Transaction} from '../models';


export class TransactionFirestoreService extends FirestoreService<Transaction> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'transactions',
      objectCreator: Transaction,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log('TransactionService construct');
  }
}

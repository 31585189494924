import {FirestoreBase} from './FirestoreBase';
import {default as fb} from 'firebase';

type DocumentReference = fb.firestore.DocumentReference;


export class Transaction extends FirestoreBase {

  type?: string;
  path?: string;
  userId?: string;
  itemId?: string;
  itemRef?: DocumentReference;
  data?: {[key: string]: any};
  status?: string;

  validate() {
    if (!super.validate()) {
      return false;
    }
    return true;
  }
}

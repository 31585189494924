import {FirestoreService} from './FirestoreService';
import {BeerSighting, PicklistItem} from '../models';


export class BeerSightingFirestoreService extends FirestoreService<BeerSighting> {

  constructor(kwArgs: {
    beer?: PicklistItem|null,
    place?: PicklistItem|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'beerSightings',
      objectCreator: BeerSighting,
      equals: {
        ...(kwArgs.beer?.id != null && {'beer.id': kwArgs.beer?.id}),
        ...(kwArgs.place?.id != null && {'place.id': kwArgs.place?.id}),
      },
      orderBy: kwArgs.orderBy
    });
    console.log("PlaceBeerService construct");
    this.useTransactions = false;
  }
}

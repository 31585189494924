import React, {useState, useEffect} from 'react';
import {ListFirestoreService} from '../firebase';
import {FirestoreListItem, PicklistItem} from '../models';
import {sortListItemsByName, sortListItemsByPicklistName} from './sortListItems';
import useStreamFromFirebaseService from './useStreamFromFirestoreService';

type ItemLists = {
  [key: string]: PicklistItem[]
};


export default function useListFromFirestoreService(
    category: string, listNames: string[],
    sortByPicklistName: boolean = true,
    sort: boolean = true
) {
  console.log("Running useStreamFromFirestoreService");
  const [itemLists, setItemLists] = useState<ItemLists>({});

  const {firestoreItems, streamError} = useStreamFromFirebaseService<FirestoreListItem, ListFirestoreService>(
      ListFirestoreService, {kwArgs: {equals: {name: category}}});

  console.log('useList items', firestoreItems);
  useEffect(() => {
    console.log("Items changed");
    const categoryLists = firestoreItems && firestoreItems[0];
    const newItemLists: ItemLists = {};
    if (categoryLists != null) {
      listNames.forEach(listName => {
        const list = categoryLists[listName] ?? [];
        if (sort) {
          if (sortByPicklistName) {
            list.sort(sortListItemsByPicklistName);
          } else {
            list.sort(sortListItemsByName);
          }
        }
        newItemLists[listName] = list;
      });
      setItemLists(newItemLists);
    }
  }, [firestoreItems]);

  return {itemLists, error: streamError};
}

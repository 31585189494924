import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Button, Checkbox,
  CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Link,
  TextField,
  Typography
} from '@material-ui/core';

import TermsAndConditions from '../common/TermsAndConditions';
import {AuthContext} from '../../contexts';
import useFormValidation, {FormErrors} from '../../helpers/useFormValidation';
import {BaseModel} from '../../models';
import signInStyles from './signInStyles';


class SignUpValues extends BaseModel {
  name: string = "";
  email: string = "";
  password: string = "";
  passwordConfirm: string = "";
  termsRead: boolean = false;
  agreeToTerms: boolean = false;
}


function validateSignUp(values: SignUpValues) {
  let errors: FormErrors<SignUpValues> = {};

  // Email Errors
  if (!values.email) {
    errors.email = "Email required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  // Password Errors
  if (!values.password) {
    errors.password = "Password required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be at least 6 characters";
  }
  // Password Confirm Errors
  if (values.passwordConfirm !== values.password) {
    errors.password = "Passwords must match";
  }
  // Agree to Terms Errors
  if (!values.agreeToTerms) {
    errors.agreeToTerms = "Must agree to the Terms and Conditions";
  }
  // Read Terms Errors
  if (!values.termsRead) {
    errors.termsRead = "Must read the Terms and Conditions";
  }

  return errors;
}

type SubmitEvent = React.FormEvent<HTMLFormElement>;

const INITITIAL_VALUE = new SignUpValues();

interface Props {
  onComplete?: () => void
}

const SignUp: FunctionComponent<Props> = props => {

  const {onComplete} = props;
  const {auth} = useContext(AuthContext);
  const [firebaseError, setFirebaseError] = useState<string | null>(null);
  const [openTerms, setOpenTerms] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    updateProperty,
    formItem,
    formErrors,
    isSubmitting
  } = useFormValidation<SignUpValues>(INITITIAL_VALUE, validateSignUp);

  const classes = signInStyles();

  useEffect(() => {
    const errorMessages = Object.values(formErrors);
    if (errorMessages.length > 0 && errorMessages[0] != null) {
      setFirebaseError(errorMessages[0]);
      return;
    }
  }, [formErrors]);

  const signUpAndVerify = async () => {
    if (formItem == null) {
      return;
    }
    const {name, email, password} = formItem;
    if (auth == null) {
      console.error("AuthContext auth is null");
      setFirebaseError("Not connected to authentication service");
      return;
    }
    console.log("Sending sign up");
    await auth.signUpWithEmail(name, email, password);
    console.log("opening dialog");
    setOpenVerify(true);
  };

  const handleRegister = (event: SubmitEvent) => {
    handleSubmit(
      event,
      () => signUpAndVerify()
      );
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
    updateProperty('termsRead', true);
  };

  const handleCloseVerify = () => {
    setOpenVerify(false);
    onComplete &&onComplete();
  };

  if (isSubmitting) {
    return <CircularProgress/>
  }

  console.log("SignUp errors", formErrors);

  return (
    <form
      className={classes.form}
      onSubmit={handleRegister}
    >
      <Typography
        className={classes.title}
        variant="h2"
      >
        Sign Up
      </Typography>
      <Typography
        align="center"
        className={classes.suggestion}
        color="textSecondary"
        variant="body1"
      >
        Use your email to create a new account
      </Typography>
      <TextField
        className={classes.textField}
        error={formErrors.name != null}
        fullWidth
        helperText={formErrors.name}
        label="Name"
        name="name"
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        value={formItem?.name || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={formErrors.email != null}
        fullWidth
        helperText={formErrors.email}
        label="Email address"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        value={formItem?.email || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={formErrors.password != null}
        fullWidth
        helperText={formErrors.password}
        label="Password"
        name="password"
        onChange={handleChange}
        onBlur={handleBlur}
        type="password"
        value={formItem?.password || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={formErrors.passwordConfirm != null}
        fullWidth
        helperText={formErrors.passwordConfirm}
        label="Confirm Password"
        name="passwordConfirm"
        onChange={handleChange}
        onBlur={handleBlur}
        type="password"
        value={formItem?.passwordConfirm || ''}
        variant="outlined"
      />
      <div className={classes.policy}>
        <Checkbox
          checked={formItem?.agreeToTerms || false}
          className={classes.policyCheckbox}
          color="primary"
          name="agreeToTerms"
          onChange={handleChange}
        />
        <Typography
          className={classes.policyText}
          color="textSecondary"
          variant="body1"
        >
          I agree to the{' '}
          <Button
            color="primary"
            onClick={() => setOpenTerms(true)}
          >
            Terms and Conditions
          </Button>
        </Typography>
      </div>
      <Button
        className={classes.signInButton}
        color="primary"
        //disabled={!isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Sign up now
      </Button>
      <Typography
        color="textSecondary"
        variant="body1"
      >
        Already have an account?{' '}
        <Link
          component={RouterLink}
          to="/sign-in"
          replace={true}
          variant="h6"
        >
          Sign in
        </Link>
      </Typography>
      <Typography
        color="error"
        variant="body1"
      >
        {firebaseError || ""}
      </Typography>
      <Dialog
        open={openTerms}
        onClose={handleCloseTerms}
        aria-labelledby="alert-dialog-terms-title"
        aria-describedby="alert-dialog-terms-description"
      >
        <DialogTitle id="alert-dialog-terms-title">{"Terms and Conditions"}</DialogTitle>
        <DialogContent>
          <TermsAndConditions/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTerms} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openVerify}
        onClose={handleCloseVerify}
        aria-labelledby="alert-dialog-verify-title"
        aria-describedby="alert-dialog-verify-description"
      >
        <DialogTitle id="alert-dialog-verify-title">{"Verify Account From Email"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-verify-description">
            {`An email has been sent to ${formItem?.email}. Follow the link the email to confirm your account.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVerify} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );

};

export default SignUp;

import {BaseModel} from './Base';


export class Address extends BaseModel {

  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;

  constructor(country: string = "United States") {
    super();
    this.country = country;
  }

  public get cityState(): string {
    let cityState = "";
    if (this.city != null) {
      cityState += this.city;
    }
    if (this.city != null && this.state != null) {
      cityState += ", ";
    }
    if (this.state != null) {
      cityState += this.state;
    }
    return cityState;
  }

  get cityStateZip(): string {
    let cityStateZip = "";
    const cityState = this.cityState;
    if (cityState != null) {
      cityStateZip += cityState;
    }
    if (cityStateZip.length > 0 && this.zip != null) {
      cityStateZip += ", " + this.zip;
    }
    return cityStateZip;
  }
}

import {BehaviorSubject} from 'rxjs';
import {default as fb} from 'firebase';
import firebase, {User} from './index';
import {FirestoreBase, Transaction} from '../models';
import {removeUndefined} from '../helpers/removeUndefined';

type CollectionReference = fb.firestore.CollectionReference;
type DocumentReference = fb.firestore.DocumentReference;
type DocumentSnapshot = fb.firestore.DocumentSnapshot;
type Firestore = fb.firestore.Firestore;
type Query = fb.firestore.Query;
type QueryDocumentSnapshot = fb.firestore.QueryDocumentSnapshot;
type QuerySnapshot = fb.firestore.QuerySnapshot;


export interface FirestoreServiceKeyWordArgs<T extends FirestoreBase> {
  path: string,
  objectCreator: { new (): T; },
  equals?: {[key: string]: any} | null,
  orderBy?: string | null
  startsWith?: {[key: string]: any} | null,
}

export class FirestoreService<T extends FirestoreBase> {

  /// Collection path for this instance
  path: string;

  /// Class type of the objects to be created using this instance
  objectCreator: new () => T;

  /// Field name to sort queries by
  orderBy?: string | null;

  /// Root collection of [path] without modifiers
  collection: CollectionReference;

  /// Current query on [collection]
  collectionQuery: Query;

  /*
  /// Query stream on [collection]
  ///
  /// [updateStream()] must be called to initialize the stream; otherwise, no
  /// documents from the Firestore query will be added to the stream.
  /// [updateStream()] is not automatically run to prevent streams without
  /// limits before a specific [updateStream()] can be executed.
  */
  get queryStream() {return this._query.subscribe()};

  _query = new BehaviorSubject<T[]>([]);
  _queryIsInitialized: boolean = false;
   _currentLimit: number = 10;
  _currentFilter: string|null = null;
  _currentFilteredSize: number = 10;
  _cancelFirestoreStreamSubscription: (() => void)|null = null;
  db: Firestore;
  useTransactions: boolean = true;

  _isDebugging: boolean = true;
  readonly _debugPrefix: string = 'FIRESTORE_SERVICE';
  runtimeType: string;

  constructor(kwArgs: FirestoreServiceKeyWordArgs<T>) {

    this.path = kwArgs.path;
    this.objectCreator = kwArgs.objectCreator;
    this.orderBy = kwArgs.orderBy;
    const equals = kwArgs.equals;
    const startsWith = kwArgs.startsWith;

    this.debugPrint(`Starting ${this.constructor.name}`);
    this.db = firebase.firestore();
    this.collection = this.db.collection(this.path);
    this.collectionQuery = this.collection;
    this.debugPrint(`  equals: ${equals}`);
    this.debugPrint(`  startsWith: ${startsWith}`);
    this.debugPrint(`  orderBy: ${this.orderBy}`);
    this.initialize({equals: equals, orderBy: this.orderBy, startsWith: startsWith});
    this.runtimeType = "Firestore";
  }

  initialize(
      kwArgs: {
        equals?: {[key: string]: any} | null,
        orderBy?: string | null,
        startsWith?: {[key: string]: any} | null,
     }) {
    const equals = kwArgs.equals;
    this.orderBy = kwArgs.orderBy;
    if (equals != null) {
      for (let key in equals) {
        let value = equals[key];
        if (value != null) {
          //console.log("key, val", key, value);
          this.collectionQuery = this.collectionQuery.where(key, "==", value);
        }
      }
    }
    const startsWith = kwArgs.startsWith;
    if (startsWith != null) {
      for (let key in startsWith) {
        let value = startsWith[key];
        if (value != null) {
          const endValue = value.replace(
            /.$/, (c: String) => String.fromCharCode(c.charCodeAt(0) + 1),
          );
          console.log('end', value, endValue);
          this.collectionQuery = this.collectionQuery
            .where(key, ">=", value)
            .where(key, "<", endValue);
            // .orderBy(key); // => orderBy of same field required before another orderBy
        }
      }
    }
  }

  debugPrint(msg: string) {
    if (this._isDebugging) {
      const prefix = this.constructor.name != null
        ? this.constructor.name.toLocaleUpperCase()
        : this._debugPrefix;
      console.log(`${prefix}: ${msg}`);
    }
  }

  async createObject(obj: {[key: string]: any}, user: User | null, id?: string | null) {

    this.debugPrint(`Creating object from data: ${obj}`);

    const itemId: string = id != null ? id : obj['id'];
    delete obj['id'];

    const itemRef: DocumentReference = obj['docRef'];
    delete obj['docRef'];

    // Remove all undefined properties as they throw errors from Firestore
    //Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
    const cleanObj = removeUndefined(obj);

    console.log("Cleaned object", cleanObj);

    if (this.useTransactions) {
      this.checkUserId(user);
      const transaction = Transaction.fromMap({
        type: 'create',
        path: this.path,
        userId: user!.id!,
        itemId: itemId,
        itemRef: itemRef,
        data: cleanObj,
      });
      this.debugPrint(`Running create transaction on ${id}: ${transaction.data}`);
      await this.createTransactionObject(transaction.toMap());

    } else {

      if (itemRef != null) {
        console.log("Updating non-transaction object");
        await itemRef.update(cleanObj);
      } else if (itemId != null) {
        console.log("Creating new non-transaction object with id");
        await this.collection.doc(itemId).set(cleanObj);
      } else {
        console.log("Creating new non-transaction object without id");
        await this.collection.doc().set(cleanObj);
      }
      this.debugPrint('  1 document updated');
    }
  }

  async createTransactionObject(data: {[key:string]: any}) {
    console.log("Transaction Data", data);
    this.db.collection('transactions').doc().set(data);
    this.debugPrint(`Transaction written ${data}`);
    this.debugPrint('  1 document created');
  }

  /// Update this service's stream based on the provided parameters
  ///
  /// If the stream has not been used, it will be initialized
  updateStream({
    filter,
    limit=20,
    setter
  }: {
    filter?: string;
    limit?: number;
    setter?: (arg: T[]) => void
  }={}) {
    this.debugPrint(`Updating stream for ${this.constructor.name}...`);

    const lcFilter = filter?.toLowerCase();
    if (this._queryIsInitialized
        && this._currentFilter === lcFilter
        && this._currentLimit === limit) {
      this.debugPrint("  No change to filter requested");
      return () => {};
    } else if (
        lcFilter != null
        && this._currentFilter != null
        && lcFilter.startsWith(this._currentFilter)
        && this._currentFilteredSize < limit) {
      // If the last query result can be filtered locally, do so to prevent
      // another Firestore query to reduce document reads
      this.debugPrint("  Filtering last object instead of new firestore stream");
      this._currentFilter = lcFilter;
      const lastData: T[] = this._query.value;
      const items = lastData.filter((obj: T) => {
        return obj?.keywords?.includes(lcFilter);
      });
      this._query.next(items);
      setter && setter(items);
      return () => {};
    }

    this._currentFilter = lcFilter || null;
    this._currentLimit = limit;

    this.debugPrint(`Filter: ${lcFilter}`);

    let query = this.collectionQuery;

    if (lcFilter != null && lcFilter.length > 0) {
          this.debugPrint(`Adding keyword to query ${lcFilter}`);
      query = query.where('keywords', "array-contains", lcFilter);
    }

    if (this.orderBy != null && this.orderBy.length > 0) {
      query = query.orderBy(this.orderBy);
    }

    if (limit != null) {
      query = query.limit(limit);
    }

    this._cancelFirestoreStreamSubscription && this._cancelFirestoreStreamSubscription();
    this._cancelFirestoreStreamSubscription = query
        .onSnapshot((snapshot: QuerySnapshot) => {
          this.debugPrint(`Fetching ${this.constructor.name} stream data from firestore`);
          const items = snapshot.docs.map(doc => this._newObject(doc));
          this._currentFilteredSize = items.length;
          this.debugPrint(`  ${this._currentFilteredSize} documents read`);
          this._query.next(items);
          setter && setter(items);
    });
    this._queryIsInitialized = true;
    return this._cancelFirestoreStreamSubscription;
  }

  _newObject(doc: DocumentSnapshot) {
    const item: T = new this.objectCreator();
    item.setFromDocument(doc);
    return item;
  }

  async getById(docId: string) {
    this.debugPrint(`Getting object by id ${docId} for ${this.runtimeType}...`);
    const docRef: DocumentReference = this.collection.doc(docId);
    return await this.getByReference(docRef);
  }

  async getByReference(docRef: DocumentReference) {
    this.debugPrint(`Getting object by ref ${docRef} for ${this.runtimeType}...`);
    const snap: DocumentSnapshot = await docRef.get();
    if (!snap.exists) {
      return null;
    }
    this.debugPrint('  1 document read');
    return this._newObject(snap);
  }

  getStream(setter: (arg: T[]) => void) {
    let collection = this.collection;
    if (this.orderBy != null) {
      collection = collection.orderBy(this.orderBy) as CollectionReference;
    }
    return collection
        .onSnapshot((snapshot: QuerySnapshot) => {
          const items = snapshot.docs.map<T>((doc: QueryDocumentSnapshot) =>
              this._newObject(doc));
          setter(items);
        });
  }

  async toList() {
    this.debugPrint(`Getting object list from current collection for ${this.runtimeType}...`);

    const qs: QuerySnapshot = await this.collectionQuery.get();
    const objList: T[] = qs.docs.map<T>((ds: DocumentSnapshot) => this._newObject(ds));
    this.debugPrint(`  ${objList.length} documents read`);
    return objList;
  }

  async updateObject(obj: {[key: string]: any}, user: User|null) {
    console.log("updateObject obj", obj);
    this.debugPrint(`Updating object ${obj}...`);
    const itemId: string = obj['id'];
    delete obj['id'];

    const itemRef: DocumentReference = obj['docRef'];
    delete obj['docRef'];

    // Remove all undefined properties as they throw errors from Firestore
    console.log("pre-clean obj", obj);
    const cleanObj = removeUndefined(obj);
    console.log("Cleaned obj", cleanObj);

    if (Object.keys(cleanObj).length === 0) {
      console.log("Warning: Nothing to update");
      return;
    }

    if (this.useTransactions) {
      this.checkUserId(user);
      const transaction = Transaction.fromMap({
        type: 'update',
        path: this.path,
        userId: user!.id!,
        itemRef: itemRef,
        data: cleanObj,
        itemId: itemId
      });
      console.log("transaction", transaction);
      this.debugPrint(`  Running update transaction on ${itemId}: ${transaction.data}`);
      await this.createTransactionObject(transaction.toMap());

    } else {

      if (itemRef != null) {
        itemRef.update(obj);
      } else if (itemId != null) {
        this.collection.doc(itemId).set(obj);
      } else {
        this.debugPrint('  Error cannot update document without reference or id');
      }

    }
  }

  async deleteObject(obj: {[key: string]: any}, user: User|null) {

    this.debugPrint('Deleting object...');

    const itemId: string = obj['id'];
    delete obj['id'];

    const itemRef: DocumentReference = obj['docRef'];
    delete obj['docRef'];

    // Remove all undefined properties as they throw errors from Firestore
    //Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
    const cleanObj = removeUndefined(obj);

    if (this.useTransactions) {

      this.checkUserId(user);
      //const transaction = new Transaction({
      const transaction = Transaction.fromMap({
        type: 'delete',
        path: this.path,
        userId: user!.id!,
        itemRef: itemRef,
        data: cleanObj,
        itemId: itemId
      });
      this.debugPrint(`  Running delete transaction on ${itemId}: ${transaction.data}`);
      await this.createTransactionObject(transaction.toMap());

    } else {

      if (itemRef != null) {
        itemRef.delete();
        this.debugPrint('  1 document deleted');
      } else if (itemId != null) {
        await this.collection.doc(itemId).delete();
        this.debugPrint('  1 document deleted');
      } else {
        this.debugPrint('  Error cannot delete document without reference or id');
      }

    }
  }

  checkUserId(user: User | null) {
    if (user == null) {
      this.debugPrint("User required for submitting a transaction");
      throw new Error("User required for submitting a transaction");
    }
    if (user.id == null) {
      this.debugPrint("User Id required for submitting a transaction");
      throw new Error("User Id required for submitting a transaction");
    }
  }
}

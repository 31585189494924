import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

interface Props {
  component: React.ComponentType<any>;
  exact?: boolean;
  layout: React.ComponentType<any>;
  path: string;
}

const RouteWithLayout = (props: Props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  exact: PropTypes.bool,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
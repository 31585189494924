/*
 * Encode latitude and longitude into a hash that allows distance based queries
 * in Firestore.
 * Based on GeoFlutterFire which is used in the mobile versions.
 * https://github.com/DarshanGowda0/GeoFlutterFire/blob/master/lib/src/util.dart
 */

const encodeAuto = 'auto';
const sigfigHashLength = [0, 5, 7, 8, 11, 12, 13, 15, 16, 17, 18];
const BASE32_CODES = '0123456789bcdefghjkmnpqrstuvwxyz';


export function encodeGeoPoint(
    latitude: number|string|null,
    longitude: number|string|null,
    numberOfChars: number|string|null = 9
) {
  if (latitude == null || longitude == null) {
    return null;
  }
  if (numberOfChars === encodeAuto) {
    if (typeof latitude === "number" || typeof longitude === "number") {
      throw new Error("string notation required for auto precision.");
    }
    const decSigFigsLat = latitude.split('.')[1].length;
    const decSigFigsLon = longitude.split('.')[1].length;
    const numberOfSigFigs = Math.max(decSigFigsLat, decSigFigsLon);
    numberOfChars = sigfigHashLength[numberOfSigFigs];
  } else if (numberOfChars == null) {
    numberOfChars = 9;
  }

  let chars: string[] = [], bits = 0, bitsTotal = 0, hashValue = 0;
  let maxLat = 90, minLat = -90, maxLon = 180, minLon = -180, mid: number;

  while (chars.length < numberOfChars) {
    if (bitsTotal % 2 === 0) {
      mid = (maxLon + minLon) / 2;
      if (longitude > mid) {
        hashValue = (hashValue << 1) + 1;
        minLon = mid;
      } else {
        hashValue = (hashValue << 1) + 0;
        maxLon = mid;
      }
    } else {
      mid = (maxLat + minLat) / 2;
      if (latitude > mid) {
        hashValue = (hashValue << 1) + 1;
        minLat = mid;
      } else {
        hashValue = (hashValue << 1) + 0;
        maxLat = mid;
      }
    }

    bits++;
    bitsTotal++;
    if (bits === 5) {
      let code = BASE32_CODES[hashValue];
      chars.push(code);
      bits = 0;
      hashValue = 0;
    }
  }

  console.log("returning hash", chars);
  return chars.join('');
}
import React from 'react';
import {
  FormControl,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {makeStyles, Theme} from '@material-ui/core/styles';

import renameObjectKey from '../../helpers/renameObjectKey';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    justifyContent: "left",
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    //maxWidth: 300,
  },
  textField: {
    marginTop: theme.spacing(2),
    //display: "inline",
  },
}));


interface Links {
  [key:string]: string;
}


interface Props {
  onChange?: (val: Links) => void;
  value: Links|null|undefined;
}

const LinkListEdit = (props: Props) => {
  const {value={}, onChange} = props;

  const classes = useStyles();

  const handleNameChange = (oldName: string, newName: string) => {
    const newLinks = renameObjectKey(oldName, newName, value);
    if (newLinks != null) {
      onChange && onChange(newLinks);
    }
  };

  const handleURLChange = (name: string, newValue: string) => {
    const newLinks = {
      ...(value || {}),
      [name]: newValue,
    };
    onChange && onChange(newLinks);
  };

  const handleAddClick = () => {
    const newLinks = {
      ...(value || {}),
      "": "",
    };
    onChange && onChange(newLinks);
  };

  return (
    <FormControl className={classes.formControl}>
      {value && Object.keys(value).map((name, i) =>
        <div key={i}>
          <TextField
            className={classes.textField}
            label="Name"
            name="name"
            onChange={e => handleNameChange(name, e.target.value)}
            type="text"
            value={name || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            label="URL"
            name="url"
            onChange={e => handleURLChange(name, e.target.value)}
            type="text"
            value={value[name] || ''}
            variant="outlined"
          />
        </div>
      )}
      <IconButton
          color="secondary"
          className={classes.button}
          aria-label="add"
          onClick={handleAddClick}
      >
        <Add/>
        <Typography
          className={classes.body}
          variant="body1"
        >
          Add a New Link
        </Typography>
      </IconButton>
     </FormControl>
  );
};

export default LinkListEdit;

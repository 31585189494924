import {PicklistItem} from '../models';

export const sortListItemsByName = (a: PicklistItem, b: PicklistItem) => {
  if (a.name == null) {
    return -1;
  } else if (b.name == null) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const sortListItemsByPicklistName = (a: PicklistItem, b: PicklistItem) => {
  if (a.picklistName == null) {
    return -1;
  } else if (b.picklistName == null) {
    return 1;
  } else {
    return a.picklistName.localeCompare(b.picklistName);
  }
};

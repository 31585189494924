import React, {Fragment, useContext, useState} from 'react';
import {
  Button,
  Grid,
  IconButton,
  List, ListItem, ListItemSecondaryAction, ListItemText,
  Typography
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Edit} from '@material-ui/icons';

import {AuthContext} from '../../contexts';
import useListFromFirestoreService
  from '../../helpers/useListFromFirestoreService';
import {PicklistItem} from '../../models';

import {AdjunctEdit, AdjunctView} from '../Adjunct';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginTop: theme.spacing(3)
  },
}));


interface Props {
}

const AdjunctsList = (props: Props) => {
  console.log("Refreshing AdjunctsList");

  const classes = useStyles();

  const [_adjunctId, setAdjunctId] = useState<string|null>(null);
  const [_editing, setEditing] = useState<boolean>(false);
  const [_selectedIndex, setSelectedIndex] = useState<number|null>(null);

  const {itemLists} = useListFromFirestoreService(
      'beerLists', ['adjuncts'], false);

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles?.canContribute;

  const handleAdd = () => {
    setAdjunctId(null);
    setSelectedIndex(null);
    setEditing(true);
  };

  const handleDoneEditing = () => {
    setEditing(false);
    setSelectedIndex(null);
  };

  const handleEdit = (adjunct: PicklistItem) => {
    if (adjunct.id != null) {
      setAdjunctId(adjunct.id);
      setEditing(true);
    }
  };

  const handleSelect = (adjunct: PicklistItem, index: number) => {
    setSelectedIndex(index);
    setEditing(false);
    adjunct.id && setAdjunctId(adjunct.id);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography className={classes.title} variant="h2">
          Adjuncts
        </Typography>
        {canContribute &&
          <Button
            size="small"
            color="primary"
            onClick={handleAdd}
          >
            Add
          </Button>
        }
        <List className={classes.root} dense>
          {itemLists.adjuncts && itemLists.adjuncts.map((adjunct, i) => (
          <Fragment key={i}>
            <ListItem
              divider
              selected={_selectedIndex === i}
              onClick={() => handleSelect(adjunct, i)}
            >
              <ListItemText
                primary={adjunct.name}
              />
              {canContribute &&
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(adjunct)}
                  >
                    <Edit/>
                  </IconButton>
                </ListItemSecondaryAction>
              }
            </ListItem>
          </Fragment>
          ))}
        </List>
      </Grid>
      <Grid item xs={6}>
        {_editing
            ? <AdjunctEdit id={_adjunctId} onComplete={handleDoneEditing}/>
            : <AdjunctView id={_adjunctId}/>
        }
      </Grid>
    </Grid>
  );
};

export default AdjunctsList;

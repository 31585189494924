import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';

import {AuthProvider} from './contexts';
import Routes from './routes/Routes';
import theme from './theme';


function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes />
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;

import React from 'react';
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {SelectEvent} from '../../helpers/useFormValidation';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

interface SelectItem {
  id?: string;
  name?: string;
  [key: string]: any;
}

interface Props {
  label?: string|null;
  name: string;
  onChange?: (values: SelectItem) => void;
  items?: SelectItem[];
  value?: SelectItem;
}

const SingleSelect = (props: Props) => {

  const {label, name, items, onChange, value} = props;

  const classes = useStyles();

  const handleChange = (event: SelectEvent) => {
    event.persist();
    if (onChange != null && items != null) {
      const selected = event.target.value;
      let match = items.find(item => item.picklistName && selected === item.picklistName);
      if (match != null) {
        onChange(match);
        return;
      }
      match = items.find(item => item.name && selected === item.name);
      if (match != null) {
        onChange(match);
        return;
      }
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        id={`${name}-select`}
        labelId={`${name}-label`}
        name={name}
        value={value?.picklistName || value?.name || ""}
        onChange={(event) =>
            handleChange(event as SelectEvent)}
        input={<Input id={`${name}-input`} />}
      >
        {items && items.map(item => (
          <MenuItem key={item.id} value={item.picklistName || item.name}>
            {item.picklistName || item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;

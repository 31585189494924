import React, {FunctionComponent} from 'react';
import clsx from 'clsx';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {Grid, Link, Typography} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

interface Props {
  className?: String,
}

const Footer: FunctionComponent<Props> = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body1">
            &copy;{' '}
            <Link
              component="a"
              href="https://bevdb.org/"
              target="_blank"
            >
              BevDb
            </Link>
            {' '}2020
          </Typography>
          <Typography variant="caption">
            The open beverage database.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <Link
              component={RouterLink}
              to="/terms"
              replace={true}
              variant="h6"
            >
              Terms
            </Link>
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <Link
              component={RouterLink}
              to="/privacy"
              replace={true}
              variant="h6"
            >
              Privacy
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            <Link
              component="a"
              href="https://groups.google.com/a/bevdb.org/d/forum/bevdb-support"
              target="newTab"
              >
              Support
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
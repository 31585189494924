import React, {useState} from 'react';

import CardGrid from '../common/CardGrid';
import {BreweryFirestoreService} from '../../firebase';
import {Brewery} from '../../models';

import BreweryCard from './BreweryCard';
import BreweryEditDialog from './BreweryEditDialog';


interface Props {
  onEdit?: (item: Brewery|null) => void,
  onSelect?: (item: Brewery|null) => void,
}

const BreweriesCardGrid = (props: Props) => {
  console.log("Refreshing BreweriesList");

  const {onEdit, onSelect} = props;

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const handleCloseDialog = (value: string) => {
    setOpenAddDialog(false);
  };

  return (
    <CardGrid
        card={<BreweryCard/>}
        editDialog={<BreweryEditDialog open={openAddDialog} onClose={handleCloseDialog}/>}
        firestoreService={BreweryFirestoreService}
        onEdit={onEdit}
        onSelect={onSelect}
        openDialog={() => setOpenAddDialog(true)}
    />
  );
};

export default BreweriesCardGrid;

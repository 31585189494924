import React, {useContext, useState} from 'react';
import {
  Avatar,
  IconButton,
  List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText,
  Typography,
} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {Check, Clear, LocalDrink} from '@material-ui/icons';

import {AuthContext} from '../../contexts';
import {
  BeerSightingFirestoreService,
  PlaceBeerFirestoreService
} from '../../firebase';
import useItemFromFirestoreService
  from '../../helpers/useItemFromFirestoreService';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';
import {BeerSighting, Place, PlaceBeer} from '../../models';
import PlaceBeerViewDialog from './PlaceBeerViewDialog';


const useStyles = makeStyles((theme: Theme) => ({
  beerList: {
    width: 200,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  beerListItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
    margins: 0,
    padding: 0,
  },
  beerListText: {
    margins: 0,
    padding: 0,
  },
  beerListActions: {
    margins: 0,
    padding: 0,
  },
  beerListIcons: {
    margins: 0,
    paddingRight: 0,
  },
  beerListTitle: {
    paddingTop: 5,
    paddingBottom: 0,
    marginTop: 5,
    marginBottom: 0,
  },
}));

interface Props {
  place?: Place|null,
  status?: string,
}

const PlaceBeersList = (props: Props) => {
  console.log("Refreshing PlaceBeersList");

  const {place, status} = props;
  const classes = useStyles();

  const [openPlaceBeerDialog, setOpenPlaceBeerDialog] = useState<boolean>(false);
  const [clickedPlaceBeer, setClickedPlaceBeer] = useState<PlaceBeer|null>(null);

  const {firestoreItems} = useStreamFromFirebaseService<PlaceBeer,PlaceBeerFirestoreService>(
      PlaceBeerFirestoreService, {kwArgs: {place: place, status: status}});

  const {db} =
      useItemFromFirestoreService<BeerSighting,BeerSightingFirestoreService>(
          null, BeerSightingFirestoreService);

  const {currentUser, currentUserRoles} = useContext(AuthContext);
  const canApprove = currentUserRoles != null && currentUserRoles.canApprove;
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  if (firestoreItems == null || firestoreItems.length === 0) {
    return null;
  }

  const handleConfirmClick = (value: PlaceBeer) => {
    const beerSighting = new BeerSighting({
      placeBeerId: value.id,
      reportedById: currentUser?.id,
      reportedOn: new Date(),
      action: 'Confirm',
    });
    db && db.createObject(beerSighting.toMap(), currentUser);
  };

  const handleRemoveClick = (value: PlaceBeer) => {
    const beerSighting = new BeerSighting({
      placeBeerId: value.id,
      reportedById: currentUser?.id,
      reportedOn: new Date(),
      action: 'Remove',
    });
    db && db.createObject(beerSighting.toMap(), currentUser);
  };

  const handleBeerClick = (beer: PlaceBeer) => {
    setClickedPlaceBeer(beer);
    setOpenPlaceBeerDialog(true);
  };

  const handleClosePlaceBeerDialog = () => {
    setOpenPlaceBeerDialog(false);
  };

  const createConfirmButton = (placeBeer: PlaceBeer) => {
    if (status === 'Reported' && canApprove) {
      return (
        <IconButton className={classes.beerListIcons}
            edge="end"
            aria-label="confirm"
            onClick={() => handleConfirmClick(placeBeer)}
        >
            <Check/>
        </IconButton>
      );
    }
    return null;
  };

  const createRemoveButton = (placeBeer: PlaceBeer) => {
    if (status === 'Confirmed' && canContribute) {
      return (
        <IconButton className={classes.beerListIcons}
            edge="end"
            aria-label="remove"
            onClick={() => handleRemoveClick(placeBeer)}
        >
            <Clear/>
        </IconButton>
      );
    } else if (status === 'Reported'
        && (canApprove || currentUser?.id === placeBeer.addedById)) {
      return (
        <IconButton className={classes.beerListIcons}
            edge="end"
            aria-label="remove"
            onClick={() => handleRemoveClick(placeBeer)}
        >
            <Clear/>
        </IconButton>
      );
    }
    return null;
  };

  const sortedItems = firestoreItems.sort(
    (a, b) =>
      a.beer?.name?.localeCompare(b.beer?.name || "") || 0
  );
  return (
    <div className={classes.beerList}>
      <Typography gutterBottom variant="h5" className={classes.beerListTitle}>
        {status === 'Confirmed' ? "Confirmed Beers" : "Reported Beers"}
      </Typography>
      <List dense className={classes.beerList}>
      {sortedItems && sortedItems.map((pb, i) =>
        <ListItem button divider key={i} className={classes.beerListItem}
          onClick={() => handleBeerClick(pb)}
        >
          <ListItemAvatar>
            <Avatar alt="Travis Howard" src={pb?.beer?.logoURL}>
              <LocalDrink/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText className={classes.beerListText}
            style={pb.status?.endsWith('Removed')
              ? {textDecoration: "line-through"}
              : {}}
              primary={pb.beer?.name || ""}
              secondary={pb.beer?.type?.name || ""}
          />
          <ListItemSecondaryAction className={classes.beerListActions}>
            {createConfirmButton(pb)}
            {createRemoveButton(pb)}
          </ListItemSecondaryAction>
        </ListItem>
      )}
      </List>
      <PlaceBeerViewDialog
        placeBeer={clickedPlaceBeer}
        open={openPlaceBeerDialog}
        onClose={handleClosePlaceBeerDialog}
      />
    </div>
  )
};

export default PlaceBeersList;

import React, {FunctionComponent, useContext, useEffect} from 'react';
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';

import {AuthContext} from '../../contexts';
import useFormValidation, {FormErrors} from '../../helpers/useFormValidation';
import {BaseModel} from '../../models';
import useStyles from './signInStyles';

class SignInValues extends BaseModel {
  email: string = "";
  password: string = "";
}


function validateSignUp(values: SignInValues) {
  let errors: FormErrors<SignInValues> = {};

  // Email Errors
  if (!values.email) {
    errors.email = "Email required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  // Password Errors
  if (!values.password) {
    errors.password = "Password required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be at least 6 characters";
  }

  return errors;
}

type SubmitEvent = React.FormEvent<HTMLFormElement>;

const INITIAL_SIGN_IN = new SignInValues();

interface Props {
  onComplete?: () => void
}

const VerifySignIn: FunctionComponent<Props> = props => {

  const {onComplete} = props;
  const {auth} = useContext(AuthContext);
  const [firebaseError, setFirebaseError] = React.useState<string|null>(null);

  async function authenticateUser(authFunc: () => Promise<any>) {
    try {
      console.log("authUser");
      await authFunc();
      onComplete && onComplete();
    } catch (e) {
      console.error("Authentication Error:", e);
      setFirebaseError(e.message);
    }
  }

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    updateProperty,
    formItem,
    formErrors,
    isSubmitting
  } = useFormValidation<SignInValues>(INITIAL_SIGN_IN, validateSignUp);

  const classes = useStyles();

  useEffect(() => {
    if (formItem != null && formItem.email === "") {
      const email = window.localStorage.getItem('emailForSignIn');
      if (email != null) {
        updateProperty("email", email);
      }
    }
  }, [formItem]);

  const handleEmailVerify = (event: SubmitEvent) => {
    if (formItem == null) {
      return;
    }
    const {email, password} = formItem;
    if (auth == null) {
      console.error("AuthContext auth is null");
      throw new Error("Not connected to authentication service");
    }
    handleSubmit(
        event,
        () => authenticateUser(
            () => auth.confirmSignUpEmailLink(email, password)
        )
    );
  };

  if (isSubmitting) {
    return <CircularProgress/>
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleEmailVerify}
    >
      <Typography
        className={classes.title}
        variant="h2"
      >
        Verify Login
      </Typography>
      <Typography
        align="center"
        className={classes.suggestion}
        color="textSecondary"
        variant="body1"
      >
        Use your email and password to verify your new account
      </Typography>
      <TextField
        className={classes.textField}
        error={formErrors.email != null}
        fullWidth
        helperText={formErrors.email}
        label="Email address"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        value={formItem?.email || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={formErrors.password != null}
        fullWidth
        helperText={formErrors.password}
        label="Password"
        name="password"
        onChange={handleChange}
        onBlur={handleBlur}
        type="password"
        value={formItem?.password || ''}
        variant="outlined"
      />
      <Button
        className={classes.signInButton}
        color="primary"
        //disabled={!isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Confirm Sign Up
      </Button>
      <Typography
        color="error"
        variant="body1"
      >
        {firebaseError || ""}
      </Typography>
    </form>
  );

};

export default VerifySignIn;

import React from 'react';
import {IconButton, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Add} from "@material-ui/icons";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      marginTop: theme.spacing(2)
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  }),
);

interface TableProps {
  label?: string;
  value?: string[];
  onChange?: (param: string[]) => void;
}

const StringListEdit = ({value=[], ...props}: TableProps) => {
  const {label, onChange} = props;
  console.log("StringListEdit", props);

  const classes = useStyles();

  const newStrings = value || [];

  const handleChange = (value: string, index: number) => {
    console.log("handle change", value, index);
    newStrings[index] = value;
    onChange && onChange(newStrings);
  };

  const handleAddClick = () => {
    console.log("handle add");
    newStrings.push("");
    onChange && onChange(newStrings);
  };

  return (
    <div>
      {value.map((val, i) =>
        <input
            key={i}
            type="text"
            value={val}
            onChange={e => handleChange(e.target.value, i)}
        />
      )}
      <IconButton
          color="secondary"
          className={classes.button}
          aria-label="add"
          onClick={handleAddClick}
      >
        <Add/>
        <Typography
          className={classes.body}
          variant="body1"
        >
          {label || ""}
        </Typography>
      </IconButton>
     </div>
  );
};

export default StringListEdit;

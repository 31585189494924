import React, {FunctionComponent, useEffect, useState} from 'react';
import {AuthService} from '../firebase';
import {User, UserRoles} from '../models';


interface ContextProps {
  auth: AuthService | null,
  currentUser: User | null,
  currentUserRoles: UserRoles | null,
  setAuthService: ((auth: AuthService|null) => void) | null,
  setCurrentUser: ((user: User|null) => void) | null,
  setCurrentUserRoles: ((userRoles: UserRoles|null) => void) | null,
}

export const AuthContext = React.createContext<ContextProps>({
  auth: null,
  currentUser: null,
  currentUserRoles: null,
  setAuthService: null,
  setCurrentUser: null,
  setCurrentUserRoles: null,
});

interface Props {
}

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  const [auth, setAuthService] = useState<AuthService|null>(null);
  const [currentUser, setCurrentUser] = useState<User|null>(null);
  const [currentUserRoles, setCurrentUserRoles] = useState<UserRoles|null>(null);


  useEffect(() => {
    if (auth == null) {
      console.log("Initializing AuthContext.Provider");
      setAuthService(new AuthService(setCurrentUser, setCurrentUserRoles));
    }
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        auth, setAuthService, currentUser, setCurrentUser, currentUserRoles, setCurrentUserRoles
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

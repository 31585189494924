import {FirestoreBase} from './FirestoreBase';
import {PicklistItem} from './PicklistItem';


export class FirestoreListItem extends FirestoreBase {

  packaging?: PicklistItem[];

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    return true;
  }
}

import React, {FunctionComponent, useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@material-ui/core';
import {
  AccountBox,
  ArrowDropDown,
  ExitToApp,
  Settings
} from '@material-ui/icons';

import {AuthContext} from '../../contexts';
import LabeledText from '../../components/common/LabeledText';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    //display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
  button: {
    borderRadius: 30,
    fontSize: '1.2rem',
    padding: 8,
  },
  menu: {
    marginLeft: '-16px',
  },
  name: {
    fontWeight: 500,
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

interface Props {
  className?: String,
}

const Profile: FunctionComponent<Props> = props => {
  const {className} = props;

  const classes = useStyles();

  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const {auth, setAuthService, currentUser, currentUserRoles} = useContext(AuthContext);
  console.log("Profile currentUser", currentUser);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (currentUser == null) {
    return (
      <div>
        <Typography
          className={classes.name}
          variant="h4"
        >
          Not Logged In
        </Typography>
      </div>
    )
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleProfileClick() {
    setOpenProfile(true);
    handleClose();
  }

  function handleCloseProfile() {
    setOpenProfile(false);
  }

  function handleSettingsClick() {
    setOpenSettings(true);
    handleClose();
  }

  function handleCloseSettings() {
    setOpenSettings(false);
  }

  async function handleLogout() {
    handleClose();
    auth && await auth.signOut();
    setAuthService && setAuthService(null);
  }

  return (
    <div className={clsx(classes.root, className)}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Search"
        className={classes.button}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar
          className={classes.avatar}
          alt={currentUser.name || undefined}
          src={currentUser.avatarURL || undefined}
        />
        <Typography
          className={classes.name}
          variant="h4"
        >
          {currentUser.name || ""}
        </Typography>
        <ArrowDropDown />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.menu,
        }}
      >
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </MenuItem>
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>

      <Dialog
          open={openProfile}
          onClose={handleCloseProfile}
          aria-labelledby="profile-dialog-title"
      >
        <DialogTitle id="profile-dialog-title">Profile</DialogTitle>
        <DialogContent>
          <LabeledText label="Name" text={currentUser.name}/>
          <LabeledText label="Email" text={currentUser.email}/>
          <LabeledText label="Points" text={currentUser?.points}/>
          <LabeledText
            label="Roles"
            text={currentUserRoles && Object.entries(currentUserRoles).map(
              ([role, valid], i) => {
                return valid === true ? role : null
            }).filter(x => x != null).join(", ")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProfile} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={openSettings}
          onClose={handleCloseSettings}
          aria-labelledby="settings-dialog-title"
      >
        <DialogTitle id="settings-dialog-title">Settings</DialogTitle>
        <DialogContent>
          <Typography variant="h3">
            Coming Soon
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettings} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );

};

export default Profile;

import React, {FunctionComponent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link, Typography} from '@material-ui/core';

import ItemView, {useItemViewStyles} from '../common/ItemView';
import LabeledText from '../common/LabeledText';
import {PlaceBeerFirestoreService} from '../../firebase';
import {PlaceBeer} from '../../models';

interface Props {
  placeBeer?: PlaceBeer | null,
}

const PlaceBeerView: FunctionComponent<Props> = props => {

  const {placeBeer} = props;
  console.log("PlaceBeerView", placeBeer);

  const classes = useItemViewStyles();

  const renderView = (item: PlaceBeer|null) => {
    return (
      <div className={classes.body}>
        {item?.place &&
        <Typography
            className={classes.title}
            variant="h2"
        >
            <Link component={RouterLink} to={`/place/${item?.place?.id}`}>
              {item?.place?.name || ""}
            </Link>
        </Typography>
        }
        {item?.beer &&
        <Typography
            className={classes.title}
            variant="h2"
        >
            <Link component={RouterLink} to={`/beer/${item?.beer.id}`}>
              {item?.beer?.name || ""}
            </Link>
        </Typography>
        }
        {item?.beer?.brewery &&
        <Typography
            className={classes.body}
            variant="body1"
        >
            <Link component={RouterLink}
                  to={`/brewery/${item?.beer?.brewery?.id}`}>
              {item?.beer?.brewery?.name || ""}
            </Link>
        </Typography>
        }
        <LabeledText label="Packaging" text={item?.packaging?.name}/>
        <LabeledText
          label="Packaging Volumes"
          text={item?.packagingVolumes.map(vol => vol.name).join(", ")}
        />
        <LabeledText
          label="Packaging Options"
          text={item?.packagingOptions.map(vol => vol.name).join(", ")}
        />
      </div>
    );
  };

  return (
    <ItemView
      item={placeBeer}
      service={PlaceBeerFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default PlaceBeerView;

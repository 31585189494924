import {FirestoreBase} from './FirestoreBase';


export class User extends FirestoreBase {

  name?: string | null;
  email?: string | null;
  avatarURL?: string | null;
  points?: number;
  karma?: number;

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    if (this.email == null || this.email === "") {
      return false;
    }
    return true;
  }
}

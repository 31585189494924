import React from 'react';
import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {makeStyles, useTheme, Theme} from '@material-ui/core/styles';
import {SelectEvent} from '../../helpers/useFormValidation';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    //maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultiSelectItem {
  id?: string;
  name?: string;
  picklistName?: string;
  [key: string]: any;
}

function getStyles(item: MultiSelectItem, items: MultiSelectItem[], theme: Theme) {
  return {
    fontWeight:
      items.some(i => i.name === item.name)
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Props {
  label?: string|null;
  name: string;
  onChange?: (values: MultiSelectItem[]) => void;
  items?: MultiSelectItem[];
  values?: MultiSelectItem[];
}

const MultiSelect = (props: Props) => {

  const {label, name, items, onChange, values} = props;

  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event: SelectEvent) => {
    event.persist();
    console.log('event', event);
    if (onChange != null && items != null) {
      const selected = event.target.value;
      let matches = items.filter(item => item.picklistName && selected.includes(item.picklistName));
      console.log('match', matches, selected, items);
      if (matches != null && matches.length > 0) {
        onChange(matches);
        return;
      }
      console.log('Trying name');
      matches = items.filter(item => item.name && selected.includes(item.name));
      if (matches != null && matches.length > 0) {
        onChange(matches);
      }
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        id={`${name}-select`}
        labelId={`${name}-label`}
        name={name}
        multiple
        value={(values && values.map(val => val.picklistName || val.name)) || []}
        onChange={(event) =>
            handleChange(event as SelectEvent)}
        input={<Input id={`${name}-input`} />}
        renderValue={selected => (
          <div className={classes.chips}>
            {(selected as MultiSelectItem[]).map((val, i) => (
              <Chip key={i} label={val} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {items && items.map(item => (
          <MenuItem key={item.id} value={item.picklistName || item.name}
                    style={getStyles(item, values || [], theme)}>
            {item.picklistName || item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;

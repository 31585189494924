import React, {Fragment, useState} from 'react';
import {TextField, Typography} from '@material-ui/core';

import AddressesEdit from '../common/AddressesEdit';
import ImagePicker from '../common/ImagePicker';
import LinkListEdit from '../common/LinkListEdit';
import {BreweryFirestoreService} from '../../firebase';
import {uploadLogo} from '../../helpers/imageUpload';
import {FormErrors, InputEvent, SelectEvent} from '../../helpers/useFormValidation';
import {Address, Brewery} from '../../models';
import ItemEdit, {useItemEditStyles} from '../common/ItemEdit';


function validateBrewery(values: Brewery) {
  let errors: FormErrors<Brewery> = {};

  // Name Errors
  if (!values.name) {
    errors.name = "Name required";
  } else if (values.name.length < 2) {
    errors.name = "Name must be at least 2 characters";
  }

  return errors;
}

const INITIAL_VALUE = new Brewery();

interface Props {
  brewery?: Brewery|null,
  formId?: string,
  onCancel?: () => void,
  onComplete?: () => void,
  onError?: (msg: string) => void,
  hideButtons?: boolean,
  hideError?: boolean,
}

const BreweryEdit = (props: Props) => {

  const {brewery, formId, onComplete, onError, hideButtons=false, hideError=false} = props;
  console.log("BreweryEdit brewery", brewery);

  const classes = useItemEditStyles();

  const [_logoFile, setLogoFile] = useState<File|null>(null);

  async function preSubmit(item: Brewery) {
    if (_logoFile != null) {
      item.logoURLs = await uploadLogo(_logoFile, 'logos/breweries');
    }
  }

  const renderFields = (
    formItem: Brewery|null,
    formErrors: FormErrors<Brewery>,
    handleBlur: () => void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const logoURL = formItem?.logoURLs && formItem.logoURLs['originalURL'];

    function handleAddressesChange(addresses: Address[]) {
      updateProperty && updateProperty("addresses", addresses);
    }

    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {brewery?.name || "New Brewery"}
        </Typography>
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.name != null}
          fullWidth
          helperText={formErrors.name}
          label="Name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.name || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.description != null}
          fullWidth
          helperText={formErrors.description}
          label="Description"
          multiline
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.description || ''}
          variant="outlined"
        />
        <AddressesEdit
          addresses={formItem?.addresses}
          classes={classes}
          onChange={handleAddressesChange}
        />
        <ImagePicker
          label="Logo"
          imageURL={logoURL}
          folder="logos/breweries"
          onChange={setLogoFile}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.website != null}
          fullWidth
          helperText={formErrors.website}
          label="Website"
          name="website"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.website || ''}
          variant="outlined"
        />
        <LinkListEdit
          value={formItem?.links}
          onChange={(val) => updateProperty("links", val)}
        />
      </Fragment>
    );
  };

  return (
    <ItemEdit
      item={brewery}
      defaultValue={INITIAL_VALUE}
      service={BreweryFirestoreService}
      fieldRenderer={renderFields}
      validator={validateBrewery}
      formId={formId}
      preSubmit={preSubmit}
      onComplete={onComplete}
      onError={onError}
      hideButtons={hideButtons}
      hideError={hideError}
    />
  )
};

export default BreweryEdit;

import {FirestoreService} from './FirestoreService';
import {Place} from '../models';


export class PlaceFirestoreService extends FirestoreService<Place> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'places',
      objectCreator: Place,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log("PlaceService construct");
  }
}

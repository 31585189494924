import React from 'react';
import {List, ListItem, ListItemText, Typography} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(3)
  },
  body: {
    marginTop: theme.spacing(2)
  },
  h2: {
    marginTop: theme.spacing(2)
  },
  h3: {
    marginTop: theme.spacing(2)
  },
  h4: {
    marginTop: theme.spacing(2)
  },
  date: {
  }
}));

const PrivacyPolicy = () => {

  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.title} variant="h1">
        Privacy Policy
      </Typography>
      <Typography className={classes.date} variant="body2">
        Updated February 16, 2020
      </Typography>
        <p>We value our relationship with you and your privacy. This privacy policy explains how and why we handle information, collected through bevdb.org, associated sites, forums, channels and any other app we provide (collectively, our “Site”). This privacy policy is part of our Terms of Use.</p>
        <p>While we do not encourage our Members to upload any Regulated Data or Submission (as defined in our Terms of Use) that contains the personal information of other people, there may be circumstances in which we are either unaware that a Submission contains personal information or the Member has complied with our Terms of Use in uploading the personal information of others in such a Submission. If you are a Member, please pay special attention to whether you are uploading a Submission with personal information to our Site. IF INFORMATION ABOUT YOU IS IN A SUBMISSION AND IF YOU HAVE ANY QUESTIONS, PLEASE CONTACT US AS PROVIDED BELOW.</p>
        <p>When you visit bevdb.org, you are agreeing to this privacy policy and our Terms of Use. If you do not agree with this privacy policy or our Terms of Use, please do not use our Site. Except as specifically described, this policy does not apply to data contained within your Submissions.</p>

      <Typography className={classes.h2} variant="h2">
        Information We Collect
      </Typography>
      <p>We collect information about you directly from you and from third-parties, as well as automatically through your use of bevdb.org.</p>
      <Typography className={classes.h3} variant="h3">
        Information We Collect Directly from You
      </Typography>
      <p>The information we collect from you depends on how you use our Site. When you register, we collect your user name, password, email address. We may also collect your payment information through our third-party payment processor and information when you contact us.</p>

      <Typography className={classes.h3} variant="h3">
        Information We Collect from Social Networks
      </Typography>
      <p>Our Site includes social media features, such as the share this button. These features may collect your IP address, which pages you are visiting on our site and set a cookie to enable the features to function properly. They are either hosted by a third-party or hosted directly on our Site. This privacy policy does not apply to these features. Your interactions with these features are governed by the privacy policy and other policies of the companies providing them.</p>
      <p>You may also log into our Site through your social networking accounts, including Facebook, Google, Twitter, or GitHub, among others.</p>
      <Typography className={classes.h4} variant="h4">
        Facebook
      </Typography>
      <p>If you log into our Site using Facebook, you must log into Facebook using your Facebook email address and password. By logging into our Site in through Facebook, you are granting us permission to: (i) access and collect your Facebook basic profile information (this includes your name, profile picture, user ID and email address); (ii) send you email; and (iii) access your Facebook data at any time even if you are not using Facebook. If you allow us to have access to this information, then we will have access to this information even if you have chosen not to make that information public.</p>
      <Typography className={classes.h4} variant="h4">
        Twitter
      </Typography>
      <p>If you log into our Site using Twitter, you must log into Twitter using your Twitter email or user name and password. By logging into our Site through Twitter, you are granting us access to read Tweets from your timeline, see whom you follow, and see the email address associated with your Twitter account.</p>
      <Typography className={classes.h4} variant="h4">
        Google
      </Typography>
      <p>If you log into our Site using Google, you log into your Google account using your Google email address and password. By logging into our Site through your Google account you are granting us access to your name, email address and profile picture.</p>
      <Typography className={classes.h4} variant="h4">
        GitHub
      </Typography>
      <p>If you log into our Site using GitHub, you must log into your GitHub account using your GitHub user name and password. By logging into our Site through your GitHub account, you are granting us access to your user name, email address and profile picture.</p>

      <p>We store the information that we receive from social networks with other information that we collect from you or receive about you. We do not collect or access your third-party social network passwords. Any third-party social networking site controls the information it collects from you. For information about how they may use and disclose your information, including any information you make public, please consult their respective privacy policies. We have no control over how any third-party site uses or discloses the personal information it collects about you.</p>

      <Typography className={classes.h3} variant="h3">
        Information We Collect Automatically
      </Typography>
        <p>We automatically collect the following information about your use of our Site through cookies, web beacons, and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; the length of time you visit or use our Site; and the referring URL, or the webpage that led you to our Site. We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information. Please see the section “Cookies and Tracking” below for more information.</p>

      <Typography className={classes.h2} variant="h2">
        Use of Information
      </Typography>
      <p>We use your information for a variety of purposes, including:</p>
      <Typography className={classes.h3} variant="h3">
        Customer service
      </Typography>
      <p>We use your information for customer service purposes, including to provide services to you; to provide you with technical support, or other similar purposes; and to establish and update your customer account.</p>
      <Typography className={classes.h3} variant="h3">
        Marketing
      </Typography>
      <p>We use your information for marketing purposes, including to provide you with special offers, promotions, news, and newsletters; to contact you about products we think may interest you; and for other marketing, advertising, and promotional purposes. We also may use the information that we learn about you to assist us in advertising our services on third-party websites.</p>
      <Typography className={classes.h3} variant="h3">
        Business
      </Typography>
      <p>We use information we collect for business development purposes, including in presentations, blogs and papers we publish.</p>
      <Typography className={classes.h3} variant="h3">
        Administration
      </Typography>
      <p>We use your information for administrative purposes, including to help us better understand how our Members and visitors use and access bevdb.org; to provide reports to prospective partners, service providers, regulators, and others; to implement and maintain security, anti-piracy, fraud prevention, and other services designed to protect you and us; to enforce this privacy policy, our Terms of Use, and other policies.</p>
      <Typography className={classes.h3} variant="h3">
        Research and development
      </Typography>
      <p>We use your information for research and development purposes, including to improve our Site, services, and Member and visitor experience; to understand our Member and visitor demographics; and for other research and analytical purposes.</p>
      <Typography className={classes.h3} variant="h3">
        Legal compliance
      </Typography>
      <p>We use your information to comply with applicable legal or regulatory obligations, including to respond to a subpoena or court order; to cooperate with law enforcement or other governmental investigations; and as part of legal proceedings.</p>
      <Typography className={classes.h3} variant="h3">
        Protection of us and others
      </Typography>
      <p>We use your information where we believe it is necessary to protect us and others.</p>

      <Typography className={classes.h2} variant="h2">
        Sharing of Information
      </Typography>
      <p>We share your information with our company affiliates and service providers. We may also share your information in the following ways:</p>

      <Typography className={classes.h3} variant="h3">
        Site Members and Visitors
      </Typography>
      <p>Depending on your profile settings, your user name, name, and profile information may be available to visitors and Members. We may share your information with other Members, who may use that information to contact you, including for marketing purposes.</p>
      <Typography className={classes.h3} variant="h3">
        Business transfers
      </Typography>
      <p>We may share your information with another entity if we or any of our current or future corporate relatives are acquired by or merged with another legal entity, if substantially all of our assets are sold or transferred to another legal entity, as part of a bankruptcy proceeding, or as part of a similar transfer of assets.</p>
      <Typography className={classes.h3} variant="h3">
        Program and Content Partners
      </Typography>
      <p>We may work with third parties to develop white papers or other content and to sponsor events. If you access this content or sign up for an event, we will share the information you provide to access the content or event with these third-party partners and sponsors. These third parties may use your information to contact you, including for their own marketing purposes.</p>
      <Typography className={classes.h3} variant="h3">
        In response to legal process
      </Typography>
      <p>We may share your information when we believe it is appropriate to comply with the law or a regulatory requirement.</p>
      <Typography className={classes.h3} variant="h3">
        To protect us and others
      </Typography>
      <p>We may share your information where we believe it is necessary to:</p>
      <List>
        <ListItem>
          <ListItemText>
            prevent or investigate a possible crime, such as fraud or identity theft
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            to enforce a contract
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            to protect our and the legal rights of others, our Site, or safety of you, bevdb.org, our corporate relatives, and our employees, clients, partners, agents, other Members and visitors, or the public in general
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            to enforce our Terms of Use or this privacy policy
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            to monitor and remediate security issues
          </ListItemText>
        </ListItem>
      </List>
      <Typography className={classes.h3} variant="h3">
        Aggregate and De-Identified Information
      </Typography>
      <p>We may share aggregate or de-identified information about Members with third-parties for marketing, advertising, research, training or similar purposes.</p>
      <p>When you submit your payment information, you are authorizing our third-party payment processor to collect, retain, use and disclose the information you provide as part of that process.</p>

      <Typography className={classes.h2} variant="h2">
        Your Choices
      </Typography>
      <p>You have certain choices regarding the information we collect about you. For example, you may:</p>

      <Typography className={classes.h3} variant="h3">
        Request to modify the personal information you submitted to us
      </Typography>
      <p>If you have created an account on our Site, you may modify the personal information you submitted to us through your Account Settings page on our Site.</p>
      <Typography className={classes.h3} variant="h3">
        Request to modify content you have posted
      </Typography>
      <p>If you have created an account on our Site, you may directly modify content that you have posted to our Site.</p>
      <Typography className={classes.h3} variant="h3">
        Opt out of promotional email communications
      </Typography>
      <p>You may opt out of promotional emails by clicking on the unsubscribe link in the email, or if you have an account with us, by submitting your request through the Help button on your Account Settings page on our Site.</p>
      <Typography className={classes.h3} variant="h3">
        Deactivate your account
      </Typography>
      <p>If you have an account with us, you may deactivate your account by submitting your request through the Help button on your Account Settings page on our Site. Your published queries and the comments and contributions you have made, will remain on our Site.</p>

      <Typography className={classes.h2} variant="h2">
        Cookies
      </Typography>
      <p>We or our service providers may use cookies to track visitor activity on our Site. We or our service providers may use cookies to track Member and visitor activities on our Site, such as the web pages you view and time you spend on our Site. Visitors to our Site who disable cookies may not be able to browse certain areas of our Site.</p>
      <p>Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Note that if you get a new computer, install a new browser, erase or otherwise alter your browser’s cookie file (including upgrading your browser) you may also clear out any opt-out cookie.</p>

      <Typography className={classes.h3} variant="h3">
        Clear GIFs, Pixel Tags and Other Technologies
      </Typography>
      <p>Clear GIFs are tiny graphics with a unique identifier, similar in functionality to cookies, which are embedded invisibly on web pages. We or our service providers may use clear GIFs (also known as web beacons, web bugs, pixel tags, or action tags, among other names), in connection with our Site to perform functions like tracking the activities of visitors to our Site, helping us manage content, and compiling statistics about usage of and visits to our Site. We or our service providers may also use clear GIFs in emails to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.</p>
      <Typography className={classes.h3} variant="h3">
        Embedded Scripts
      </Typography>
      <p>We use embedded scripts, which is code designed to collect information about how visitors interact with a website, such as the website which linked them to our Site.</p>
      <Typography className={classes.h3} variant="h3">
        Third-Party Analytics
      </Typography>
      <p>We use service providers, such as Google Analytics, among others, to evaluate the access and use of our Site. We also may use other analytic means to evaluate our Site. We use these tools to help us improve our services, performance and Member experiences. These entities may use cookies and other tracking technologies to perform their services. To review how Google Analytics collects and processes data, see https://www.google.com/intl/en/policies/privacy/.</p>
      <Typography className={classes.h3} variant="h3">
        Do Not Track
      </Typography>
      <p>Our Site do not respond to Do Not Track signals, but we do not track your activities once you leave one of our Site. You may disable certain tracking as discussed in this section (e.g., by disabling cookies).</p>
      <Typography className={classes.h3} variant="h3">
        Third-Party Ad Networks
      </Typography>
      <p>We use third-parties such as network advertisers to serve advertisements on our Site and on third-party websites or other media (e.g., social networking platforms). This enables us and these third-parties to target advertisements to you for products and services in which you might be interested. Third-party ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you. These third-party cookies and other technologies are governed by each third-party’s specific privacy policy, not this one. We may provide these third-party advertisers with information, including personal information, about you.</p>
      <p>Visitors and Members in the United States may opt out of many third-party ad networks. For example, you may go to the Digital Advertising Alliance (“DAA”) Consumer Choice Page for information about opting out of interest-based advertising and their choices regarding having information used by DAA companies. You may also go to the Network Advertising Initiative (“NAI”) Consumer Opt-Out Page for information about opting out of interest-based advertising and their choices regarding having information used by NAI members.</p>
      <p>Opting out from one or more companies listed on the DAA Consumer Choice Page or the NAI Consumer Opt-Out Page will opt you out from those companies’ delivery of interest-based content or ads to you, but it does not mean you will no longer receive any advertising through our Site or on other websites. You may continue to receive advertisements, for example, based on the particular website that you are viewing (i.e., contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA or NAI websites, your opt out may not be effective. Additional information is available on the DAA’s website at www.aboutads.info or the NAI’s website at www.networkadvertising.org.</p>

      <Typography className={classes.h2} variant="h2">
        Third-Party Links
      </Typography>
      <p>Our Site may contain links to non-bevdb.org websites and applications. Any access to and use of such non-bevdb.org websites and applications are not governed by this privacy policy.</p>

      <Typography className={classes.h2} variant="h2">
        International Transfers of Information
      </Typography>
      <p>If you are located anywhere outside of the United States, please be aware that information we collect about you, including personal information, will be transferred to, processed and stored in the United States.</p>
      <p>U.S. governmental agencies, courts, or law enforcement may be able to access or obtain your personal information (for example, in response to a court order) or personal information contained in your Submissions to a private side of our Site. By accessing or using bevdb.org, you consent to the transfer, processing, and storage of your information, including personal information, in the United States.</p>

      <Typography className={classes.h2} variant="h2">
        Children
      </Typography>
      <p>Our Site is for general audiences and is not targeted to children. We do not knowingly collect any personal information from children younger than the age of 18. If you learn that your minor child has provided us with personal information without your consent, please contact us.</p>

      <Typography className={classes.h2} variant="h2">
        Security
      </Typography>
      <p>We have taken steps to help protect the information we collect about you and the data sets that you post to the private side of our Site. However, no data security measures can guarantee security. Please note that we cannot guarantee the security of any information we have collected about you or the Submissions or data that you post to our Site, and your access or use of bevdb.org indicates that you understand and accept this risk.</p>
      <p>You should take steps to protect your account by, among other things, choosing a strong password that nobody else knows or can easily guess and keeping your log-in and password information private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity.</p>

      <Typography className={classes.h2} variant="h2">
        Your California Privacy Rights
      </Typography>
      <p>Under California’s “Shine the Light” law, visitors and Members of our Site who are California residents can request information about the types of personal information (if any) shared by us with third-parties (if any) for “Direct Marketing Purposes” within the preceding calendar year, as well as the names and addresses of those third-parties.Direct Marketing Purposes means the use of certain types of personal information to solicit or induce a purchase, rental, lease, or exchange of products, goods, property, or services directly to you.</p>
        <p>If you are a California resident, you may email your request to support@bevdb.org. Please (i) put “California Privacy Information Request” in the subject line and in the body of your message and (ii) state that you are a California resident and provide your California address. You may make one (1) request per calendar year.</p>
        <p>Please note that we may take thirty (30) days to respond to your request. Please also be aware that California’s “Shine the Light” law only applies to certain types of personal information and only those types of personal information covered by the law will be included in our response.</p>

      <Typography className={classes.h2} variant="h2">
        Changes to this Privacy Notice
      </Typography>
      <p>This privacy policy will be updated from time to time. Changes to this privacy policy will go into effect when posted and will only apply to information collected on and after the “Last Updated” date of the revised privacy policy. If revisions to this privacy policy materially affect how we use personal information that we collected from you prior to the date of the revised privacy policy, we will obtain your consent for any new uses or sharing of your personal information. We will post any updated privacy policy to our Site.</p>

      <Typography className={classes.h2} variant="h2">
        Contact
      </Typography>
      <p>By email: support@bevdb.org</p>
      <p>By mail:</p>
      <p>BevDb</p>
      <p>5578 Greenwich Park Drive</p>
      <p>Mason, OH</p>

    </div>
  );
};

export default PrivacyPolicy;





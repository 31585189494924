import React, {Fragment, useContext} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Divider,
  IconButton,
  Link,
  List, ListItem, ListItemSecondaryAction, ListItemText,
  Typography,
} from '@material-ui/core';
import {Theme, makeStyles} from '@material-ui/core/styles';
import {Check, Clear} from '@material-ui/icons';

import {AuthContext} from '../../contexts';
import {functions, TransactionFirestoreService} from '../../firebase';
import useStreamFromFirebaseService
  from '../../helpers/useStreamFromFirestoreService';
import {Transaction} from '../../models';


const useStyles = makeStyles((theme: Theme) => ({
  inline: {
    display: 'inline',
  },
  title: {
    marginTop: theme.spacing(3)
  },
  transactionList: {
    marginTop: 10,
  },
}));

interface Props {
}

const TransactionsList = (props: Props) => {
  console.log("Refreshing TransactionsList");


  const classes = useStyles();

  const {firestoreItems} = useStreamFromFirebaseService<Transaction,TransactionFirestoreService>(
      TransactionFirestoreService, {});

  const {auth} = useContext(AuthContext);
  const currentUser = auth && auth.currentUser;
  const currentUserRoles = auth && auth.currentUserRoles;
  const canAdminister = currentUserRoles != null && currentUserRoles.canAdminister;
  const canApprove = currentUserRoles != null && currentUserRoles.canApprove;
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  if (firestoreItems == null || firestoreItems.length === 0) {
    return (
      <Typography
        className={classes.title}
        variant="h2"
      >
        No pending transactions
      </Typography>
    );
  }

  const handleApproveClick = async (value: Transaction) => {
    // Call Cloud Function to confirm the transaction
    const callable = functions.httpsCallable('handleTransaction');
    try {
      const result = await callable({
        transactionId: value.id,
        userId: currentUser?.id,
        action: 'Approve'});
      console.log(`Confirm transaction response: ${result.data}`);
    } catch (e) {
      console.log('Confirm transaction error', e);
      throw Error(e.message);
    }
  };

  const handleRejectClick = async (value: Transaction) => {
    // Call Cloud Function to confirm the transaction
    const callable = functions.httpsCallable('handleTransaction');
    try {
      const result = await callable({
        transactionId: value.id,
        userId: currentUser?.id,
        action: 'Reject'});
      console.log(`Reject transaction response: ${result.data}`);
    } catch (e) {
      console.log('Reject transaction error', e);
      throw Error(e.message);
    }
  };

  const createConfirmButton = (placeBeer: Transaction) => {
    if (canApprove) {
      return (
        <IconButton
            edge="end"
            aria-label="confirm"
            onClick={() => handleApproveClick(placeBeer)}
        >
            <Check/>
        </IconButton>
      );
    }
    return null;
  };

  const createRejectButton = (transaction: Transaction) => {
    if (canApprove) {
      return (
        <IconButton
            edge="end"
            aria-label="reject"
            onClick={() => handleRejectClick(transaction)}
        >
            <Clear/>
        </IconButton>
      );
    }
    return null;
  };

  return (
    <div className={classes.transactionList}>
      <Typography gutterBottom variant="h5" component="h2">
        Transactions
      </Typography>
      <List component="nav">
      {firestoreItems && firestoreItems.map((trans, i) =>
        <Fragment key={i}>
          <ListItem button>
            <ListItemText
                primary={trans.path || ""}
                secondary={
                  <Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {trans.action || ""}
                    </Typography>
                    {JSON.stringify(trans.data)}
                  </Fragment>}
            />
            <ListItemSecondaryAction>

              {createConfirmButton(trans)}
              {createRejectButton(trans)}

            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
        </Fragment>
      )}
      </List>
    </div>
  )
};

export default TransactionsList;

import React, {Fragment, FunctionComponent, useContext, useState} from 'react';
import {Button, Grid, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';

import AddressView from '../common/AddressView';
import ItemView, {useItemViewStyles} from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledText from '../common/LabeledText';
import LabeledURL from '../common/LabeledURL';
import {AuthContext} from '../../contexts';
import {PlaceFirestoreService} from '../../firebase';
import {Place, PlaceCore} from '../../models';

import {BeerSightingEditDialog} from './BeerSightingEdit';
import PlaceEditDialog from './PlaceEditDialog';
import PlaceBeersList from './PlaceBeersList';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    paddingLeft: 10,
    marginTop: 10,
    marginBottom: 10,
  },
}));


interface Props {
  place?: Place | null,
}

const PlaceView: FunctionComponent<Props> = props => {

  const {place} = props;

  //console.log("PlaceView", place);

  const viewClasses = useItemViewStyles();
  const classes= useStyles();

  const {currentUserRoles} = useContext(AuthContext);
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openBeerDialog, setOpenBeerDialog] = useState<boolean>(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickOpenBeerSightingDialog = () => {
    setOpenBeerDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseBeerSightingDialog = () => {
    setOpenBeerDialog(false);
  };

  const renderView = (item: Place|null) => {
    return (
      <div className={viewClasses.root}>
        {canContribute &&
          <div>
            <Button size="small" color="primary"
                    onClick={handleClickOpenDialog}>
                Edit
            </Button>
            <PlaceEditDialog
                place={item}
                open={openDialog}
                onClose={handleCloseDialog}
            />
          </div>
        }
        <Grid container spacing={6}>
          <Grid item className={viewClasses.leftCentered}>
            {item?.logoURLs &&
              <img className={viewClasses.image}
                src={item?.logoURLs['400pxURL']}
                alt="Logo"
              />
            }
          </Grid>
          <Grid item className={clsx(viewClasses.info, viewClasses.leftCentered)}>
            <Typography className={viewClasses.title} variant="h2">
              {item?.name}
            </Typography>

            <Typography className={viewClasses.body} variant="body2" color="textSecondary">
              {item?.description}
            </Typography>

            <LabeledText
              label="Services"
              text={item?.services && item?.services.map(service => service.name).join(", ")}
            />

            <AddressView address={item?.address} className={viewClasses} labeled/>
            <LabeledText
              label="Phone"
              text={item?.phone}
            />
            <LabeledURL
              label="Website"
              url={item?.website}
            />
            <LabeledLinks
              label="Links"
              links={item?.links}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item className={classes.column}>
            {<PlaceBeersList place={item} status="Confirmed"/>}
          </Grid>
          <Grid item className={classes.column}>
            {<PlaceBeersList place={item} status="Reported"/>}
          </Grid>
        </Grid>
        {canContribute &&
        <Fragment>
            <Button size="small" color="primary"
                    onClick={handleClickOpenBeerSightingDialog}>
                Add Beer
            </Button>
            <BeerSightingEditDialog
                place={PlaceCore.fromPlace(item)}
                open={openBeerDialog}
                onClose={handleCloseBeerSightingDialog}
            />
        </Fragment>
        }
      </div>
    );
  };

  return (
    <ItemView
      item={place}
      service={PlaceFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default PlaceView;

import {FirestoreService} from './FirestoreService';
import {User} from '../models';


export class UserFirestoreService extends FirestoreService<User> {

  constructor(kwArgs: {
    equals?: {[key: string]: any} | null,
    orderBy?: string | null
  } = {}) {
    super({
      path: 'users',
      objectCreator: User,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    this.useTransactions = false;
    console.log('UserService construct');
  }

  async createUser(id: string, data: {[key: string]: any}) {
    console.log('Creating user');
    const docRef = this.collection.doc(id);
    await docRef.set(data, {merge: true});
    return await this.getByReference(docRef);
  }
}

import {FirestoreService} from './FirestoreService';
import {Hop} from '../models';


export class HopFirestoreService extends FirestoreService<Hop> {

  constructor(kwArgs: {
    equals?: {[key: string]: any}|null,
    orderBy?: string
  } = {}) {
    super({
      path: 'hops',
      objectCreator: Hop,
      equals: kwArgs.equals,
      orderBy: kwArgs.orderBy
    });
    console.log('HopService construct');
  }
}

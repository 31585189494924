import React, {Fragment, FunctionComponent} from 'react';
import {Typography} from '@material-ui/core';

import LabeledLinks from '../common/LabeledLinks';
import LabeledText from '../common/LabeledText';
import {HopFirestoreService} from '../../firebase';
import {Hop} from '../../models';
import ItemView, {useItemViewStyles} from '../common/ItemView';


interface Props {
  hop?: Hop | null,
  id?: string|null,
}

const HopView: FunctionComponent<Props> = props => {

  const {hop, id} = props;

  const classes = useItemViewStyles();

  const renderView = (item: Hop|null) => {
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {item?.name}
        </Typography>
        <Typography
          className={classes.body}
          variant="body1"
        >
          {item?.description}
        </Typography>
        <LabeledText
          label="Picklist Name"
          text={item?.picklistName}
        />
        <LabeledText
          label="Aliases"
          text={item?.aliases && item.aliases.join(", ")}
        />
        <LabeledText
          label="Alpha:"
          text={`${item?.alphaMin} to ${item?.alphaMax}%`}
        />
        <LabeledText
          label="Created by:"
          text={item?.creator}
        />
        <LabeledLinks
          label="Links"
          links={item?.links}
        />
      </Fragment>
    );
  };

  return (
    <ItemView
      item={hop}
      id={id}
      service={HopFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default HopView;

import React, {Fragment, useEffect, useState} from 'react';
import {TextField, Typography} from '@material-ui/core';

import {BrewerySelect} from '../Brewery';
import ImagePicker from '../common/ImagePicker';
import ItemEdit, {useItemEditStyles} from '../common/ItemEdit';
import LinkListEdit from '../common/LinkListEdit';
import MultiSelect from '../common/MultiSelect';
import SingleSelect from '../common/SingleSelect';
import {BeerFirestoreService} from '../../firebase';
import {uploadLogo} from '../../helpers/imageUpload';
import {FormErrors, InputEvent, SelectEvent} from '../../helpers/useFormValidation';
import useListFromFirestoreService
  from '../../helpers/useListFromFirestoreService';
import {Beer, BreweryCore} from '../../models';


function validateBeer(values: Beer) {
  let errors: FormErrors<Beer> = {};

  // Name Errors
  if (!values.name) {
    errors.name = "Name required";
  } else if (values.name.length < 2) {
    errors.name = "Name must be at least 2 characters";
  }

  // Brewery Errors
  if (values.brewery?.id == null) {
    errors.brewery = "Brewery required";
  }
  return errors;
}

const INITIAL_VALUE = new Beer();

interface Props {
  beer?: Beer|null,
  brewery?: BreweryCore|null,
  formId?: string,
  onComplete?: () => void,
  onError?: (msg: string) => void,
  hideButtons?: boolean,
  hideError?: boolean,
}

const BeerEdit = (props: Props) => {

  const {beer, brewery, formId, onComplete, onError, hideButtons=false, hideError=false} = props;

  //console.log("BeerEdit", beer);

  const classes = useItemEditStyles();

  const [_beer, setBeer] = useState<Beer|null>(null);

  useEffect(() => {
    if (beer == null && brewery != null) {
      setBeer(() => {
        const newBeer = INITIAL_VALUE;
        if (newBeer != null) {
          newBeer.brewery = brewery;
        }
        return newBeer;
      });
    } else if (beer != null && brewery != null) {
      setBeer((oldBeer) => {
        const newBeer = oldBeer?.clone();
        if (newBeer != null) {
          newBeer.brewery = brewery;
        }
        return newBeer;
      });
    } else {
      setBeer(beer||null);
    }
  }, [brewery, beer]);

  const [_logoFile, setLogoFile] = useState<File|null>(null);

  const {itemLists} = useListFromFirestoreService(
    'beerLists',
      ['adjuncts', 'availabilities', 'beerTypes', 'hops', 'malts', 'yeasts']);

  async function preSubmit(item: Beer) {
    if (_logoFile != null) {
      item.logoURLs = await uploadLogo(_logoFile, 'logos/beers');
    }
  }

  const renderFields = (
    formItem: Beer|null,
    formErrors: FormErrors<Beer>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {
    const logoURL = formItem?.logoURLs && formItem.logoURLs['originalURL'];

    function handleBrewerySelect(brewery: BreweryCore | null) {
      if (brewery == null) {
        updateProperty('brewery', undefined);
      } else {
        updateProperty('brewery', {id: brewery.id, name: brewery.name});
      }
    }

    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h2"
        >
          {beer?.name || "New Beer"}
        </Typography>
        <BrewerySelect
          brewery={formItem?.brewery}
          onChange={handleBrewerySelect}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.name != null}
          fullWidth
          helperText={formErrors.name}
          label="Name"
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.name || ''}
          variant="outlined"
        />
        <SingleSelect
          label="Type"
          name="type"
          items={itemLists.beerTypes}
          value={formItem?.type}
          onChange={(val) => updateProperty("type", val)}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.description != null}
          fullWidth
          helperText={formErrors.description}
          label="Description"
          multiline
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.description || ''}
          variant="outlined"
        />
        <ImagePicker
          label="Logo"
          imageURL={logoURL}
          folder="logos/breweries"
          onChange={setLogoFile}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.abv != null}
          fullWidth
          helperText={formErrors.abv}
          label="ABV"
          name="abv"
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          value={formItem?.abv || ''}
          variant="outlined"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.ibu != null}
          fullWidth
          helperText={formErrors.ibu}
          label="IBU"
          name="ibu"
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          value={formItem?.ibu || ''}
          variant="outlined"
        />
        <SingleSelect
          label="Availability"
          name="availability"
          items={itemLists.availabilities}
          value={formItem?.availability}
          onChange={(val) => updateProperty("availability", val)}
        />
        <MultiSelect
          label="Hops"
          name="hops"
          items={itemLists.hops}
          values={formItem?.hops}
          onChange={(vals) => updateProperty("hops", vals)}
        />
        <MultiSelect
          label="Malts"
          name="malts"
          items={itemLists.malts}
          values={formItem?.malts}
          onChange={(vals) => updateProperty("malts", vals)}
        />
        <MultiSelect
          label="Yeasts"
          name="yeasts"
          items={itemLists.yeasts}
          values={formItem?.yeasts}
          onChange={(vals) => updateProperty("yeasts", vals)}
        />
        <MultiSelect
          label="Adjuncts"
          name="adjuncts"
          items={itemLists.adjuncts}
          values={formItem?.adjuncts}
          onChange={(vals) => updateProperty("adjuncts", vals)}
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={formErrors.website != null}
          fullWidth
          helperText={formErrors.website}
          label="Website"
          name="website"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          value={formItem?.website || ''}
          variant="outlined"
        />
        <LinkListEdit
          value={formItem?.links}
          onChange={(val) => updateProperty("links", val)}
        />
      </Fragment>
    );
  };

  return (
    <ItemEdit
      item={_beer}
      defaultValue={INITIAL_VALUE}
      service={BeerFirestoreService}
      fieldRenderer={renderFields}
      validator={validateBeer}
      preSubmit={preSubmit}
      formId={formId}
      onComplete={onComplete}
      onError={onError}
      hideButtons={hideButtons}
      hideError={hideError}
    />
  )
};

export default BeerEdit;

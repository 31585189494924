import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  label: {
  },
  text: {
    marginLeft: 20,
  },
  name: {
  },
  url: {
    marginLeft: 20,
  }
}));

type LinksType = {[key: string]: string};

interface Props {
  label?: string,
  links?: LinksType,
}


const LabeledLinks = (props: Props) => {

  const {label, links} = props;

  const classes = useStyles();

  const [_links, setLinks] = useState<LinksType>(links || {});

  useEffect(() => {
    const newLinks: LinksType = {};
    if (links != null) {
      Object.entries(links).forEach(([name, link]) => {
        if (link && link.startsWith("http")) {
          newLinks[name] = link;
        } else {
          newLinks[name] = "//" + link
        }
      })
    }
    setLinks(newLinks);
  }, [links]);

  return (
    <div className={classes.root}>
      {label &&
        <Typography
          className={classes.label}
          variant="h6"
          display="inline"
        >
          {label}
        </Typography>
      }
      <div className={classes.text}>
        {_links && Object.keys(_links).map((name, i) => (
            <div key={i}>
              <Typography
                  className={classes.name}
                  variant="body1"
                  display="inline"
              >
                {name}
              </Typography>
              <Typography
                  variant="body1"
                  display="inline"
                  className={classes.url}
              >
                <Link
                    to={_links[name]}
                    target="newTab"
                >
                  {links && links[name]}
                </Link>
              </Typography>
            </div>
        ))}
      </div>
    </div>
  )
};

export default LabeledLinks;

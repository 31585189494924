import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  label: {
  },
  text: {
    marginLeft: 20,
  }
}));


interface Props {
  label?: string,
  text?: string|number|null,
}


const LabeledText = (props: Props) => {

  const {label, text} = props;

  const classes = useStyles();

  return (
    <div>
      <Typography
          className={classes.label}
          variant="h6"
          component="h6"
          display="inline"
      >
        {label}
      </Typography>
      <Typography
          className={classes.text}
          variant="body1"
          component="p"
          display="inline"
      >
        {text || ""}
      </Typography>
    </div>
  )
};

export default LabeledText;

import React, {FunctionComponent, useContext, useState} from 'react';
import {Button, Grid, Link, Typography} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

import ItemView, {useItemViewStyles} from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledText from '../common/LabeledText';
import LabeledURL from '../common/LabeledURL';
import {AuthContext} from '../../contexts';
import {BeerFirestoreService} from '../../firebase';
import {Beer} from '../../models';

import BeerEditDialog from './BeerEditDialog';
import clsx from 'clsx';


interface Props {
  beer?: Beer | null,
  id?: string|null,
}

const BeerView: FunctionComponent<Props> = props => {

  const {beer, id} = props;
  console.log("BeerView", beer);

  const viewClasses = useItemViewStyles();

  const {auth} = useContext(AuthContext);
  const currentUserRoles = auth && auth.currentUserRoles;
  const canContribute = currentUserRoles != null && currentUserRoles.canContribute;

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const renderView = (item: Beer|null) => {
    return (
      <div className={viewClasses.root}>
        {canContribute &&
          <div>
              <Button size="small" color="primary"
                      onClick={handleClickOpenDialog}>
                  Edit
              </Button>
              <BeerEditDialog
                  beer={item}
                  open={openDialog}
                  onClose={handleCloseDialog}
              />
          </div>
        }
        <Grid container spacing={6}>
          <Grid item className={viewClasses.leftCentered}>
            {item?.logoURLs &&
              <img className={viewClasses.image}
                src={item?.logoURLs['400pxURL']}
                alt="Logo"
              />
            }
          </Grid>
          <Grid item className={clsx(viewClasses.info, viewClasses.leftCentered)}>

            <Typography className={viewClasses.title} variant="h2">
              {item?.name}
            </Typography>
            <Typography className={viewClasses.body} variant="body1">
              {item?.type?.name}
            </Typography>
            {item?.brewery &&
              <Typography className={viewClasses.body} variant="body1">
                <Link component={RouterLink} to={`/brewery/${item?.brewery.id}`}>
                  {item?.brewery?.name}
                </Link>
              </Typography>
            }
            <Typography className={viewClasses.body} variant="body1" color="textSecondary">
              {item?.description}
            </Typography>
          </Grid>
        </Grid>

        <LabeledText
          label="ABV"
          text={item?.abv != null ? item?.abv.toLocaleString() : ""}
        />
        <LabeledText
          label="IBU"
          text={item?.ibu != null ? item?.ibu.toLocaleString() : ""}
        />
        <LabeledText
          label="Availability"
          text={item?.availability?.name}
        />
        <LabeledText
          label="Hops"
          text={item?.hops && item?.hops.map(hop => hop.name).join(", ")}
        />
        <LabeledText
          label="Malts"
          text={item?.malts && item?.malts.map(malt => malt.name).join(", ")}
        />
        <LabeledText
          label="Yeasts"
          text={item?.yeasts && item?.yeasts.map(yeast => yeast.name).join(", ")}
        />
        <LabeledText
          label="Adjuncts"
          text={item?.adjuncts && item?.adjuncts.map(adjunct => adjunct.name).join(", ")}
        />

        <LabeledURL
          label="Website"
          url={item?.website}
        />
        <LabeledLinks
          label="Links"
          links={item?.links}
        />
      </div>
    );
  };

  return (
    <ItemView
      item={beer}
      id={id}
      service={BeerFirestoreService}
      viewRenderer={renderView}
    />
  )
};

export default BeerView;
